import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import DashboardTable from "../table/DashboardTable";
import {
  pets,
} from "../../assets/svg/svgs";

const AdminRescuesTable = ({ rescues, title, pageSize }) => {
  const processor = (rescues) => {
    const rescuesState = rescues.map((rescue, key) => {
      return {
        id: `rescue_${rescue.id}`,
        rescue_id: rescue.id,
        rescue_name: rescue.legalRescueName,
        ein: rescue.ein,
        createdAt: rescue.createdAt.substring(0, 10),
        approved: rescue.isEnabled ? "YES" : "NO",
        deleted: rescue.isDeleted ? "YES" : "NO",
        actions: (
          <div className="actions-right">
            <Link to={`/admin/rescues/${rescue.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Rescue"
              >
                {pets}
              </button>
            </Link>
          </div>
        ),
      };
    });
    return rescuesState;
  };

  const [dataState, setDataState] = useState(processor(rescues));

  const flexGrow = ["max-80", "grow-3", "grow-2", "grow-2", "", "", "grow-0-auto"];

  useEffect(() => {
    setDataState(processor(rescues));
  }, [rescues]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <h5>{title}</h5>
          <Card className="dashboardTable">
            <CardBody>
              <DashboardTable
                data={dataState}
                columns={[
                  {
                    Header: "ID",
                    accessor: "rescue_id",
                  },
                  {
                    Header: "Rescue",
                    accessor: "rescue_name",
                  },
                  {
                    Header: "EIN",
                    accessor: "ein",
                  },
                  {
                    Header: "Created at",
                    accessor: "createdAt",
                  },
                  {
                    Header: "Approved",
                    accessor: "approved",
                  },
                  {
                    Header: "Deleted",
                    accessor: "deleted",
                  },
                  {
                    Header: (
                      <div>
                        <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                          {pets}
                        </button>
                      </div>
                    ),
                    accessor: "actions",
                  },
                ]}
                className="-striped -highlight primary-pagination"
                flexGrow={flexGrow}
                pageRowCount={pageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminRescuesTable;
