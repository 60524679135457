import { useLoaderData, redirect } from "react-router-dom";
import AnimalForm from "../../../components/dashboard/AnimalForm";
import { animalDefault } from "../../../utils/initialState";
import { Card, CardBody } from "reactstrap";

const RescueDashboardNewAnimalPage = () => {
  const data = useLoaderData();
  console.log(data);
  const dogBreedOptions = [
    { value: "", label: "Select Dog Breed", isDisabled: true },
  ].concat(
    data.dogBreeds.map((breed) => ({ value: breed.id, label: breed.name }))
  );

  const catBreedOptions = [
    { value: "", label: "Select Cat Breed", isDisabled: true },
  ].concat(
    data.catBreeds.map((breed) => ({ value: breed.id, label: breed.name }))
  );

  return (
    <div>
      <h5>Add New Animal</h5>
      <Card>
        <CardBody>
          <AnimalForm
            dogBreeds={dogBreedOptions}
            catBreeds={catBreedOptions}
            method="POST"
            animal={animalDefault}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default RescueDashboardNewAnimalPage;

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const animalData = Object.fromEntries(formData);
    console.log(animalData);
    let status = "";
    let url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/animals`;
    const response = await fetch(url,
        {
            method: request.method,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(animalData),
        }
    );

    status = response.status;
    if (!response.ok) {
        console.log(response)
      return response;
    }
    const data = await response.json();
    return redirect(`/rescue/animals/${data.id}`)
};

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(`${process.env.REACT_APP_API_ROOT}/breeds/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/rescue/animals");
  }
};
