import { Badge, Card, CardBody, Row, Col } from "reactstrap";
import NoteForm from "./NoteForm";

const NoteTimeline = ({ notes, title }) => {
  return (
    <div className="content" style={{ height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col md="12">
          <Card className="card-timeline card-plain" style={{ height: "100%" }}>
            <CardBody style={{ paddingTop: 0, height: "100%" }}>
              <ul
                className="timeline"
                style={{ paddingTop: 0, height: "100%" }}
              >
                <li>
                  <div style={{ width: "82%", float: "right" }}>
                    <NoteForm />
                  </div>
                </li>
                {notes.map((note, i) => (
                  <li key={`note_${i + 1}`}>
                    <div className="timeline-badge fetched">
                      <i className="nc-icon nc-single-copy-04" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <span></span>
                        <Badge color="fetched" pill>
                          {note.createdAt.substring(0, 10)}
                        </Badge>
                      </div>
                      <div className="timeline-body">
                        <p>{note.note}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default NoteTimeline;
