import { useLoaderData, redirect} from 'react-router-dom';
import AdminRescuesTable from '../../../components/dashboard/AdminRescuesTable';

const AdminDashboardPage = () => {

    const data = useLoaderData();
    const {rescues} = data;
    const defaultPageSize = 5;

    console.log(data);
    
    return (
        <div>
            <h1>Admin Dashboard</h1>
            <AdminRescuesTable rescues={rescues} title="Rescues" pageSize={defaultPageSize}/>

        </div>
    ) 
}

export default AdminDashboardPage;

export const loader = async () => {
    let status = "";
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/admin/adminDashboard`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      status = response.status;
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(`Message : ${error.message}, Status : ${status}`);
      return redirect("/");
    }
  };
  