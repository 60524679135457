import { useEffect, useState, useRef } from "react";
import { Form, useNavigation } from "react-router-dom";
import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import classes from "./CustomForm.module.css";

const CustomForm = ({ method, customQuestion, handleToggle }) => {
  const [isActive, setIsActive] = useState(
    method === "POST" ? false : undefined
  );
  const [isRequired, setIsRequired] = useState(
    method === "POST" ? false : undefined
  );
  const [isDog, setIsDog] = useState(method === "POST" ? true : undefined);
  const [isCat, setIsCat] = useState(method === "POST" ? true : undefined);
  const [isDropdown, setIsDropdown] = useState(
    method === "POST" ? false : undefined
  );

  const formRef = useRef();

  const handlingToggle = (e) => {
    switch (e.target.name) {
      case "isEnabled":
        setIsActive((prevState) => !prevState);
        break;
      case "isRequired":
        setIsRequired((prevState) => !prevState);
        break;
      case "isDog":
        setIsDog((prevState) => !prevState);
        break;
      case "isCat":
        setIsCat((prevState) => !prevState);
        break;
      default:
        break;
    }
  };

  const updateFieldType = (e) => {
    setIsDropdown(e.target.value === "SELECT");
  };
  const navigation = useNavigation();
  let submissionState = "";

  switch (navigation.state) {
    case "submitting":
      submissionState = "Saving...";
      break;
    default:
      submissionState = "Save";
  }

  useEffect(() => {
    if (navigation.state === "loading" && method === "POST") {
      formRef.current.reset();
      handleToggle();
    }
  }, [navigation.state]);

  useEffect(() => {
    setIsActive(customQuestion.isEnabled);
    setIsRequired(customQuestion.isRequired);
    setIsDog(customQuestion.isDog);
    setIsCat(customQuestion.isCat);
    setIsDropdown(customQuestion.fieldType === "SELECT");
  }, []);

  return (
    <Form method={method} ref={formRef}>
      <Row>
        <Col md="12">
          <div className="form-group">
            <label>Question</label>
            <Input
              defaultValue={customQuestion.question}
              className="textarea"
              type="textarea"
              cols="80"
              rows="4"
              placeholder="Custom Question"
              name="question"
              required
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          {/* {!isActive && (
            <input hidden name="isEnabled" value={false} readOnly />
          )} */}
          <InputGroup className={classes.inputGroup}>
            <InputGroupText className={classes.inputGroupText}>
              <Input
                className={classes["formControl_checkbox"]}
                addon
                type="checkbox"
                name="isEnabled"
                checked={isActive}
                onChange={handlingToggle}
                value={isActive}
              />
            </InputGroupText>
            <Input className={classes.formControl} value="This is an active question" />
          </InputGroup>
        </Col>
        <Col md="6">
          <InputGroup className={classes.inputGroup}>
            <InputGroupText className={classes.inputGroupText}>
              <Input
                className={classes["formControl_checkbox"]}
                addon
                type="checkbox"
                name="isRequired"
                checked={isRequired}
                onChange={handlingToggle}
                value={isRequired}
              />
            </InputGroupText>
            <Input className={classes.formControl} value="This is a required question" />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <InputGroup className={classes.inputGroup}>
            <InputGroupText className={classes.inputGroupText}>
              <Input
                className={classes["formControl_checkbox"]}
                addon
                type="checkbox"
                name="isDog"
                checked={isDog}
                onChange={handlingToggle}
                value={isDog}
              />
            </InputGroupText>
            <Input className={classes.formControl} value="This question is for rescued dogs" />
          </InputGroup>
        </Col>
        <Col md="6">
          <InputGroup className={classes.inputGroup}>
            <InputGroupText className={classes.inputGroupText}>
              <Input
                className={classes["formControl_checkbox"]}
                addon
                type="checkbox"
                name="isCat"
                checked={isCat}
                onChange={handlingToggle}
                value={isCat}
              />
            </InputGroupText>
            <Input className={classes.formControl} value="This question is for rescued cats" />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="category">Question Category</Label>
            <Input
              className={classes.formSelect}
              id="category"
              name="category"
              type="select"
              defaultValue={customQuestion.category}
            >
              <option value="GENERAL">General Question</option>
              <option value="PERSONAL">Personal Question</option>
              <option value="PREVIOUS_CURRENT_ANIMAL">
                Previous & Current Animals
              </option>
              <option value="LIVING_ENVIORNMENT">Living Environment</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="fieldType">Field Type</Label>
            <Input
              className={classes.formSelect}
              id="fieldType"
              name="fieldType"
              type="select"
              defaultValue={customQuestion.fieldType}
              onChange={updateFieldType}
            >
              <option value="TEXT">Text</option>
              <option value="SELECT">Dropdown Box</option>
              <option value="BOOLEAN">Checkbox</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      {isDropdown && (
         <Row>
         <Col md="12">
           <div className="form-group">
             <label>Dropdown Question Options (seperate options by comma, up to 5 options)</label>
             <Input
               defaultValue={method === "POST" ? "" : customQuestion.options.toString()}
               className="textarea"
               type="textarea"
               cols="80"
               rows="2"
               placeholder="Option 1, Option 2, ... "
               name="options"
               required
             />
           </div>
         </Col>
       </Row>
      )}

      <div className="flex justify-content flex-end">
        <Button color="fetched-solid">{submissionState}</Button>
      </div>
    </Form>
  );
};

export default CustomForm;

export const action = async ({ request, params }) => {
  const formData = await request.formData();
  const customData = Object.fromEntries(formData);
  console.log("from action: " + JSON.stringify(customData));

  // converting options string to array
  // const options = formData.get("options");
  // const optionsArr = options.split(",");
  // for(const i = 0; i < optionsArr.length; i++) {
  //   optionsArr[i].trim();
  // }
  // formData.set("options", optionsArr);
  
  let url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/customQuestions`;
  if (request.method === "PUT") {
    url = `${url}/${params.customId}`;
  }
  let status = "";
  const response = await fetch(url, {
    method: request.method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(customData),
  });

  status = response.status;
  if (!response.ok) {
    return response;
  }
  const data = await response.json();
  return data;
};
