import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import DashboardTable from "../table/DashboardTable";
import { dog, comment } from "../../assets/svg/svgs";

const QuestionTable = ({ questions, title, pageSize }) => {
  const processor = (questions) => {
    const questionsState = questions.map((_question, key) => {
      return {
        id: `question_${_question.id}`,
        question_id: _question.id,
        question: _question.question,
        animal_name: _question.animal.name,
        answered: !_question.answer ? "No" : "Yes",
        public: _question.isPublic ? "Yes" : "No",
        actions: (
          <div className="actions-right">
            <Link to={`/rescue/questions/${_question.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Question"
              >
                {comment}
              </button>
            </Link>
            <Link to={`/rescue/animals/${_question.animal.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Animal"
              >
                {dog}
              </button>
            </Link>
          </div>
        ),
      };
    });
    return questionsState;
  };

  const [dataState, setDataState] = useState(processor(questions));

  const flexGrow = ["max-80", "grow-6", "grow-2", "", "", "grow-0-auto"];

  useEffect(() => {
    setDataState(processor(questions));
  }, [questions]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <h5>{title}</h5>
          <Card className="dashboardTable">
            <CardBody>
              <DashboardTable
                data={dataState}
                columns={[
                  {
                    Header: "ID",
                    accessor: "question_id",
                  },
                  {
                    Header: "Question",
                    accessor: "question",
                  },
                  {
                    Header: "Animal",
                    accessor: "animal_name",
                  },
                  {
                    Header: "Answer",
                    accessor: "answered",
                  },
                  {
                    Header: "Public",
                    accessor: "public",
                  },
                  {
                    Header: (
                      <div>
                        <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                          {comment}
                        </button>
                        <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                          {dog}
                        </button>
                      </div>
                    ),
                    accessor: "actions",
                  },
                ]}
                className="-striped -highlight primary-pagination"
                flexGrow={flexGrow}
                pageRowCount={pageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default QuestionTable;
