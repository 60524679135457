import { useRef, useState, useEffect } from "react";
import { Form, useNavigation, useParams, useSubmit } from "react-router-dom";
import { Button, Input, Row, Col, FormGroup, Label } from "reactstrap";
import logo from "../../assets/images/logo.png";
import { documentDefault } from "../../utils/initialState";
import { pdf } from "../../assets/svg/svgs";

const DocumentForm = ({ document, method, toggle }) => {
  const [preview, setPreview] = useState("");
  const [file, setFile] = useState(documentDefault.file);
  const [title, setTitle] = useState(documentDefault.title);
  const [note, setNote] = useState(documentDefault.note);

  const { animalId } = useParams();
  const submit = useSubmit();
  const addDocumentRef = useRef();
  const navigation = useNavigation();

  let submissionState = "";

  switch (navigation.state) {
    case "submitting":
      submissionState = "Saving...";
      break;
    default:
      submissionState = "Save";
  }

  const handleClick = () => {
    addDocumentRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append(
      "form-id",
      method === "PUT" ? "updateDocument" : "addDocument"
    );
    formData.append("fileType", "document");
    formData.append("animalId", animalId);
    formData.append("file", file);
    formData.append("title", title);
    formData.append("note", note);
    if (method === "PUT") {
      const delete_url = document.url;
      formData.append("delete_url", delete_url);
      formData.append("documentId", document.id);
    }

    const actionPath = `/rescue/animals/${animalId}`;
    submit(formData, {
      method: method,
      encType: "multipart/form-data",
      action: actionPath,
    });
  };

  const handleDocumentInput = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let targetFile = e.target.files[0];
    reader.onloadend = () => {
      setFile(targetFile);
      setPreview(
        reader.result.startsWith("data:image") ? reader.result : "pdf"
      );
    };
    if (targetFile) {
      reader.readAsDataURL(targetFile);
    }
  };

  const removeDocument = (e) => {
    addDocumentRef.current.value = null;
    method = "DELETE";
    const formData = new FormData();
    formData.append("form-id", "deleteDocument");
    formData.append("documentId", document.id);
    const actionPath = `/rescue/animals/${animalId}`;
    submit(formData, {
      method: method,
      action: actionPath,
    });
  };

  useEffect(() => {
    if (navigation.state === "loading") {
      toggle();
    }
  }, [navigation.state]);

  useEffect(() => {
    setPreview(
      method === "PUT" &&
        document.url.substring(document.url.length - 3) === "pdf"
        ? "pdf"
        : document.file
    );
    setFile(document.file);
    setTitle(document.title);
    setNote(document.note);
  }, []);

  return (
    <>
      <Row>
        <Col md="4" className="pr-1">
          <div className="imageAddWrapper">
            <input
              hidden
              name="form-id"
              defaultValue={method === "PUT" ? "updateDocument" : "addDocument"}
            />
            <input
              ref={addDocumentRef}
              type="file"
              onChange={handleDocumentInput}
              accept="image/png, image/jpeg, image/webp, application/pdf"
              style={{ display: "none" }}
            />
            <div
              className={"thumbnail"}
              style={{ border: "1px solid #eee", position: "relative" }}
            >
              {file && preview !== "pdf" && (
                <img src={preview} alt="document" />
              )}
              {file && preview === "pdf" && pdf}
            </div>
            {!preview && (
              <Button
                color="info"
                className="btn-round multiBtn"
                onClick={handleClick}
              >
                <i className="fa fa-plus" />
              </Button>
            )}
          </div>
          {file && (
            <span>
              <Button
                className="btn-round multiBtn"
                onClick={handleClick}
                style={{ width: "150px", margin: "10px" }}
              >
                Change
              </Button>
            </span>
          )}
        </Col>
        <Col md="8" className="pl-1">
          <div className="form-group">
            <label>Title</label>
            <Input
              type="text"
              placeholder="Document Title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Note</label>
            <Input
              type="text"
              placeholder="Document Note"
              name="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              required
            />
          </div>
        </Col>
      </Row>
      <div className="flex justify-content flex-end">
        <Button color="danger" className="multiBtn " onClick={removeDocument}>
          Delete Document
        </Button>
        <Button color="fetched-solid" onClick={handleSubmit}>
          {submissionState}
        </Button>
      </div>
    </>
  );
};

export default DocumentForm;
