import { useLoaderData, redirect, useOutletContext } from "react-router-dom";
import Footer from "../components/Footer";
import AnimalCards from "../components/card/AnimalCards";

import LogoPlacement from "../assets/images/logo_placement_bw.png";
import classes from "./Rescues.module.css";
import Facebook from "../assets/images/social_facebook.png";
import Instagram from "../assets/images/social_instagram.png";
import Twitter from "../assets/images/social_twitter.png";
import Tiktok from "../assets/images/social_tiktok.png";
import Youtube from "../assets/images/social_youtube.png";

const RescuesPage = () => {
  const data = useLoaderData();
  const [user] = useOutletContext();

  console.log(data);
  if (data === "No Rescue found") {
    return <h1>Rescue does not exist.</h1>;
  }

  const city = `${data.rescueMeta.city.toLowerCase()}`;
  const hasSocial =
    data.socialProfile.facebook ||
    data.socialProfile.instagram ||
    data.socialProfile.x ||
    data.socialProfile.tiktok ||
    data.socialProfile.youtube;
  return (
    <>
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <div className={classes.descriptionContainer}>
            <h1>{data.legalRescueName.toLowerCase()}</h1>
            <h4>
              <span>{city}</span>, {data.rescueMeta.state}
            </h4>
            <h5>{data.rescueMeta.description}</h5>
            <p>{data.rescueMeta.story}</p>
          </div>
          <div className={classes.rescueDataContainer}>
            {data.rescueLogo && (
              <img
                src={`${process.env.REACT_APP_API_BASE_ROOT}${data.rescueLogo.img_url}`}
                alt="rescue organization logo"
              />
            )}
            {!data.rescueLogo && (
              <img
                src={LogoPlacement}
                alt="a placement cartoon image for rescue organization"
              />
            )}

            <div className={classes.space10} />
            <span>{data.rescueMeta.streetAddress1}</span>
            <br />
            <span>
              {data.rescueMeta.city}, {data.rescueMeta.state}{" "}
              {data.rescueMeta.zipCode}
            </span>
            <div className={classes.space10} />
            <span>{data.rescueMeta.phone}</span>
            <br />
            <span>{data.user.email}</span>

            {data.rescueMeta.website && (
              <>
                <h6>Visit us on the web</h6>
                <span>
                  <a
                    href={data.rescueMeta.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.rescueMeta.website
                      .replace("http://", "")
                      .replace("https://", "")}
                  </a>
                </span>
              </>
            )}
            {data.rescueMeta.merchStore && (
              <>
                <h6>Shop our merch</h6>
                <span>
                  <a
                    href={data.rescueMeta.merchStore}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.rescueMeta.merchStore
                      .replace("http://", "")
                      .replace("https://", "")}
                  </a>
                </span>
              </>
            )}
            {data.rescueMeta.donation && (
              <>
                <h6>Make a donation</h6>
                <span>
                  <a
                    href={data.rescueMeta.donation}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.rescueMeta.donation
                      .replace("http://", "")
                      .replace("https://", "")}
                  </a>
                </span>
              </>
            )}

            {hasSocial && (
              <>
                <h6>Follow our rescue on social media</h6>
                <ul>
                  {data.socialProfile.facebook && (
                    <li>
                      <a
                        href={`https://facebook.com/${data.socialProfile.facebook}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Facebook} alt="facebook logo" />
                      </a>
                    </li>
                  )}
                  {data.socialProfile.instagram && (
                    <li>
                      <a
                        href={`https://instagram.com/${data.socialProfile.instagram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Instagram} alt="instagram logo" />
                      </a>
                    </li>
                  )}
                  {data.socialProfile.x && (
                    <li>
                      <a
                        href={`https://twitter.com/${data.socialProfile.x}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Twitter} alt="twitter logo" />
                      </a>
                    </li>
                  )}
                  {data.socialProfile.tiktok && (
                    <li>
                      <a
                        href={`https://tiktok.com/@${data.socialProfile.tiktok}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Tiktok} alt="tiktok logo" />
                      </a>
                    </li>
                  )}
                  {data.socialProfile.youtube && (
                    <li>
                      <a
                        href={`https://youtube.com/@${data.socialProfile.youtube}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Youtube} alt="youtube logo" />
                      </a>
                    </li>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
        <div className={classes.centerContainer}>
          <h2>Our Rescue Animals</h2>
          <AnimalCards
            animals={data.animals}
            userId={user && user.role == "USER" ? user.id : null}
            action={`/page/rescues/${data.id}`}
          />
        </div>
      </div>
      {/* <div className={classes.container}>
        <Footer />
      </div> */}
    </>
  );
};

export default RescuesPage;

export const action = async ({ request }) => {
  const formData = await request.formData();
  let url = `${process.env.REACT_APP_API_ROOT}/users/user/animal/${formData.get(
    "animalId"
  )}`;
  let status = "";

  const response = await fetch(url, {
    method: request.method,
    credentials: "include",
  });
  status = response.status;
  if (!response.ok) {
    console.log("status: " + status);
    return response;
  }
  const responseData = await response.json();
  return responseData;
};

export const loader = async ({ params }) => {
  let status = "";
  const url = `${process.env.REACT_APP_API_ROOT}/rescues/rescueProfile/${params.rescueId}`;
  console.log("rescue page - before fetching endpoint");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    status = response.status;
    console.log("rescue page - Status: " + status);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
