import { useSubmit, Link } from 'react-router-dom';
import classes from "./AnimalCard.module.css";
import dog from "../../assets/images/dog.png";
import cat from "../../assets/images/cat.png";
import baby from "../../assets/images/baby.png";
import { redHeartFilled, redHeartOutlined, dot } from "../../assets/svg/svgs";

const AnimalCardItem = ({ animal, userId, action, searchPage }) => {
  const animalData = {
    photo: searchPage && animal.img_url ? animal.img_url : !searchPage && animal.photos.length > 0 ? animal.photos[0].img_url : null,
    gender: searchPage ? animal.gender : animal.animalMeta.gender,
    size: searchPage ? animal.size : animal.animalMeta.size,
    age: searchPage ? animal.age : animal.animalMeta.age,
    breed: searchPage ? animal.breed : animal.animalMeta.primaryBreed.name,
    isMixed: searchPage ? animal.isMixed : animal.animalMeta.isMixed,
    isGoodWithDogs: searchPage ? animal.isGoodWithDogs : animal.animalMeta.isGoodWithDogs,
    isGoodWithCats: searchPage ? animal.isGoodWithCats : animal.animalMeta.isGoodWithCats,
    isGoodWithKids: searchPage ? animal.isGoodWithKids : animal.animalMeta.isGoodWithKids,
  }

  const submit = useSubmit();
  const gender = animalData.gender === "FEMALE" ? "F" : "M";
  let like = false;

  if(!searchPage && userId) {
    for (let i = 0; i < animal.likes.length; i++) {
      if (animal.likes[i].userId === userId) {
        like = true;
        break;
      }
    }
  }
  
  const handleClick = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("animalId" , animal.id);
    if(like) {
        submit(formData, { method: "DELETE", action: action});
    } else {
        submit(formData, { method: "POST", action: action})
    }
  }

  return (
    <Link to={`/page/animals/${animal.id}`}>
    <div className={`${classes.card} ${classes.md}`}>
      { userId && 
        <div onClick={handleClick}>
        {like && redHeartFilled}
        {!like && redHeartOutlined}
      </div>
      }
      <div className={classes.cardImageWrapper} style={animalData.photo ? {backgroundImage: `url(${process.env.REACT_APP_API_BASE_ROOT}/${animalData.photo})`}: null}></div>
      <div className={classes.cardDescriptionWrapper}>
        <div
          className={classes.circle}
          style={{
            backgroundColor: gender === "F" ? "rgb(251, 124, 124)" : "#56CCF2",
          }}
        >
          {gender}
        </div>
        <div className={`${classes.text} ${classes.mdText}`}>
          {animalData.size.toLowerCase()} {dot}
          {animalData.age.toLowerCase().replaceAll("_", " ")}
        </div>
        <div className={`${classes.title} ${classes.mdTitle}`}>
          {animal.name}
        </div>
        <div className={`${classes.subTitle} ${classes.mdSubTitle}`}>
          {animalData.breed} {animalData.isMixed ? "Mix" : ""}
        </div>
        <div className={classes.iconsWrapper}>
          {animalData.isGoodWithDogs && <img src={dog} />}
          {animalData.isGoodWithCats && <img src={cat} />}
          {animalData.isGoodWithKids && <img src={baby} />}
        </div>
      </div>
    </div></Link>
  );
};

export default AnimalCardItem;
