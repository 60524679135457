import { redirect, useLoaderData } from "react-router-dom";
import EventForm from "../../../components/dashboard/EventForm";
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import classes from "./EditPage.module.css";

const RescueDashboardEventPage = () => {
  const data = useLoaderData();
  console.log(data);
  const startDate = new Date(data.startDate);
  const endDate = new Date(data.endDate);
  const times = Math.abs(endDate - startDate);
  const days = Math.ceil(times / (1000 * 60 * 60 * 24)); 
  console.log(days);
  return (
    <div className="content">

      <div className={classes.badge}>
        {data.isPhysical ? "Physical Event" : "Virtual Event"}
      </div>
      <div className={classes.badge}>
        {days === 0 ? "Single-Day Event" : "Multi-Day Event"}
      </div>
      
      <h3>{data.title}</h3>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle>Edit Current Event</CardTitle>
              </CardHeader>
              <CardBody>
                <EventForm
                  method="PUT"
                  event={data}
                  rescueAddress={data.rescue.rescueMeta}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RescueDashboardEventPage;

export const loader = async ({ params }) => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/events/${params.eventId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
