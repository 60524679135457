import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import DashboardTable from "../table/DashboardTable";
import { dog, pets } from "../../assets/svg/svgs";

const UserQuestionTable = ({ questions, title, pageSize }) => {
    const processor = (questions) => {
        const questionsState = questions.map((_question, key) => {
          return {
            id: `question_${_question.id}`,
            question_id: _question.id,
            animal_name: _question.animal.name,
            question: _question.question,
            
            answer: _question.answer,
            rescue: _question.rescue.legalRescueName,
            actions: (
              <div className="actions-right">
                <Link to={`/page/animals/${_question.animal.id}`}>
                  <button
                    className="btn-icon btn-link like btn btn-fetched btn-sm"
                    type="button"
                    title="View Animal"
                  >
                    {dog}
                  </button>
                </Link>
                <Link to={`/page/rescues/${_question.rescue.id}`}>
                  <button
                    className="btn-icon btn-link like btn btn-fetched btn-sm"
                    type="button"
                    title="View Rescue"
                  >
                    {pets}
                  </button>
                </Link>
              </div>
            ),
          };
        });
        return questionsState;
      };

      const [dataState, setDataState] = useState(processor(questions));

      const flexGrow = ["max-80", "", "grow-6", "grow-6", "grow-2", "grow-0-auto"];
    
      useEffect(() => {
        setDataState(processor(questions));
      }, [questions]);
    
      return (
        <div className="content">
          <Row>
            <Col md="12">
              <h5>{title}</h5>
              <Card className="dashboardTable">
                <CardBody>
                  <DashboardTable
                    data={dataState}
                    columns={[
                      {
                        Header: "ID",
                        accessor: "question_id",
                      },
                      {
                        Header: "Animal",
                        accessor: "animal_name",
                      },
                      {
                        Header: "Question",
                        accessor: "question",
                      },
                      
                      {
                        Header: "Answer",
                        accessor: "answer",
                      },
                      {
                        Header: "Rescue",
                        accessor: "rescue",
                      },
                      {
                        Header: (
                          <div>
                            
                            <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                              {dog}
                            </button>
                            <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                              {pets}
                            </button>
                          </div>
                        ),
                        accessor: "actions",
                      },
                    ]}
                    className="-striped -highlight primary-pagination"
                    flexGrow={flexGrow}
                    pageRowCount={pageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );

}

export default UserQuestionTable;