import { useState, useEffect, useRef } from "react";
import { Form, useNavigation } from "react-router-dom";
import Datetime from "react-datetime";
import Select from "react-select";
import moment from "moment";
import {
  reverseConvertingTimeStringToDateObject,
  modifyingEventData,
} from "../../utils/date_time";
import { stateOptions } from "../../utils/enums";
import { Button, Input, Row, Col } from "reactstrap";

function EventForm({ event, method, rescueAddress, handleToggle }) {
  const rawStartDate =
    event && event.startDate
      ? new Date(event.startDate.substring(0, 10))
      : null;
  const rawEndDate =
    event && event.endDate ? new Date(event.endDate.substring(0, 10)) : null;

  // accounting for timezone difference
  const now = new Date();
  const initialStartDate = rawStartDate
    ? new Date(rawStartDate.getTime() + now.getTimezoneOffset() * 60 * 1000)
    : null;
  const initialEndDate = rawEndDate
    ? new Date(rawEndDate.getTime() + now.getTimezoneOffset() * 60 * 1000)
    : null;

  // need to handle event.startTime and event.endTime for edit form
  const initialStartTime =
    event && event.startTime
      ? reverseConvertingTimeStringToDateObject(event.startTime)
      : null;
  const initialEndTime =
    event && event.startTime
      ? reverseConvertingTimeStringToDateObject(event.endTime)
      : null;

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  const [eventType, setEventType] = useState("");
  const [usState, setUsState] = useState("");
  const formRef = useRef();

  const navigation = useNavigation();
  let submissionState = "";

  switch (navigation.state) {
    case "submitting":
      submissionState = "Saving...";
      break;
    default:
      submissionState = "Save";
  }

  useEffect(() => {
    if (method === "POST" && navigation.state === "loading") {
      setStartDate(null);
      setEndDate(null);
      setStartTime(null);
      setEndTime(null);
      setEventType("");
      setUsState("");
      formRef.current.reset();
      handleToggle();
    }
  }, [navigation.state]);

  const yesterday = moment().subtract(1, "day");
  const beforeStartDate = moment(startDate).subtract(1, "day");
  const validStart = (current) => {
    return current.isAfter(yesterday);
  };
  const validEnd = (current) => {
    return current.isAfter(beforeStartDate);
  };

  const handlingDateTime = (e, type) => {
    const newDate = e._d;
    switch (type) {
      case "startDate":
        setStartDate(newDate);
        break;
      case "endDate":
        setEndDate(newDate);
        break;
      case "startTime":
        setStartTime(newDate);
        break;
      case "endTime":
        setEndTime(newDate);
        break;
      default:
        window.alert("Not a valid date / time type");
    }
  };

  useEffect(() => {
    setEventType(
      event.isPhysical == null
        ? null
        : event.isPhysical
        ? { value: "physical", label: "Physical Event" }
        : !event.isPhysical
        ? { value: "virtual", label: "Virtual Event" }
        : null
    );
    setUsState({ value: event.state, label: event.state });
  }, []);

  return (
    <Form method={method} ref={formRef}>
      <Row>
        <Col className="pr-1" md="9">
          <div className="form-group">
            <label>Title*</label>
            <Input
              defaultValue={event.title}
              placeholder="Event title"
              type="text"
              name="title"
              required
            />
          </div>
        </Col>
        <Col className="pl-1" md="3">
          <div className="form-group">
            <label>Event Type*</label>
            <Select
              id="isPhysical"
              className="react-select primary"
              classNamePrefix="react-select"
              required
              name="isPhysical"
              value={eventType}
              onChange={(value) => {
                setEventType(value);
              }}
              options={[
                {
                  value: "",
                  label: "Select Event Type",
                  isDisabled: true,
                },
                { value: "virtual", label: "Virtual Event" },
                { value: "physical", label: "Physical Event" },
              ]}
              placeholder="Select"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="pr-1" md="4">
          <div className="form-group">
            <label>Start Date*</label>
            <Datetime
              isValidDate={validStart}
              value={startDate ? startDate : ""}
              required
              onChange={(e) => handlingDateTime(e, "startDate")}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              inputProps={{
                name: "startDate",
              }}
              renderInput={(props) => {
                return (
                  <input {...props} value={startDate ? props.value : ""} />
                );
              }}
            />
          </div>
        </Col>
        <Col className="px-1" md="2">
          <div className="form-group">
            <label>Start Time*</label>
            <Datetime
              timeConstraints={{ minutes: { step: 30 } }}
              dateFormat={false}
              value={startTime ? startTime : ""}
              onChange={(e) => handlingDateTime(e, "startTime")}
              required
              inputProps={{
                className: "form-control",
                placeholder: "Select Start Time",
                name: "startTime",
              }}
              renderInput={(props) => {
                return (
                  <input {...props} value={startTime ? props.value : ""} />
                );
              }}
            />
          </div>
        </Col>
        <Col className="px-1" md="4">
          <div className="form-group">
            <label>End Date*</label>
            <Datetime
              isValidDate={validEnd}
              value={endDate ? endDate : ""}
              onChange={(e) => handlingDateTime(e, "endDate")}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              inputProps={{
                name: "endDate",
              }}
              renderInput={(props) => {
                return <input {...props} value={endDate ? props.value : ""} />;
              }}
            />
          </div>
        </Col>
        <Col className="pl-1" md="2">
          <div id="endTime" className="form-group">
            <label>End Time*</label>
            <Datetime
              timeConstraints={{ minutes: { step: 30 } }}
              dateFormat={false}
              value={endTime ? endTime : ""}
              onChange={(e) => handlingDateTime(e, "endTime")}
              inputProps={{
                className: "form-control",
                placeholder: "Select End Time",
                name: "endTime",
              }}
              renderInput={(props) => {
                return <input {...props} value={endTime ? props.value : ""} />;
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="pr-1" md="6">
          <div className="form-group">
            <label>Location*</label>
            <Input
              defaultValue={event.location}
              placeholder="Physical event in the park or Virtual event online"
              type="text"
              name="location"
              required
            />
          </div>
        </Col>
        <Col className="pl-1" md="6">
          <div className="form-group">
            <label>Website*</label>
            <Input
              defaultValue={event.url}
              placeholder="Website address for both physical and virtual events"
              type="text"
              name="url"
              required
            />
          </div>
        </Col>
      </Row>

      {eventType && eventType.value === "virtual" && (
        <>
          <input
            hidden
            readOnly
            name="streetAddress1"
            value={rescueAddress.streetAddress1}
          />
          <input hidden readOnly name="city" value={rescueAddress.city} />
          <input hidden readOnly name="state" value={rescueAddress.state} />
          <input hidden readOnly name="zipCode" value={rescueAddress.zipCode} />
        </>
      )}

      {eventType && eventType.value === "physical" && (
        <Row>
          <Col className="pr-1" md="5">
            <div className="form-group">
              <label>Street Address*</label>
              <Input
                defaultValue={event.streetAddress1}
                placeholder="Street Address"
                type="text"
                name="streetAddress1"
                required
              />
            </div>
          </Col>
          <Col className="px-1" md="3">
            <div className="form-group">
              <label>City*</label>
              <Input
                defaultValue={event.city}
                placeholder="City"
                type="text"
                name="city"
                required
              />
            </div>
          </Col>
          <Col className="px-1" md="2">
            <div className="form-group">
              <label>State*</label>
              <Select
                id="state"
                className="react-select primary"
                classNamePrefix="react-select"
                required
                name="state"
                value={usState}
                onChange={(value) => {
                  setUsState(value);
                }}
                options={stateOptions}
                placeholder="State"
              />
            </div>
          </Col>
          <Col className="pl-1" md="2">
            <div className="form-group">
              <label>Zipcode*</label>
              <Input
                defaultValue={event.zipCode}
                placeholder="Zipcode"
                type="text"
                name="zipCode"
                required
              />
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col md="12">
          <div className="form-group">
            <label>Event description*</label>
            <Input
              defaultValue={event.description}
              className="textarea"
              type="textarea"
              cols="80"
              rows="4"
              placeholder="Add Event Description"
              name="description"
              required
            />
          </div>
        </Col>
      </Row>
      <div className="flex justify-content flex-end">
        <Button color="fetched-solid">{submissionState}</Button>
      </div>
    </Form>
  );
}

export default EventForm;

export const action = async ({ request, params }) => {
  const formData = await request.formData();
  let url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/events`;
  const eventData = Object.fromEntries(formData);
  const modifiedEventData = modifyingEventData(eventData);

  let status = "";

  if (request.method === "PUT") {
    url = `${url}/${params.eventId};`;
  }

  const response = await fetch(url, {
    method: request.method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(modifiedEventData),
  });
  status = response.status;
  if (!response.ok) {
    return response;
  }
  const data = await response.json();
  return data;
};
