import { Outlet,
  useRouteLoaderData,
  matchPath,
  useLocation, } from "react-router-dom";
import classes from "./Root.module.css";
import PageNavigation from "../components/navigation/Page";
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import PageFooter from "../components/PageFooter";
const PageRootLayout = () => {

  const { pathname } = useLocation();
  const isAuth = matchPath("/auth/*", pathname);
  const user = useRouteLoaderData(!isAuth ? "pageRoot" : "authRoot");

  return (
    <div className={classes.whBg}>
      <PageNavigation user={user} auth={isAuth}/>
      <main className={classes.main}>
        <Outlet context={[user]} />
        <ScrollToTop />
      </main>
      {/* <div className={classes.footerContainer}> */}
        <PageFooter />
      {/* </div> */}
      
    </div>
  );
};

export default PageRootLayout;
