import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import DashboardTable from "../table/DashboardTable";
import { dog, cat } from "../../assets/svg/svgs";

const AnimalTable = ({ animals, title, pageSize }) => {
  const processor = (animals) => {
    const animalsState = animals.map((animal, key) => {
      return {
        id: `animal_${animal.id}`,
        animal_id: animal.id,
        name: animal.name,
        species: animal.animalMeta.species,
        breed: `${animal.animalMeta.primaryBreed.name} ${
          animal.animalMeta.isMixed ? "Mix" : ""
        }`,
        status: animal.adoptionStatus.replaceAll("_", " "),
        display: animal.isDisplayed ? "Yes" : "No",
        actions: (
          <div className="actions-right">
            <Link to={`/rescue/animals/${animal.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Animal"
              >
                {animal.animalMeta.species === "DOG" ? dog : cat}
              </button>
            </Link>
          </div>
        ),
      };
    });
    return animalsState;
  };

  const [dataState, setDataState] = useState(processor(animals));
  const flexGrow = [
    "max-80",
    "grow-2",
    "",
    "grow-2",
    "grow-3",
    "",
    "grow-0-auto",
  ];

  useEffect(() => {
    setDataState(processor(animals));
  }, [animals]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <h5>{title}</h5>
          <Card className="dashboardTable">
            <CardBody>
              <DashboardTable
                data={dataState}
                columns={[
                  {
                    Header: "ID",
                    accessor: "animal_id",
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                  },
                  { Header: "Species", accessor: "species" },
                  { Header: "Breed", accessor: "breed" },
                  {
                    Header: "Status",
                    accessor: "status",
                  },
                  {
                    Header: "Public",
                    accessor: "display",
                  },
                  {
                    Header: (
                      <button className="btn-icon like btn btn-fetched btn-sm">
                        {dog}
                      </button>
                    ),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                className="-striped -highlight primary-pagination"
                flexGrow={flexGrow}
                pageRowCount={pageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AnimalTable;
