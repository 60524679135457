import { useState } from "react";
import classes from "./TagDropdown.module.css";

const TagDropdown = ({
  text,
  isNewApplicant,
  isRepeatAdopter,
  isRepeatApplicant,
  isFlagged,
  handleClick,
  toggle,
  setToggle
}) => {
//   const [toggle, setToggle] = useState(false);
    
    const styles = !toggle ? {display:"none"} : {display:"block"};

  return (
    <div className={classes.container}>
      <div
        className={classes.dropdownButton}
        onClick={() => setToggle(!toggle)}
      >
        {text}
      </div>

      <ul style={styles}>
        {!isNewApplicant && <li onClick={() => {handleClick("newApplicant")}}>New Applicant</li>}
        {!isRepeatAdopter && <li onClick={() => {handleClick("repeatAdopter")}}>Repeat Adopter</li>}
        {!isRepeatApplicant && <li onClick={() => {handleClick("repeatApplicant")}}>Repeat Applicant</li>}
        {!isFlagged && <li onClick={() => {handleClick("flagged")}}>Flagged</li>}
      </ul>
    </div>
  );
};

export default TagDropdown;
