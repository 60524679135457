import classes from "../dashboard/CustomForm.module.css";

import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
const CustomSelect = ({ question, idx }) => {

const options = question.options;

  return (
    <Row>
      <Col md="12">
      <div className="form-group">
          <Label for={`fetchedAppQuestion_${question.category}_${idx}`}>{question.question}{question.isRequired ? "*" : ""}</Label>
          <Input
            className={classes.formSelect}
            id={`fetchedAppQuestion_${question.category}_${idx}`}
            name={`fetchedAppQuestion_${question.category}_${idx}`}
            type="select"
            required={question.isRequired}
            // data-question-question={question.question}
            data-question-type={question.type}
            data-question-category={question.category}
            data-question-idx={idx}
          >
            {options.map((item, i) => (
              <option value={item} key={`option_${i + 1}`}>
                {item}
              </option>
            ))}
          </Input>
        </div>
      </Col>
    </Row>
  );
};

export default CustomSelect;
