import classes from "./Footer.module.css";
import ContactBox from "./ContactBox";
import { logoNav } from "../assets/svg/svgs";
import Twitter from "../assets/images/twitter.png";
import Facebook from "../assets/images/facebook.png";
import Instagram from "../assets/images/instagram.png";
import Email from "../assets/images/email.png";
import { matchPath, useLocation, Link } from "react-router-dom";

const PageFooter = () => {
  const date = new Date();
  const year = date.getFullYear();
  const { pathname } = useLocation();
  const isContact = matchPath("/page/contact", pathname);

  return (
    <footer className={classes.pageFooterContainer}>
      {!isContact && (
        <div className={classes.contactBoxWrapper}>
          <ContactBox />
        </div>
      )}
      <p>
        <Link to={'/page/about'}>About</Link>{" "}|{" "}
        <Link to={'/page/cookiesPolicy'}>Cookie Policies</Link>{" "}|{" "}
        <Link to={'/page/termsAndCondition'}>Terms & Conditions</Link>
        </p>
      
      <div className={classes.pageFooterWrapper}>
        <div className={classes.footerLogo}>
          {logoNav}
          <span>© {year} fetched, All rights reserved.</span>
        </div>
        <div className={classes.socialLinks}>
          <img src={Email} />
          <img src={Facebook} />
          <img src={Instagram} />
          <img src={Twitter} />
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
