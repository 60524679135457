import { useEffect } from "react";
import { Form, useNavigation } from "react-router-dom";
import { useState } from "react";
import { Button, Input, Row, Col } from "reactstrap";

const NoteForm = () => {
  const [inputValue, setInputValue] = useState("");

  const handlingChange = (e) => {
    setInputValue(e.target.value);
  };
  const navigation = useNavigation();

  let submissionState = "";

  switch (navigation.state) {
    case "submitting":
      submissionState = navigation.formMethod === "put" ? "Save" : "Saving...";
      break;
    case "loading":
      submissionState = navigation.formMethod === "put" ? "Save" : "Saved!";
      break;
    default:
      submissionState = "Save";
  }

  useEffect(() => {
    if ((submissionState = "Saved!")) {
      setInputValue("");
    }
  }, [submissionState]);

  return (
    <Form method="post">
      <Row>
        <Col md="12">
          <input name="form-id" hidden defaultValue="note" />
          <div className="form-group">
            <Input
              className="textarea"
              type="textarea"
              cols="80"
              rows="4"
              placeholder="Add new note"
              name="note"
              value={inputValue}
              required
              onChange={handlingChange}
            />
          </div>
        </Col>
      </Row>
      <Button color="fetched-solid" style={{ width: "100%", marginTop: 0 }}>
        {submissionState}
      </Button>
    </Form>
  );
};

export default NoteForm;
