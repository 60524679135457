import classes from "./AnimalCard.module.css";
import AnimalCardItem from "./AnimalCardItem";
import AnimalCardItemAlt from "./AnimalCardItemAlt";
import {  matchPath, useLocation} from "react-router-dom";

const AnimalCards = ({ animals, userId, likes, action, updateAction, searchPage }) => {
  const { pathname } = useLocation();
  const isPage = matchPath("/page/*", pathname);
  
  return (
    <div className={classes.animalCardsWrapper}>
      {searchPage && 
       animals.map((animal, i) => (
        <AnimalCardItemAlt key={`animal_${i+1}`} animal={animal} userId={userId} likes={likes} action={action} updateAction={updateAction} searchPage={searchPage}/>
      ))
      }
      {!isPage && 
        animals.map((_animal, i) => (
          <AnimalCardItem key={`animal_${i+1}`} animal={_animal.animal} userId={userId} action={action}  />
        ))
      }
      {
        isPage && !searchPage &&
        animals.map((animal, i) => (
          <AnimalCardItem key={`animal_${i+1}`} animal={animal} userId={userId} action={action} searchPage={searchPage}/>
        ))
      }
      
    </div>
  );
};

export default AnimalCards;

