import { useLoaderData, redirect } from "react-router-dom";
import AnimalTable from "../../../components/dashboard/AnimalTable";

const RescueDashboardArchivedDashboardPage = () => {
  const data = useLoaderData();
  console.log(data)
  const defaultPageSize = 20;
  return (
    <div>
      <AnimalTable
        animals={data.animals}
        title="Archived Animals"
        pageSize={defaultPageSize}
      />
    </div>
  );
};

export default RescueDashboardArchivedDashboardPage;

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/archivedAnimals`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/rescue/animals");
  }
};
