import { json, redirect, useActionData, Link } from 'react-router-dom';
import classes from './Login.module.css';
import UserSignupForm from '../components/form/UserSignupForm';
import {encode, decode} from 'string-encode-decode';

const SignupPage = () => {

    const errors = useActionData();

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={classes.redirectTop}>
                    Are you a rescue?{" "}
                    <Link to="/auth/register">Rescue Sign Up</Link> </div>
                <div className={classes["prompt-wrapper"]}>
                    <h2>Sign Up</h2>
                    {errors?.message && <span style={{color: "red"}}>{errors.message}</span>}

                </div>
                <div className={classes["form-wrapper"]}>  
                    <UserSignupForm /> 
                </div>
            </div>
        </div>
    )
}

export default SignupPage;

export const action = async ({request}) => {
    const data = Object.fromEntries(await request.formData());
    let status = "";
    const errors = {};
    const response = await fetch(`${process.env.REACT_APP_API_ROOT}/auth/register`,
        {
            method: "POST",
            headers: {
                'Content-Type':'application/json',
            },
            body: JSON.stringify(data)
        }
    );
    status = response.status;
    const resJson = await response.json();
     if(!response.ok) {
        errors.message = resJson.message;
        console.log(errors.message);
        return errors;
     }
    //  const resData = await response.json();
     return redirect("/auth/login");
} 