import { useEffect, useState } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import AdminNavigation from "../components/navigation/Admin";
import DashboardGreeting from "../components/dashboard/Greeting";
import classes from "./DashboardRoot.module.css";
import Copyright from "../components/Copyright";
import ScrollToTop from "../components/ScrollToTop";

const AdminDashboardRootLayout = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const data = useLoaderData();
  const navigate = useNavigate();

  const handleMenuClick = (toggleState) => {
    setMenuToggle(toggleState);
  };

  useEffect(() => {
    if (data == null) navigate("/auth/login");
    else {
      if (data.role === "RESCUE") navigate("/rescue");
      else if (data.role === "USER") navigate("/user");
      else if (data.role !== "ADMIN") navigate("/auth/login");
    }
  }, []);

  return (
    <>
      {data != null && data.role === "ADMIN" && (
        <>
          {menuToggle && (
            <div className={classes["dashboard-full-overlay"]}></div>
          )}
          <AdminNavigation
            name={data.username}
            onMenuClick={handleMenuClick}
            menuToggle={menuToggle}
          />
          <div className={classes["content-container"]}>
            <DashboardGreeting
              name={data.username}
              onMenuClick={handleMenuClick}
            />
            <main>
              <Outlet />
              <ScrollToTop />
            </main>
            <Copyright />
          </div>
        </>
      )}
    </>
  );
};

export default AdminDashboardRootLayout;
