import { useRef } from "react";

import { Button } from "reactstrap";

const MultipleImageItem = ({
  index,
  handleImageChange,
  handleSubmit,
  handleRemove,
  file,
  previewUrl,
}) => {
  const imageItemRef = useRef();

  const handleClick = () => {
    imageItemRef.current.click();
  };

  const removeImage = (e) => {
    imageItemRef.current.value = null;
    handleRemove(e, index);
  };

  return (
    <div className="fileinput text-center imageItemWrapper">
      <input
        type="file"
        onChange={(e) => handleImageChange(e, index, "updatePhoto")}
        ref={imageItemRef}
        accept="image/png, image/jpeg, image/webp"
      />
      <div className={"thumbnail"} style={{ border: "1px solid #eee" }}>
        {file && (
          <a href={previewUrl} target="_blank" rel="noopener">
            <img src={previewUrl} alt="animal image" />
          </a>
        )}
      </div>
      <div>
        <span>
          <Button className="btn-round multiBtn" onClick={handleClick}>
            Change
          </Button>
            <Button
              color="danger"
              className="btn-round multiBtn close"
              onClick={removeImage}
            >
              <i className="fa fa-times" />
            </Button>

        </span>
      </div>
    </div>
  );
};

export default MultipleImageItem;
