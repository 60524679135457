import classes from "./ApplicantProfile.module.css";
import {
  Card,
  Row,
  Col,
} from "reactstrap";
import ApplicantTags from "./ApplicantTags";
import { user, house, phone, mail, profile} from '../../assets/svg/svgs';

const ApplicantProfile = ({ applicant, username, email, tags, createdAt }) => {
  return (
    <>
      <ApplicantTags tags={tags.length === 0 ? null : tags[0]} />

      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <div className={classes["userData-container"]}>
                <h3 className="title">{username}</h3>
                <p>
                  {user}<span>{`${applicant.firstName} ${applicant.lastName}`}
                  </span>
                  /{profile}<span>Member since {createdAt.substring(0, 4)}</span>
                </p>
                <p>
                  {house}<span>{applicant.city}, {applicant.state}
                  </span>
                  /{phone}<span>{applicant.phone}</span>/{mail}<span>{email}</span>
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ApplicantProfile;
