/*  navigation for page and auth pages  */

import {
  Link,
  useRouteLoaderData,
  matchPath,
  useLocation,
} from "react-router-dom";
import classes from "./Main.module.css";
import { logoNav } from "../../assets/svg/svgs";
import NavigationAuth from "./NavAuth";

const PageNavigation = ({user, auth}) => {
   // const { pathname } = useLocation();
  // const isAuth = matchPath("/auth/*", pathname);
  // const user = useRouteLoaderData(!isAuth ? "pageRoot" : "authRoot");
  // console.log(user);

  return (
    <header>
      <nav className={classes.container}>
        <div className={classes["header-menu-container"]}>
          <div className={classes["header-logo"]}>
            <Link to="/">{logoNav}</Link>
          </div>
        </div>
        <NavigationAuth user={user} auth={auth} />
      </nav>
    </header>
  );
};

export default PageNavigation;
