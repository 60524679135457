import { useEffect, useState } from "react";
import { redirect, useNavigation } from "react-router-dom";
import { Button, Col } from "reactstrap";
import { pdf } from "../../assets/svg/svgs";

const MultipleDocumentItem = ({
  document,
  setMethod,
  setActiveDocument,
  toggle,
}) => {
  const [file, setFile] = useState(null);
  const navigation = useNavigation();

  const handleClick = () => {
    setMethod("PUT");
    setActiveDocument(document);
    toggle();
  };

  const getFile = async() => {
    let status = "";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/files/rescue/animals/${document.animalId}/documents/${document.id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      status = response.status;
      setFile(response.url);
      document.file = response.url;
      return response.url;
    } catch (error) {
      console.log(`Message : ${error.message}, Status : ${status}`);
      return redirect(`/rescue/animals/${document.animalId}`);
    }
  };

  useEffect(() => {
      getFile();
  }, [document]);

  return (
    <Col xl="4" lg="6" style={{ padding: "15px" }}>
      <div style={{ border: "1px solid #eee", padding: "5px" }}>
        <div className={"thumbnail document-thumb"}>
          <a href={file} target="_blank" rel="noopener">
            {document.url.substring(document.url.length - 3) === "pdf" && pdf}
            {document.url.substring(document.url.length - 3) !== "pdf" && (
              <img src={file} alt="document" />
            )}
          </a>
        </div>
        <h6>{document.title}</h6>
        <p>{document.note}</p>
        <div className="flex justify-content flex-end">
          <Button color="fetched-solid" onClick={handleClick}>
            Edit Document
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default MultipleDocumentItem;
