import { useLoaderData, redirect } from "react-router-dom";
import AnimalCards from "../../../components/card/AnimalCards";

const UserDashboardFavoritesPage = () => {
  const data = useLoaderData();
  console.log(data);

  return (
    <div>
        <h5>My Saved Animals</h5>
      <AnimalCards animals={data.animalsLikes} userId={data.id} action="/user/favorites" />
    </div>
  );
};

export default UserDashboardFavoritesPage;

export const action = async ({request}) => {
  const formData = await request.formData();
  let url = `${process.env.REACT_APP_API_ROOT}/users/user/animal/${formData.get(
    "animalId"
  )}`;
  let status = "";

  const response = await fetch(url, {
    method: request.method,
    credentials: "include",
  });
  status = response.status;
  if (!response.ok) {
    console.log("status: " + status);
    return response;
  }
  const responseData = await response.json();
  return responseData;
};

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/users/user/animals`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
