import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import DashboardTable from "../table/DashboardTable";
import { event as eventSvg } from "../../assets/svg/svgs";

const EventTable = ({ events, title, pageSize }) => {
  const processor = (events) => {
    const eventsState = events.map((event, key) => {
      return {
        id: `event_${event.id}`,
        event_id: event.id,
        start_date: event.startDate.substring(0, 10),
        event_title: event.title,
        event_type: event.isPhysical ? "Physical Event" : "Virtual Event",
        actions: (
          <div className="actions-right">
            <Link to={`/rescue/events/${event.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Event"
              >
                {eventSvg}
              </button>
            </Link>
          </div>
        ),
      };
    });
    return eventsState;
  };

  const [dataState, setDataState] = useState(processor(events));

  const flexGrow = ["max-80", "", "grow-4", "grow-2", "grow-0-auto"];

  useEffect(() => {
    setDataState(processor(events));
  }, [events]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <h5>{title}</h5>
          <Card className="dashboardTable">
            <CardBody>
              <DashboardTable
                data={dataState}
                columns={[
                  {
                    Header: "ID",
                    accessor: "event_id",
                  },
                  {
                    Header: "Date",
                    accessor: "start_date",
                  },
                  {
                    Header: "Title",
                    accessor: "event_title",
                  },

                  {
                    Header: "Event Type",
                    accessor: "event_type",
                  },
                  {
                    Header: (
                      <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                        {eventSvg}
                      </button>
                    ),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                className="-striped -highlight primary-pagination"
                flexGrow={flexGrow}
                pageRowCount={pageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EventTable;
