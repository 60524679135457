import classes from './Greeting.module.css';
import { menu } from "../../assets/svg/svgs";

const DashboardGreeting = ({name, onMenuClick}) => {
    const now = new Date();
    const hour = now.getHours();
    const greeting = hour >= 12 ? "Good afternoon" : "Good morning";

    const handleMenu = () => {
        onMenuClick(true);
    }

    return (
        <div className={classes["dashboard-greeting-wrapper"]}>
            <button onClick={handleMenu} className={classes["dashboard-menu-button"]}>{menu}</button>
            <div className={classes["dashboard-greeting"]}>
                <h3>{`${greeting}, ${name.charAt(0).toUpperCase() + name.slice(1)}`}</h3>
            </div>
        </div>
        
    )
}



export default DashboardGreeting;