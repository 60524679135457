import { json, redirect, useActionData, Link } from "react-router-dom";
import classes from "./Login.module.css";
import { encode, decode } from "string-encode-decode";
import RescueSignupForm from "../components/form/RescueSignupForm";

const RegisterPage = () => {
  const errors = useActionData();

  return (
    <>
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.redirectTop}>
          Not a rescue? <Link to="/auth/signup">Sign Up Here</Link>
        </div>

        <div className={classes["prompt-wrapper"]}>
          <h2>Rescue Sign Up</h2>
          {errors?.message && (
            <span style={{ color: "red" }}>{errors.message}</span>
          )}
        </div>
        <div className={classes["form-wrapper"]}>
          <RescueSignupForm />
        </div>
      </div>
    </div>
    </>
    
  );
};

export default RegisterPage;

// username, password, email
// `${process.env.REACT_APP_API_ROOT}/auth/registerRescue`,

export const action = async ({ request }) => {
  const data = Object.fromEntries(await request.formData());
  let status = "";
  const errors = {};
  const response = await fetch(
    `${process.env.REACT_APP_API_ROOT}/auth/registerRescue`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  status = response.status;
  if (!response.ok) {
    errors.message = response.message;
    return errors;
  }
  //  const resData = await response.json();
  return redirect("/auth/login");
};
