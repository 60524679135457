import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Tag from "../../ui/Tag";
import classes from "./AnimalProfile.module.css";
import {
  application as applicationSvg,
  dog,
  cat
} from "../../assets/svg/svgs";

const ApplicationAnimalProfile = ({ animal }) => {

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader className={classes.cardHeader}>
              <Link to={`/rescue/animals/${animal.id}`}>
                <h5 className="title">
                  {animal.animalMeta.species === "DOG" ? dog : animal.animalMeta.species === "CAT" ? cat : null}
                  {animal.name}
                </h5>
              </Link>
            </CardHeader>
            <CardBody>
              <div className={classes.tagWrapper}>
                <Tag
                  title={animal.adoptionStatus.replaceAll("_", " ")}
                  type={null}
                  size="small"
                />
                <Tag
                  title={animal.animalMeta.species}
                  type={null}
                  size="small"
                />
              </div>
              <p className={classes.info1}>
                {animal.animalMeta.age.toLowerCase().replaceAll("_", " ")} |{" "}
                {animal.animalMeta.size.toLowerCase()} |{" "}
                {animal.animalMeta.gender.toLowerCase()}
              </p>
              <p className={classes.info2}>
                {animal.animalMeta.primaryBreed.name} 
                {animal.animalMeta.secondaryBreed?.name && ` / ${animal.animalMeta.secondaryBreed.name}` }
                {" "} Mix
              </p>
              <br />
              <p className={classes.info2}>
                {applicationSvg} {animal.name} has {animal._count.applications}{" "}
                application
                {animal._count.applications > 1 ? "s" : ""}
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationAnimalProfile;
