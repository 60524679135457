import { useState } from "react";
import { useSubmit, useParams } from "react-router-dom";
import Tag from "../../ui/Tag";
import TagDropdown from "../../ui/TagDropdown";

const ApplicantTags = ({ tags }) => {
  const submit = useSubmit();
  const { applicantId } = useParams();

  const [isNewApplicant, setIsNewApplicant] = useState(
    tags === null ? false : tags.isNewApplicant
  );
  const [isRepeatApplicant, setIsRepeatApplicant] = useState(
    tags === null ? false : tags.isRepeatApplicant
  );
  const [isRepeatAdopter, setIsRepeatAdopter] = useState(
    tags === null ? false : tags.isRepeatAdopter
  );
  const [isFlagged, setIsFlagged] = useState(
    tags === null ? false : tags.isFlagged
  );
  const [toggle, setToggle] = useState(false);

  let formData = new FormData();
  formData.append("form-id", "tags");
  formData.append("tagId", tags.id);
  formData.append("applicantId", applicantId);
  formData.append("isNewApplicant", isNewApplicant);
  formData.append("isRepeatApplicant", isRepeatApplicant);
  formData.append("isRepeatAdopter", isRepeatAdopter);
  formData.append("isFlagged", isFlagged);

  const handleStateChange = async (state) => {
    switch (state) {
      case "newApplicant":
        formData.set("isNewApplicant", isNewApplicant ? false : true);
        setIsNewApplicant(!isNewApplicant);

        break;
      case "repeatAdopter":
        formData.set("isRepeatAdopter", isRepeatAdopter ? false : true);
        setIsRepeatAdopter(!isRepeatAdopter);

        break;
      case "repeatApplicant":
        formData.set("isRepeatApplicant", isRepeatApplicant ? false : true);
        setIsRepeatApplicant(!isRepeatApplicant);

        break;
      case "flagged":
        formData.set("isFlagged", isFlagged ? false : true);
        setIsFlagged(!isFlagged);
        break;
      default:
        break;
    }

    submit(formData, { method: "PUT", action: "/rescue/applicants" });

    setToggle(false);

    console.log("formData from end of handle Click : " + formData);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        margin: "0 10px 20px 0",
      }}
    >
      {isNewApplicant && (
        <Tag
          title="New Applicant"
          type="newApplicant"
          handleClick={handleStateChange}
        />
      )}
      {isRepeatAdopter && (
        <Tag
          title="Repeat Adopter"
          type="repeatAdopter"
          handleClick={handleStateChange}
        />
      )}
      {isRepeatApplicant && (
        <Tag
          title="Repeat Applicant"
          type="repeatApplicant"
          handleClick={handleStateChange}
        />
      )}
      {isFlagged && (
        <Tag title="Flagged" type="flagged" handleClick={handleStateChange} />
      )}

      {(!isNewApplicant ||
        !isRepeatApplicant ||
        !isRepeatAdopter ||
        !isFlagged) && (
        <TagDropdown
          text="+"
          isNewApplicant={isNewApplicant}
          isRepeatAdopter={isRepeatAdopter}
          isRepeatApplicant={isRepeatApplicant}
          isFlagged={isFlagged}
          handleClick={handleStateChange}
          toggle={toggle}
          setToggle={setToggle}
        />
      )}
    </div>
  );
};

export default ApplicantTags;
