
import { Form, Link, useActionData, useNavigation } from "react-router-dom";

const RescueSignupForm = () => {

  // const data = useActionData();
  // const navigation = useNavigation();
  // const isSubmitting = navigation.state === 'submitting';


  // username, password, email, ein, contactName, phone

  return (
    <Form method="post">
      <div>
        <label>Username</label>
        <div>
          <input type="text" id="username" name="username" required />
        </div>
      </div>
      <div>
        <label>Email Address</label>
        <div>
          <input type="email" id="email" name="email" required />
        </div>
      </div>
      <div>
        <label>Password</label>
        <div>
          <input type="password" id="password" name="password" required />
        </div>
      </div>
      <div>
        <label>EIN</label>
        <div>
          <input type="text" id="ein" name="ein" required />
        </div>
      </div>
      <div>
        <label>Contact Person</label>
        <div>
          <input type="text" id="contactName" name="contactName" required />
        </div>
      </div>
      <div>
        <label>Phone Number</label>
        <div>
          <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required/>
          <small>Format: 123-456-7890</small>
        </div>
      </div>
      <button>Sign up</button>

      Already has an account? <Link to="/auth/login">Login</Link>
    </Form>
  );
};

export default RescueSignupForm;
