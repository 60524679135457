import { useState, useRef, useEffect } from "react";
import { useSubmit, useParams } from "react-router-dom";
import MultipleImageItem from "./MultipleImageItem";
import { Button } from "reactstrap";

const urlRoot = `${process.env.REACT_APP_API_BASE_ROOT}`;

const MultipleImageUpload = ({ photos }) => {

  // const urlRoot = `${process.env.REACT_APP_API_BASE_ROOT}`;

  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);

  const submit = useSubmit(); 

  const addImageRef = useRef();
  const { animalId } = useParams();

  const maxFiles = 10;

  const handleSubmit = (e, formData) => {
    e.preventDefault();
    const actionPath = `/rescue/animals/${animalId}`;
    submit(formData, { method: "POST", encType: "multipart/form-data", action: actionPath });
  };

  const handleRemoval = (e, formData) => {
    e.preventDefault();
    const actionPath = `/rescue/animals/${animalId}`;
    submit(formData, {method: "POST", action: actionPath});
  }

  const handleImageChange = (e, i, formId) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("form-id", formId);
    formData.append("fileType", "photo");
    formData.append("animalId", animalId);
    formData.append("file", file);
    if(formId === "updatePhoto") {
      const delete_image_url = previewUrls[i].replace(urlRoot, "");
      formData.append("delete_image_url", delete_image_url);
      formData.append("photo_id", files[i]);
    }

    reader.onloadend = () => {
      const newFiles = [...files];
      newFiles[i] = file;
      setFiles(newFiles);
      const newPreviewUrls = [...previewUrls];
      newPreviewUrls[i] = reader.result;
      setPreviewUrls(newPreviewUrls);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    handleSubmit(e, formData);
  };

  const handleRemove = (e, i) => {
    const image_url = previewUrls[i].replace(urlRoot, "");
    const formData = new FormData();
    formData.append("form-id", "deletePhoto");
    formData.append("animalId", animalId);
    formData.append("photo_id", files[i]);
    formData.append("image_url", image_url);

    handleRemoval(e, formData );
  };

  const handleClick = () => {
    addImageRef.current.click();
  };

  const resetStates = (photos) => {
    const newFiles = [];
    const newUrls = [];
    for (const photo of photos) {
      newFiles.push(photo.id);
      newUrls.push(`${urlRoot}${photo.img_url}`);
    }
    setFiles(newFiles);
    setPreviewUrls(newUrls);
  }

  useEffect(() => {
    if (photos.length > 0) {
      resetStates(photos);
    }
  }, [photos]);


  return (
    <div className="flex-column">
      <p>{files.length} of 10 photos uploaded</p>
      <div className="flex-wrap">
        {files.map((file, i) => (
          <MultipleImageItem
            key={i}
            index={i}
            handleImageChange={handleImageChange}
            handleSubmit={handleSubmit}
            handleRemove={handleRemove}
            file={file} // photo id
            previewUrl={previewUrls[i]}  // photo image url
          />
        ))}

        {/* if photos uploaded are under 10, display add photo button */}
        {files.length < maxFiles && (
          <div className="imageAddWrapper">
            <input
              type="file"
              onChange={(e) => {
                files.push(null);
                previewUrls.push(null);
                handleImageChange(e, files.length - 1, "addPhoto");
              }}
              ref={addImageRef}
              accept="image/png, image/jpeg, image/webp"
              style={{ display: "none" }}
            />
            <Button
              color="info"
              className="btn-round multiBtn"
              onClick={handleClick}
            >
              <i className="fa fa-plus" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultipleImageUpload;
