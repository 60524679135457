import { useState } from "react";
import { Form, redirect } from "react-router-dom";
import { Card, CardBody, Row, Col, Input, Button } from "reactstrap";
import { stateOptions } from "../../../utils/enums";
import Select from "react-select";

const AdminDashboardNewRescue = () => {
  // const [user, setUser] = useState(null);
  const [usState, setUsState] = useState("");

  return (
    <div>
      <h1>Add New Rescue</h1>
      <Card>
        <CardBody>
          <Form method="PUT">
            <Row style={{ alignItems: "flex-end" }}>
              <Col md="3" className="pr-1">
                <div className="form-group">
                  <label>User ID</label>
                  <Input
                    type="text"
                    placeholder="User ID"
                    name="userId"
                    required
                  />
                </div>
              </Col>
              <Col md="6" className="px-1">
                <div className="form-group">
                  <label>Legal Rescue Name</label>
                  <Input
                    type="text"
                    placeholder="Legal Rescue Name"
                    name="legalRescueName"
                    required
                  />
                </div>
              </Col>
              <Col md="3" className="pl-1">
                <div className="form-group">
                  <label>EIN</label>
                  <Input type="text" placeholder="EIN" name="ein" required />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="form-group">
                  <label>Street Address</label>
                  <Input
                    type="text"
                    placeholder="Street Address"
                    name="streetAddress1"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="pr-1">
                <div className="form-group">
                  <label>City</label>
                  <Input type="text" placeholder="City" name="city" required />
                </div>
              </Col>
              {/* <Col md="3" className="px-1">
                <div className="form-group">
                  <label>State</label>
                  <Input
                    type="text"
                    placeholder="State"
                    name="state"
                    required
                  />
                </div>
              </Col> */}
              <Col className="px-1" md="3">
                <div className="form-group">
                  <label>State*</label>
                  <Select
                    id="state"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    required
                    name="state"
                    value={usState}
                    onChange={(value) => {
                      setUsState(value);
                    }}
                    options={stateOptions}
                    placeholder="State"
                  />
                </div>
              </Col>
              <Col md="3" className="pl-1">
                <div className="form-group">
                  <label>Zipcode</label>
                  <Input
                    type="text"
                    placeholder="Zipcode"
                    name="zipCode"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="pr-1">
                <div className="form-group">
                  <label>Phone Number</label>
                  <Input
                    type="text"
                    placeholder="Phone Number"
                    name="phone"
                    required
                  />
                </div>
              </Col>
              <Col md="6" className="pl-1">
                <div className="form-group">
                  <label>Contact Name</label>
                  <Input
                    type="text"
                    placeholder="Contact Name"
                    name="contactName"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Button color="fetched-solid">Add Rescue</Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AdminDashboardNewRescue;

export const action = async ({ request }) => {
  const formData = await request.formData();
  const rescueData = Object.fromEntries(formData);
  console.log(JSON.stringify(rescueData));
  let status = "";
  let url = `${process.env.REACT_APP_API_ROOT}/admin/users/${formData.get(
    "userId"
  )}/rescue`;
  const response = await fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rescueData),
  });

  status = response.status;
  if (!response.ok) {
    console.log(response);
    return response;
  }
  const data = await response.json();
  return redirect("/admin/rescues/");
};
