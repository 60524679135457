import { useLoaderData, redirect } from "react-router-dom";
import UserQuestionTable from "../../../components/dashboard/UserQuestionTable";

const UserDashboardQuestionsPage = () => {
  const data = useLoaderData();
  console.log(data);
  const defaultPageSize = 10;

  return (
    <div>
      <UserQuestionTable
        questions={data.animalQuestions}
        title="My Questions"
        pageSize={defaultPageSize}
      />
    </div>
  );
};

export default UserDashboardQuestionsPage;

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/users/user/questions`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
