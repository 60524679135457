import {useState} from 'react';
import classes from "../dashboard/CustomForm.module.css";


import {
    Button,
    Input,
    Row,
    Col,
    FormGroup,
    Label,
    InputGroup,
    InputGroupText,
  } from "reactstrap";

const CustomBoolean = ({question, idx}) => {
    const [checked, setChecked] = useState(false);
    
    const handlingToggle = e => {
        setChecked((prevState) => !prevState);
    }

  return (
    <Row>
      <Col md="12">
        <InputGroup className={classes.inputGroup}>
          <InputGroupText className={classes.inputGroupText}>
            <Input
              className={classes["formControl_checkbox"]}
              id={`fetchedAppQuestion_${question.category}_${idx}`}
              addon
              type="checkbox"
              name={`fetchedAppQuestion_${question.category}_${idx}`}
              checked={checked}
              onChange={handlingToggle}
              value={checked}
              required={question.isRequired}
              data-question-type={question.type}
              data-question-category={question.category}
              data-question-idx={idx}

            />
          </InputGroupText>
          <Input
            className={classes.formControl}
            value={`${question.question}${question.isRequired ? "*" : ""}`}
          />
        </InputGroup>
      </Col>
    </Row>
  );
};

export default CustomBoolean;
