import {useState} from "react";
import { Form, useNavigation } from "react-router-dom";
import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import classes from "../components/dashboard/CustomForm.module.css";

const ContactPage = () => {

  const [topic, setTopic] = useState("");
  const handleSetTopic = e => {
    e.preventDefault();
    setTopic(e.target.value);
  }

  return (
    <div className={classes.container50}>
        <h1 className={classes.title}>Contact Fetched</h1>
       <Row>
          <Col md="12">
            <div className="form-group">
              <Label for="topic">Topic</Label>
              <Input
                className={classes.formSelect}
                id="topic"
                name="topic"
                type="select"
                required
                value={topic}
                onChange={handleSetTopic}
              >
                <option value="" key="option_0" disabled>
                  Select Message Topic
                </option>
                <option value="animal">I have question about an animal</option>
                <option value="rescue">I have question about a rescue</option>
                <option value="applicationStatus">I have question about my application status</option>
                <option value="siteInfo">General Site Info</option>
                <option value="rescueAccount">
                  I have question about my rescue account
                </option>
                <option value="userAccount">I have question about my user account</option>
                <option value="rescueSignup">I have trouble sign up for a rescue account</option>
                <option value="other">My question is not on this list</option>
              </Input>
            </div>
          </Col>
        </Row>
        {topic === "animal" && <div className={classes.messageWrapper}><h5>Please contact the rescue listed on the animal profile page for any of your animal related question. </h5></div>}
        {topic === "rescue" && <div className={classes.messageWrapper}><h5>Please contact the specific rescue for any of your rescue related question.</h5></div>}
        {topic === "applicationStatus" && <div className={classes.messageWrapper}><h5>Please log in to your user account. You will be able to see all of your application updates right on your user dashboard.</h5></div>}
      {
        (topic === "siteInfo" || topic === "rescueAccount" || topic === "userAccount" || topic === "rescueSignup" || topic === "other") && 
        <Form>
            <Row>
            <Col md="12">
                <div className="form-group">
                <label for="subject">Message Subject*</label>
                <Input
                    id="subject"
                    type="text"
                    name="subject"
                    className="mt-1"
                    required
                />
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="form-group">
                <label for="name">Name*</label>
                <Input
                    id="name"
                    type="text"
                    name="name"
                    className="mt-1"
                    required
                />
                </div>
            </Col>
            </Row>
            <Row>
            <Col md="12">
                <div className="form-group">
                <label for="email">Email*</label>
                <Input
                    id="email"
                    type="email"
                    name="email"
                    className="mt-1"
                    required
                />
                </div>
            </Col>
            </Row>
            {/* <Row>
            <Col md="12">
                <div className="form-group">
                <Label for="topic">Topic*</Label>
                <Input
                    className={classes.formSelect}
                    id="topic"
                    name="topic"
                    type="select"
                    required
                >
                    <option value="" key="option_0" disabled>
                    Select Message Topic
                    </option>

                    <option value="General Site Info">General Site Info</option>
                    <option value="Rescue Account Issue">
                    Rescue Account Issue
                    </option>
                    <option value="User Account Issue">User Account Issue</option>
                </Input>
                </div>
            </Col>
            </Row> */}
            <Row>
            <Col md="12">
                <FormGroup>
                <Label for="message">Message*</Label>
                <Input
                    className="textarea"
                    type="textarea"
                    cols="80"
                    rows="10"
                    name="message"
                />
                </FormGroup>
            </Col>
            </Row>
            <Button>Submit</Button>
        </Form>
      }
    </div>
  );
};

export default ContactPage;
