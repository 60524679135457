import { useLoaderData, redirect } from "react-router-dom";
import QuestionTable from "../../../components/dashboard/QuestionTable";

const RescueDashboardQuestionsPage = () => {
  const data = useLoaderData();
  const defaultPageSize = 10;

  console.log(data);

  return (
    <div>
      <QuestionTable questions={data} title="Questions & Comments" pageSize={defaultPageSize}/>
    </div>
  );
};

export default RescueDashboardQuestionsPage;

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/questions`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch(error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
