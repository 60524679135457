import { useState, useEffect } from "react";
import {
  Link,
  useLoaderData,
  Form,
  useSubmit,
  useActionData,
} from "react-router-dom";
import CustomSelect from "../components/customInput/CustomSelect";
import CustomBoolean from "../components/customInput/CustomBoolean";
import Footer from "../components/Footer";
import classes from "../components/dashboard/CustomForm.module.css";
import { states } from "../utils/enums";
import CustomText from "../components/customInput/CustomText";

import {
  Button,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  FormGroup,
  Label,
} from "reactstrap";

const ApplicationPage = () => {
  const data = useLoaderData();
  const metaFields = [
    "firstName",
    "lastName",
    "pronouns",
    "email",
    "streetAddress1",
    "city",
    "state",
    "zipCode",
  ];
  const rescueId = data.animal.rescue.id;
  const animalId = data.animal.id;

  console.log(data);
  const submit = useSubmit();
  const actionData = useActionData();

  const [appQuestions, setAppQuestions] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [ageToggle, setAgeToggle] = useState(false);
  const [formError, setFormError] = useState([]);

  const getCustomInput = (type, q, idx) => {
    switch (type) {
      case "SELECT":
        return <CustomSelect question={q} idx={idx + 1} />;
      case "TEXT":
        return <CustomText question={q} idx={idx + 1} />;
      case "BOOLEAN":
        return <CustomBoolean question={q} idx={idx + 1} />;
      default:
        return;
    }
  };

  const handlePhoneNumber = (e) => {
    let num = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let updateNum = !num[2]
      ? num[1]
      : +num[1] + "-" + num[2] + (num[3] ? "-" + num[3] : "");
    setPhoneNumber(updateNum);
  };

  const handlingToggle = (e) => {
    setAgeToggle((prevState) => !prevState);
  };

  const handlingFormSubmit = async (e) => {
    e.preventDefault();
    formError.forEach((error) => {
      error.node.classList.remove("errorInput");
    });
    setFormError([]);
    const errorFields = [];
    const questions = [];
    // TODO: also need to check for validity of applicant meta questions, firstname, lastname, address.... etc
    const list = document.querySelectorAll('[id^="fetchedAppQuestion_"]');
    [...list].map((item) => {
      const category = item.getAttribute("data-question-category");
      const originalQuestion =
        appQuestions[category][item.getAttribute("data-question-idx") - 1];
      if (item.required == true && item.value == "") {
        errorFields.push({ node: item, question: originalQuestion.question });
      }
      const obj = {
        category,
        question: originalQuestion.question,
        type: originalQuestion.type,
        answer: item.type != "checkbox" ? item.value : item.value == "true" ? "Yes" : "No"
      };
      // if(item.type != "checkbox") {
      //   obj.answer = item.value;
      // } else {
      //   obj.answer = item.value == "true" ? "Yes" : "No";
      // }
      questions.push(obj);
    });
    if (errorFields.length > 0) {
      setFormError(errorFields);
      errorFields.forEach((error) => {
        error.node.classList.add("errorInput");
      });
    } else {
      const formData = new FormData();
      formData.append("questions", JSON.stringify(questions));
      formData.append("animalId", animalId);
      formData.append("rescueId", rescueId);
      formData.append("age", ageToggle);
      formData.append("phone", phoneNumber);
      metaFields.forEach((field) => {
        formData.append(field, document.getElementById(field).value);
      });
      const actionPath = `/page/application/${rescueId}/${animalId}`;
      submit(formData, { method: "POST", action: actionPath });
    }
  };

  useEffect(() => {
    if (!data.errorMessage) {
      setAppQuestions(data.questions);
      setPhoneNumber(data.user.userMeta?.phone ? data.user.userMeta.phone : "");
    }
  }, []);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Adoption Application</h1>

      {actionData?.message && <span>{actionData.message}</span>}
      <div className={classes.applicationMainHeaderContainer}> 
      
        <Col md="12" className={classes.applicationMainHeader}>
          <Col md="5" className="pl-0 pr-40">
            <div className={classes.imgContainer}>
              <img
                src={`${process.env.REACT_APP_API_BASE_ROOT}${data.animal.photos[0].img_url}`}
              ></img>
            </div>
          </Col>
          <Col md="7" className="pl-1">
            <h2>{data.animal.name}</h2>
            <p>
              <span className={classes.capitalize}>{data.animal.animalMeta.age.toLowerCase()} {data.animal.animalMeta.gender.toLowerCase()}{" "}</span> 
              
              {data.animal.animalMeta.primaryBreed.name}{" "}
              {data.animal.animalMeta.isMixed ? "Mix" : ""}
            </p>
            <p>Cared for by <strong>{data.animal.rescue.legalRescueName}</strong></p>
            <p>
              Require home check:{" "}
              {data.animal.rescue.rescueMeta.homeCheckReq ? "Yes" : "No"}{" "}
            </p>
            <p>
              Require interview:{" "}
              {data.animal.rescue.rescueMeta.interviewReq ? "Yes" : "No"}{" "}
            </p>
            <p>
              Minimum adoption age required:{" "}
              {data.animal.rescue.rescueMeta.adoptionAgeReq}{" "}
            </p>
            <p>
              Adoption fee:{" "}
              ${data.animal.animalMeta.adoptionFee}{" "}
            </p>
          </Col>
        </Col>
      
      </div>
      {data.errorMessage && <span>{data.errorMessage}</span>}
      {!data.errorMessage && !appQuestions && <h2>Loading Questions</h2>}
      {actionData?.applicationSuccess && <><h2>{actionData.applicationSuccess}</h2><Link to={`/page/animals/${animalId}`}>Back to animal</Link></>}
      {!actionData?.applicationSuccess && appQuestions && (

        <Form className={classes.formContainer}>
            <div className={classes.sectionContainer}>
              <h2>Personal Questions</h2>
            <Row>
              <Col md="4">
                <div className="form-group">
                  <label for="firstName">First name*</label>
                  <Input
                    id="firstName"
                    type="text"
                    name="firstName"
                    className="mt-1"
                    required
                    defaultValue={
                      data.user.userMeta?.firstName
                        ? data.user.userMeta.firstName
                        : ""
                    }
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="form-group">
                  <label for="lastName">Last name*</label>
                  <Input
                    id="lastName"
                    type="text"
                    name="lastName"
                    className="mt-1"
                    required
                    defaultValue={
                      data.user.userMeta?.lastName
                        ? data.user.userMeta.lastName
                        : ""
                    }
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="form-group">
                  <label for="pronouns">Pronouns</label>
                  <Input
                    id="pronouns"
                    type="text"
                    name="pronouns"
                    className="mt-1"
                    defaultValue={
                      data.user.userMeta?.pronouns
                        ? data.user.userMeta.pronouns
                        : ""
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="form-group">
                  <label for="phone">Phone Number*</label>
                  <Input
                    id="phone"
                    type="text"
                    name="phone"
                    className="mt-1"
                    required
                    onChange={handlePhoneNumber}
                    value={phoneNumber}
                    placeholder="555-555-5555"
                    maxLength="12"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="form-group">
                  <label for="email">Email Address*</label>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    className="mt-1"
                    required
                    defaultValue={data.user.email}
                  />
                </div>
              </Col>
              <Col md="4" style={{ display: "flex", alignItems: "flex-end" }}>
                <InputGroup className={classes.inputGroup}>
                  <InputGroupText className={classes.inputGroupText}>
                    <Input
                      className={classes["formControl_checkbox"]}
                      id="adoptionAgeReq"
                      addon
                      type="checkbox"
                      name="adoptionAgeReq"
                      checked={ageToggle}
                      onChange={handlingToggle}
                      value={ageToggle}
                      required
                    />
                  </InputGroupText>
                  <Input
                    className={classes.formControl}
                    value={`Are you over the age of ${data.animal.rescue.rescueMeta.adoptionAgeReq}?*`}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="form-group">
                  <label for="streetAddress1">Street Address*</label>
                  <Input
                    id="streetAddress1"
                    type="text"
                    name="streetAddress1"
                    className="mt-1"
                    required
                    defaultValue={
                      data.user.userMeta?.streetAddress1
                        ? data.user.userMeta.streetAddress1
                        : ""
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label for="city">City*</label>
                  <Input
                    id="city"
                    type="text"
                    name="city"
                    className="mt-1"
                    required
                    defaultValue={
                      data.user.userMeta?.city ? data.user.userMeta.city : ""
                    }
                  />
                </div>
              </Col>

              <Col md="3">
                <FormGroup>
                  <Label for="state">State*</Label>
                  <Input
                    className={classes.formSelect}
                    id="state"
                    name="state"
                    type="select"
                    
                    defaultValue={data.user.userMeta.state}
                    required
                  >
                    <option value="" key="option_0" disabled>
                      Select State
                    </option>
                    {states.map((item, i) => (
                      <option value={item} key={`option_${i + 1}`}>
                        {item}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="3">
                <div className="form-group">
                  <label for="zipCode">ZIP code*</label>
                  <Input
                    id="zipCode"
                    type="text"
                    name="zipCode"
                    className="mt-1"
                    required
                    defaultValue={
                      data.user.userMeta?.zipCode
                        ? data.user.userMeta.zipCode
                        : ""
                    }
                  />
                </div>
              </Col>
            </Row>

            {appQuestions.PERSONAL.map((q, i) =>
              getCustomInput(q.fieldType, q, i)
            )}
            </div>
            
            <div className={classes.sectionContainer}> 
              <h2>General Questions</h2>
              {appQuestions.GENERAL.map((q, i) =>
                getCustomInput(q.fieldType, q, i)
              )}
            </div>
            <div className={classes.sectionContainer}> 
              <h2>Living Environment</h2>
              {appQuestions["LIVING_ENVIORNMENT"].map((q, i) =>
                getCustomInput(q.fieldType, q, i)
              )}
            </div>
            <div className={classes.sectionContainer}> 
              <h2>Previous and Current Animal</h2>
              {appQuestions["PREVIOUS_CURRENT_ANIMAL"].map((q, i) =>
                getCustomInput(q.fieldType, q, i)
              )}
            </div>
            
            {formError.length > 0 ? (
              <>
                <span>There are errors in this application</span>
                <ul>
                  {formError.map((error, i) => (
                    <li key={`error_${i + 1}`}>{error.question}</li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )} 
             {actionData?.applicationError && <span>{actionData.applicationError}</span>}
             {actionData?.applicationSuccess && <>
             <span>{actionData.applicationSuccess}</span>
             <Link to={`/page/animals/${animalId}`}>Back to Animal</Link>
             </>}
            {!actionData?.applicationSuccess && <Button onClick={handlingFormSubmit}>Submit</Button>}
            
          </Form>

      )}
      {/* <Footer /> */}
    </div>
  );
};

export default ApplicationPage;

export const action = async ({ request, params }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  const url = `${process.env.REACT_APP_API_ROOT}/users/user/application`;
  console.log(data);
  let status = "";
  const actionData = {};
  // actionData.message = "passed to action";
  // return actionData;

  const response = await fetch(
    url,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  status = response.status;
  if (!response.ok) {
    actionData.applicationError =
      "Can't send application at this time.  Try again later.";
    return actionData;
  }

  actionData.applicationSuccess = "Success!";
  return actionData;
};

export const loader = async ({ request, params }) => {
  const { animalId, rescueId } = params;
  let status = "";
  const url = `${process.env.REACT_APP_API_ROOT}/users/rescue/applicationQuestions`;

  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ rescueId: rescueId, animalId: animalId }),
  });
  status = response.status;
  if (!response.ok) {
    console.log("status: " + status);
    const responseData = {
      errorStatus: 401,
      errorMessage:
        "Animal and Rescue cannot be verified.  Please verify you have a valid link.",
    };
    return responseData;
  }
  const responseData = await response.json();
  responseData.questions = scrubQuestions(
    responseData.standardQuestions,
    responseData.customQuestions,
    responseData.animal.animalMeta.species
  );

  return responseData;
};

const scrubQuestions = (standard, custom, species) => {
  const data = {
    GENERAL: [],
    LIVING_ENVIORNMENT: [],
    PREVIOUS_CURRENT_ANIMAL: [],
    PERSONAL: [],
  };
  getQuestions(data, standard, "STANDARD", species);
  getQuestions(data, custom, "CUSTOM", species);

  return data;
};

const getQuestions = (dataObj, questions, str, species) => {
  const len = questions.length;
  for (let i = 0; i < len; i++) {
    if (species === "DOG" && !questions[i].isDog) continue;
    if (species === "CAT" && !questions[i].isCat) continue;
    questions[i].type = str;
    questions[i].typeIdx = i;
    dataObj[questions[i].category].push(questions[i]);
  }
};
