import { Card, CardBody, Row, Col } from "reactstrap";
import classes from "./ApplicationQuestions.module.css";
import { questionCircle } from "../../assets/svg/svgs";

const ApplicationQuestions = ({ questions, category }) => {
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              {questions.map((question, i) => 
              (
                question.category === category &&
                <div
                  key={`${category}_${i + 1}`}
                  className={classes.questionWrapper}
                >
                  {questionCircle} <p>{question.question}</p>
                  <span>Answer: {question.answer}</span>
                </div>
              )
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationQuestions;
