import {
  Form,
  Link,
  useActionData,
  useNavigation,
} from "react-router-dom";

const LoginForm = () => {

    const data = useActionData();
    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting'; 

  return (
    <Form method="post">
      <div>
        <label>Username</label>
        <div>
          <input type="text" id="username" name="username" required/>
        </div>
      </div>
      <div>
        <label>Password</label>
        <div>
          <input type="password" id="password" name="password" required/>
        </div>
      </div>
      <button disabled={isSubmitting}>{isSubmitting ? "Authenticating..." : "Sign in"}</button>
      <div>
                Do not have a Fetched account? <Link to="/auth/signup">Sign Up</Link> <br />
        Are you a rescue? <Link to="/auth/register">Rescue Sign Up</Link>
      </div>

    </Form>
  );
};

export default LoginForm;