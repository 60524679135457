/*
  navigation for homepage
*/

import { Link, useRouteLoaderData } from "react-router-dom";
import classes from "./Main.module.css";
import { logoNav } from "../../assets/svg/svgs";
import NavigationAuth from "./NavAuth";

const MainNavigation = () => {

  const user = useRouteLoaderData("root");

  return (
    <header>
      <nav className={classes.container}>
        <div className={classes["header-menu-container"]}>
          <div className={classes["header-logo"]}>
            <Link to="/">
              {logoNav}
            </Link>
          </div>
          <div className={classes["header-menu-wrapper"]}>
            <ul>
              <li>
                <a href="#mission">
                  Mission
                </a>
              </li>
              <li>
                <a href="#spotlight">
                  Spotlight
                </a>
              </li>
              <li>
                <a href="#forAdopters">
                  For Adopters
                </a>
              </li>
              <li>
                <a href="#forRescues">
                  For Rescues
                </a>
              </li>
            </ul>
          </div>
        </div>
        <NavigationAuth user={user} auth="home"/>
      </nav>
    </header>
  );
};

export default MainNavigation;
