import {useState} from "react";
import { useLoaderData, redirect } from "react-router-dom";
import CustomTable from "../../../components/dashboard/CustomTable";
import CustomForm from "../../../components/dashboard/CustomForm";
import { customQuestionDefault } from '../../../utils/initialState';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row
} from "reactstrap";

const RescueDashboardCustomsPage = () => {
  const [modal, setModal] = useState(false);
  const data = useLoaderData();
  const defaultPageSize = 10;

  console.log(data);

  const toggle = () => {
    setModal(prevState => !prevState);
  }

  return (
    <div>
      <Row>
        <Button color="fetched-solid" onClick={toggle}>+ Add New Custom Question</Button>
      </Row>
      <CustomTable customs={data.customQuestions} title="Custom Questions" pageSize={defaultPageSize}/>
 
      <Modal isOpen={modal} toggle={toggle} className="dashboardFormModal modal-dialog-centered">
        <ModalHeader toggle={toggle}>Add New Custom Question</ModalHeader>
        <ModalBody>
          <CustomForm customQuestion={customQuestionDefault} method="POST" handleToggle={setModal}/>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RescueDashboardCustomsPage;

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/customQuestions`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
