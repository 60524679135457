import classes from "./MainPage.module.css";
import { useLoaderData, redirect } from "react-router-dom";
import NoteTimeline from "../../../components/dashboard/NoteTimeLine";
import ApplicantProfile from "../../../components/dashboard/ApplicantProfile";
import ApplicationTable from "../../../components/dashboard/ApplicationTable";

const RescueDashboardApplicantPage = () => {
  const data = useLoaderData();
  console.log(data);

  return (
    <div className={classes.container}>
      <div className="col-sm-12 col-md-8">
        <ApplicantProfile
          applicant={data.userMeta}
          username={data.username}
          email={data.email}
          tags={data.applicantTags}
          createdAt={data.createdAt}
        />

        <ApplicationTable
          applications={data.applications}
          title="Applications"
          pageSize={10}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <NoteTimeline notes={data.applicantNotesApplicant} title="" />
      </div>
    </div>
  );
};

export default RescueDashboardApplicantPage;

export const multiActions = async ({ request, params }) => {
  const formData = await request.formData();
  const formId = formData.get("form-id");
  let status = "";
  let url = "";

  switch (formId) {
    case "note":
      const note = { note: formData.get("note") };
      url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/applicants/${params.applicantId}/note`;
      const noteResponse = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(note),
      });
      status = noteResponse.status;
      if (!noteResponse.ok) {
        console.log("status: " + status);
        return noteResponse;
      }
      const noteData = await noteResponse.json();
      console.log("data" + noteData);
      return noteData;

    case "tags":
      const tagId = formData.get("tagId");
      const tags = {
        isNewApplicant : formData.get("isNewApplicant"),
        isRepeatAdopter : formData.get("isRepeatAdopter"),
        isRepeatApplicant : formData.get("isRepeatApplicant"),
        isFlagged : formData.get("isFlagged")
      }
      url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/applicantTags/${tagId}`;

      const tagsResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tags),
      });
      status = tagsResponse.status;
      if (!tagsResponse.ok) {
        console.log("status: " + status);
        return tagsResponse;
      }
      const tagsData = await tagsResponse.json();
      console.log("data" + JSON.stringify(tagsData));
      return redirect(`/rescue/applicants/${formData.get("applicantId")}`);

    default:
      console.log("note submitted without a valid id");
  }
};

export const loader = async ({ params }) => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/applicants/${params.applicantId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();

    if (data === "No User found" || data === "Unauthorized") {
      return redirect("/rescue/applicants");
    } else {
      return data;
    }
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
