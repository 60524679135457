import { useState } from 'react';
import { useLoaderData, redirect } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row
} from "reactstrap";
import EventTable from "../../../components/dashboard/EventTable";
import EventForm from "../../../components/dashboard/EventForm";
import { eventDefault } from "../../../utils/initialState";

const RescueDashboardEventsPage = () => {
  const [modal, setModal] = useState(false);
  const data = useLoaderData();
  const defaultPageSize = 10;

  console.log(data);

  const toggle = () => {
    setModal(prevState => !prevState);
  }

  return (
    <div>
      <Row>
        <Button color="fetched-solid" onClick={toggle}>+ Add New Event</Button>
      </Row>
      <EventTable events={data.events} title="Events" pageSize={defaultPageSize}/>
      <Modal isOpen={modal} toggle={toggle} className="dashboardFormModal modal-dialog-centered">
        <ModalHeader toggle={toggle}>Add New Event</ModalHeader>
        <ModalBody>
          <EventForm method="POST" event={eventDefault} rescueAddress={data.rescueMeta} handleToggle={setModal}/>
        </ModalBody>

      </Modal>
    </div>
  );
};

export default RescueDashboardEventsPage;

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/events`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};


