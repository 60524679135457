import { useLoaderData, redirect} from 'react-router-dom';
import DashboardStatsPanel from "../../../components/dashboard/StatsPanel";
import AnimalTable from "../../../components/dashboard/AnimalTable";
import ApplicationTable from "../../../components/dashboard/ApplicationTable";
import QuestionTable from '../../../components/dashboard/QuestionTable';
import EventTable from '../../../components/dashboard/EventTable';
import { dog, application, comment, event} from "../../../assets/svg/svgs";

const RescueDashboardPage = () => {

    const data = useLoaderData();
    const {id, username, email, rescue} = data;
    console.log(rescue);
    const defaultPageSize = 5;

    const statCards = [
        {
          body: true,      
          title: "Total Animals",



          
          icon: dog,
          stat: rescue.animals.length,
          footer: true,
          actionButton: false,
          footerMsg: "View animals",
          route:"/rescue/animals"
        },
        {
          body: true,
          title: "Total Applications",
          icon: application,
          stat: rescue.applications.length,
          footer: true,
          actionButton: false,
          footerMsg: "View applications",
          route: "/rescue/applications"
        },
        {
          body: true,
          title: "Total Upcoming Events",
          icon: event,
          stat: rescue.events.length,
          footer: true,
          actionButton: false,
          footerMsg: "View events",
          route: "/rescue/events"
        },
        {
            body: true,      
            title: "Unanswered Questions",
            icon: comment,
            stat: rescue.animalQuestions.length,
            footer: true,
            actionButton: false,
            footerMsg: "View Questions",
            route:"/rescue/questions"
          },
    ]

  return (
    <div>
        <DashboardStatsPanel statCards={statCards}/>
        <h1>Dashboard</h1>
        <AnimalTable animals={rescue.animals.slice(0, 5)} title="Recent Animals" pageSize={defaultPageSize} />
        <ApplicationTable applications={rescue.applications.slice(0,5)} title="Recent Applications" pageSize={defaultPageSize}/>
        <QuestionTable questions={rescue.animalQuestions.slice(0,5)} title="Recent Unanswered Questions" pageSize={defaultPageSize}/>
        <EventTable events={rescue.events.slice(0,5)} title="Upcoming Events" pageSize={defaultPageSize}/>
    </div>
  );
};

export default RescueDashboardPage;

export const loader = async() => {
  let status = "";
  try {
    const response = await fetch(`${process.env.REACT_APP_API_ROOT}/rescues/rescueDashboard`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type':'application/json',
        }
      });
      status = response.status;
      const data = await response.json();
      return data;  

  } catch (error) {
      console.log(`Message : ${error.message}, Status : ${status}`);
      return redirect("/");
  }
  
 
}
