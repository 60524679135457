import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import DashboardTable from "../table/DashboardTable";
import { task } from "../../assets/svg/svgs";

const CustomTable = ({ customs, title, pageSize }) => {
  const processor = (customs) => {
    const customsState = customs.map((custom, key) => {
      return {
        id: `custom_${custom.id}`,
        custom_id: custom.id,
        question: custom.question,
        active: custom.isEnabled ? "Yes" : "No",
        actions: (
          <div className="actions-right">
            <Link to={`/rescue/customs/${custom.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Custom Question"
              >
                {task}
              </button>
            </Link>
          </div>
        ),
      };
    });
    return customsState;
  };

  const [dataState, setDataState] = useState(processor(customs));

  const flexGrow = ["max-80", "grow-6", "", "grow-0-auto"];

  useEffect(() => {
    setDataState(processor(customs));
  }, [customs]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <h5>{title}</h5>
          <Card className="dashboardTable">
            <CardBody>
              <DashboardTable
                data={dataState}
                columns={[
                  {
                    Header: "ID",
                    accessor: "custom_id",
                  },
                  {
                    Header: "Question",
                    accessor: "question",
                  },
                  {
                    Header: "Active",
                    accessor: "active",
                  },
                  {
                    Header: (
                      <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                        {task}
                      </button>
                    ),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                className="-striped -highlight primary-pagination"
                flexGrow={flexGrow}
                pageRowCount={pageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CustomTable;
