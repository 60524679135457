import classes from "./Footer.module.css";
import ContactBox from "../components/ContactBox";
import { logoNav } from "../assets/svg/svgs";
import Twitter from "../assets/images/twitter.png";
import Facebook from "../assets/images/facebook.png";
import Instagram from "../assets/images/instagram.png";
import Email from "../assets/images/email.png";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();


  return (
    <footer className={classes.mainFooter}>

        <div className={classes.contactBoxWrapper}>
          <ContactBox />
        </div>
      <div className={classes.footerLogo}>
        {logoNav}
        <span>© {year} fetched, All rights reserved.</span>
      </div>
      <div className={classes.socialLinks}>
        <img src={Email} />
        <img src={Facebook} />
        <img src={Instagram} />
        <img src={Twitter} />
      </div>
    </footer>
  );
};

export default Footer;
