import { useLoaderData, redirect } from "react-router-dom";
import classes from "./MainPage.module.css";
import NoteTimeLine from "../../../components/dashboard/NoteTimeLine";
import AnimalProfile from "../../../components/dashboard/AnimalProfile";
import ApplicationTable from "../../../components/dashboard/ApplicationTable";
import QuestionTable from "../../../components/dashboard/QuestionTable";
import DashboardStatsPanel from "../../../components/dashboard/StatsPanel";
import {
  application as applicationSvg,
  heart,
  comment,
} from "../../../assets/svg/svgs";

const RescueDashboardAnimalPage = () => {

  // animal, breeds
  const data = useLoaderData();
  console.log(data);
  let dogBreedOptions = [
    { value: "", label: "Select Dog Breed", isDisabled: true },
  ];
  let catBreedOptions = [
    { value: "", label: "Select Cat Breed", isDisabled: true },
  ];

  data.breeds.forEach((breed) => {
    if (breed.species === "DOG")
      dogBreedOptions.push({ value: breed.id, label: breed.name });
    else if (breed.species === "CAT")
      catBreedOptions.push({ value: breed.id, label: breed.name });
  });

  const statCards = [
    {
      body: true,
      title: "Applications",
      icon: applicationSvg,
      stat: data.animal.applications.length,
      footer: false,
      actionButton: false,
      footerMsg: "",
      route: "",
    },
    {
      body: true,
      title: "Likes",
      icon: heart,
      stat: data.animal.likes.length,
      footer: false,
      actionButton: false,
      footerMsg: "",
      route: "",
    },
    {
      body: true,
      title: "Questions",
      icon: comment,
      stat: data.animal.questions.length,
      footer: false,
      actionButton: false,
      footerMsg: "",
      route: "",
    },
  ];

  return (
    <div className={classes.container}>
      <div className="col-sm-12 col-md-8">
        <DashboardStatsPanel statCards={statCards} />
        <AnimalProfile
          animal={data.animal}
          dogBreeds={dogBreedOptions}
          catBreeds={catBreedOptions}
        />
        <ApplicationTable
          applications={data.animal.applications}
          title="Applications"
          pageSize={10}
        />
        <QuestionTable
          questions={data.animal.questions}
          title="Questions"
          pageSize={10}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <NoteTimeLine notes={data.animal.notes} title="" />
      </div>
    </div>
  );
};

export default RescueDashboardAnimalPage;

export const multiActions = async ({ request, params }) => {
  const formData = await request.formData();
  const formId = formData.get("form-id");
  let url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/animals`;
  let status = "";

  switch (formId) {
    case "note":
      const note = { note: formData.get("note") };
      url = `${url}/${params.animalId}/note`;
      const noteResponse = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(note),
      });
      status = noteResponse.status;
      if (!noteResponse.ok) {
        console.log("status: " + status);
        return noteResponse;
      }
      const noteData = await noteResponse.json();
      console.log("data" + noteData);
      return noteData;

    case "animalForm":
      const animal = Object.fromEntries(formData);
      url = `${url}/${params.animalId}`;
      if (!animal.secondaryBreedId) {
        animal.secondaryBreedId = "";
      }
      const animalResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(animal),
      });
      status = animalResponse.status;
      if (!animalResponse.ok) {
        console.log("status: " + status);
        return animalResponse;
      }
      const animaldata = await animalResponse.json();
      return animaldata;

    case "adoptionStatus":
    case "display":
    case "archive":
    case "delete":

      const data = Object.fromEntries(formData);
      console.log(JSON.stringify(data));

      url = `${url}/${formData.get("animalId")}/${formId}`;
      console.log(url)

      const dataResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      status = dataResponse.status;
      if (!dataResponse.ok) {
        console.log("status: " + status);
        return dataResponse;
      }
      const statusData = await dataResponse.json();

      if (formId === "archive" && formData.get("isArchived") === "false") {
        return redirect(`/rescue/animals/${statusData.id}`);
      }
      return statusData;

    case "addPhoto":
      const photoObj = Object.fromEntries(formData);
      console.log(JSON.stringify(photoObj.file))
      console.log(photoObj)
      url = `${process.env.REACT_APP_API_ROOT}/files/rescue/animals/${params.animalId}/photos`;
      const photoResponse = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      status = photoResponse.status;

      if (!photoResponse.ok) {
        console.log("status: " + status);
        return photoResponse;
      }
      const photoData = await photoResponse.json();
      return photoData;

    case "updatePhoto":
      // put route
      url = `${process.env.REACT_APP_API_ROOT}/files/rescue/animals/${
        params.animalId
      }/photos/${formData.get("photo_id")}`;
      const updatePhotoResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        body: formData,
      });
      status = updatePhotoResponse.status;
      if (!updatePhotoResponse.ok) {
        console.log("status: " + status);
        return updatePhotoResponse;
      }
      const updatePhotoData = await updatePhotoResponse.json();
      return updatePhotoData;

    case "deletePhoto":
      const photoToBeDeleted = { image_url: formData.get("image_url") };
      // post route to delete photo
      url = `${process.env.REACT_APP_API_ROOT}/files/rescue/animals/${
        params.animalId
      }/photos/${formData.get("photo_id")}`;
      const deletePhotoResponse = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(photoToBeDeleted),
      });
      status = deletePhotoResponse.status;
      if (!deletePhotoResponse.ok) {
        console.log("status: " + status);
        return deletePhotoResponse;
      }
      const deletePhotoData = await deletePhotoResponse.json();
      console.log("data" + deletePhotoData);
      return deletePhotoData;

    case "addDocument":
      const obj = Object.fromEntries(formData);
      console.log(obj.file.type)
      console.log(obj)
      url = `${process.env.REACT_APP_API_ROOT}/files/rescue/animals/${params.animalId}/documents`;
      const documentResponse = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      status = documentResponse.status;

      if (!documentResponse.ok) {
        console.log("status: " + status);
        return documentResponse;
      }
      const documentData = await documentResponse.json();
      return documentData;

    case "updateDocument":
       // put route
       url = `${process.env.REACT_APP_API_ROOT}/files/rescue/animals/${
        params.animalId
      }/documents/${formData.get("documentId")}`;
      const updateDocumentResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        body: formData,
      });
      status = updateDocumentResponse.status;
      if (!updateDocumentResponse.ok) {
        console.log("status: " + status);
        return updateDocumentResponse;
      }
      const updateDocumentData = await updateDocumentResponse.json();
      return updateDocumentData;

    case "deleteDocument":
      url = `${process.env.REACT_APP_API_ROOT}/files/rescue/animals/${
        params.animalId
      }/documents/${formData.get("documentId")}`;
      const deleteDocumentResponse = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      })
      status = deleteDocumentResponse.status;
      if (!deleteDocumentResponse.ok) {
        console.log("status: " + status);
        return deleteDocumentResponse;
      }
      const deleteDocumentData = await deleteDocumentResponse.json();
      return deleteDocumentData;

    default:
      console.log("form submitted without a valid form id");
  }
};

export const loader = async ({ params }) => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/animals/${params.animalId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    if (data.message && data.message === "Unauthorized") {
      return redirect("/rescue/animals");
    } else {
      return data;
    }
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
