import { useState } from "react";
import { useLoaderData, redirect, Link } from "react-router-dom";
import DashboardStatsPanel from "../../../components/dashboard/StatsPanel";
import { dog, heart, medal } from "../../../assets/svg/svgs";
import AnimalTable from "../../../components/dashboard/AnimalTable";
import { Button, Row, Col } from "reactstrap";

const RescueDashboardAnimalsPage = () => {
  const data = useLoaderData();
  // total animals, most liked animal, seniors, Hot shot
  const stats = getStats(data.animals);
  const [animals, setAnimals] = useState(data.animals);
  const defaultPageSize = 10;
  console.log(data);

  const handlingClick = (option) => {
    if (option === "ALL") setAnimals(data.animals);
    else {
      const filteredAnimals = data.animals.filter((animal) => {
        return animal.animalMeta.age === option;
      });
      setAnimals(filteredAnimals);
    }
  };

  const statCards = [
    {
      body: true,
      title: "Total Animals",
      icon: dog,
      stat: data.animals.length,
      footer: true,
      actionButton: true,
      footerMsg: "View animals",
      onClick: () => handlingClick("ALL"),
    },
    {
      body: true,
      title: "Most Popular",
      icon: heart,
      stat: stats.mostLiked.count,
      footer: true,
      actionButton: false,
      footerMsg: `View ${stats.mostLiked.name}`,
      route: `/rescue/animals/${stats.mostLiked.id}`,
    },
    {
      body: true,
      title: "Seniors",
      icon: dog,
      stat: stats.seniors,
      footer: true,
      actionButton: true,
      footerMsg: "View Seniors",
      onClick: () => handlingClick("SENIOR"),
    },
    {
      body: true,
      title: "Hot Shot",
      icon: medal,
      stat: stats.hotShot.count,
      footer: true,
      actionButton: false,
      footerMsg: `View ${stats.hotShot.name}`,
      route: `/rescue/animals/${stats.hotShot.id}`,
    },
  ];

  return (
    <div>
      <DashboardStatsPanel statCards={statCards} />
      <Row>
        <Col md={{size: 3, offfset: 9}} >
          <Link to="/rescue/animals/new">
            <Button color="fetched-solid">+ Add New Animal</Button>
          </Link>
        </Col>
      </Row>
      <AnimalTable
        animals={animals}
        title="Animals"
        pageSize={defaultPageSize}
      />
    </div>
  );
};

export default RescueDashboardAnimalsPage;

export const loader = async () => {
  let status = "";

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/animals`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};

const getStats = (animals) => {
  //most liked animal(like), seniors, Hot shot (application)
  const stats = {
    mostLiked: {
      id: null,
      name: null,
      count: 0,
    },
    seniors: 0,
    hotShot: {
      id: null,
      name: null,
      count: 0,
    },
  };
  let likes = 0;
  let applications = 0;

  animals.forEach((animal) => {
    if (animal.applications.length >= applications) {
      applications = animal.applications.length;
      stats.hotShot = {
        id: animal.id,
        name: animal.name,
        count: animal.applications.length,
      };
    }
    if (animal.likes.length >= likes) {
      likes = animal.likes.length;
      stats.mostLiked = {
        id: animal.id,
        name: animal.name,
        count: animal.likes.length,
      };
    }
    if (animal.animalMeta.age === "SENIOR") {
      stats.seniors++;
    }
  });
  return stats;
};
