import { Outlet,
  useRouteLoaderData,
  matchPath,
  useLocation, } from "react-router-dom";
import HeroBg from "../components/HeroBg";
import classes from "./Root.module.css";
import NoiseBG from "../assets/images/NoiseTexture.png";
import PageNavigation from "../components/navigation/Page";

const AuthRootLayout = () => {


  const { pathname } = useLocation();
  const isAuth = matchPath("/auth/*", pathname);
  const user = useRouteLoaderData(!isAuth ? "pageRoot" : "authRoot");
  console.log(user);

  return (
    <>
      <div
        className={classes.noiseOverlay}
        style={{ backgroundImage: `url(${NoiseBG})` }}
      />
      <HeroBg />
      <PageNavigation user={user} auth={isAuth}/>
      <main className={classes.main}>
        <Outlet/>
      </main>
    </>
  );
};

export default AuthRootLayout;
