import { useState, useEffect } from "react";
import {
  useLoaderData,
  redirect,
  Link,
  useOutletContext,
} from "react-router-dom";
import Footer from "../components/Footer";
import classes from "./Animals.module.css";
import DogHero from "../assets/images/dog_hero.png";
import CatHero from "../assets/images/kitty_hero.png";
import LogoPlacement from "../assets/images/logo_placement_bw.png";

const AnimalsPage = () => {
  const [user] = useOutletContext();
  const data = useLoaderData();
  const [activeImg, setActiveImg] = useState("");

  // const stateData = {
  //   rescueId: data.rescue.rescueMeta.rescueId,
  //   animalId: data.id
  // }

  useEffect(() => {
    if (data != null && data.photos.length > 0) {
      setActiveImg(data.photos[0].img_url);
    }
  }, []);

  if (data == null) {
    return <h1>Animal does not exist.</h1>;
  }
  console.log(data);
  console.log(user);
  const imgBase =
    data.animalMeta.species === "DOG"
      ? DogHero
      : data.animalMeta.species === "CAT"
      ? CatHero
      : "";

  const breed = `${data.animalMeta.primaryBreed.name}${
    data.animalMeta.secondaryBreed &&
    data.animalMeta.secondaryBreed.name != data.animalMeta.primaryBreed.name
      ? " & " + data.animalMeta.secondaryBreed.name
      : ""
  } ${data.animalMeta.isMixed ? " Mix" : ""}`;

  const city = `${data.rescue.rescueMeta.city.toLowerCase()}`;
  const state = `${data.rescue.rescueMeta.state}`;
  const goodWith = `${data.animalMeta.isGoodWithKids ? "Kids, " : ""}${
    data.animalMeta.isGoodWithDogs ? "Dogs, " : ""
  } ${data.animalMeta.isGoodWithCats ? "Cats" : ""}`.replace(/,\s*$/, "");

  return (
    <>
      <div className={classes.container}>
        <span>search / {data.name}</span>
        <div className={classes.topContainer}>
          <div className={classes.imagesContainer}>
            {data.photos.length == 0 && (
              <div className={classes.tempImgWrapper}>
                <img src={imgBase} />
                <a
                  href="https://www.flaticon.com/free-icons/hero"
                  title="hero icons"
                >
                  Hero icons created by Freepik - Flaticon
                </a>
              </div>
            )}
            {data.photos.length > 0 && (
              <div className={classes.activeImgWrapper}>
                <img
                  src={`${process.env.REACT_APP_API_BASE_ROOT}/${activeImg}`}
                />
              </div>
            )}
            <ul className={classes.imageThumbs}>
              {data.photos.map((img, idx) => (
                <li
                  className={img.img_url === activeImg ? classes.active : null}
                  key={`img_${idx}`}
                  onClick={() => setActiveImg(img.img_url)}
                >
                  <img
                    src={`${process.env.REACT_APP_API_BASE_ROOT}/${img.img_url}`}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.dataContainer}>
            <h4>{data.adoptionStatus.replaceAll("_", " ")}</h4>
            <h1>{data.name}</h1>
            <h3>
              <span>{breed}</span> <span>&#9679;</span> {city}, {state}
            </h3>
            <div className={classes.dataWrapper}>
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>Age</div>
                <div className={classes.dataInfo}>
                  {data.animalMeta.age.replaceAll("_", " ").toLowerCase()}
                </div>
              </div>
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>Size</div>
                <div className={classes.dataInfo}>
                  {data.animalMeta.size.replaceAll("_", " ").toLowerCase()}
                </div>
              </div>
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>Gender</div>
                <div className={classes.dataInfo}>
                  {data.animalMeta.gender.replaceAll("_", " ").toLowerCase()}
                </div>
              </div>
              {data.animalMeta.color ? (
                <div className={classes.dataRow}>
                  <div className={classes.dataLabel}>Color</div>
                  <div className={classes.dataInfo}>
                    {data.animalMeta.color.replaceAll("_", " ").toLowerCase()}
                  </div>
                </div>
              ) : null}
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>Coat Length</div>
                <div className={classes.dataInfo}>
                  {data.animalMeta.coatLength
                    .replaceAll("_", " ")
                    .toLowerCase()}
                </div>
              </div>
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>Weight</div>
                <div className={classes.dataInfo}>
                  {data.animalMeta.weight.replaceAll("_", " ").toLowerCase()}
                </div>
              </div>
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>Good With</div>
                <div className={classes.dataInfo}>
                  {goodWith == ""
                    ? goodWith
                    : "Not getting along with kids, dogs nor cats"}
                </div>
              </div>
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>House-trained</div>
                <div className={classes.dataInfo}>
                  {data.animalMeta.isHouseTrained ? "Yes" : "No"}
                </div>
              </div>
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>Health</div>
                <div className={classes.dataInfo}>{data.animalMeta.health}</div>
              </div>
              <div className={classes.dataRow}>
                <div className={classes.dataLabel}>Temperament</div>
                <div className={classes.dataInfo}>
                  {data.animalMeta.temperament}
                </div>
              </div>
            </div>
            {user &&
              user.role == "USER" &&
              (data.adoptionStatus == "LOOKING_FOR_A_HOME" ||
                data.adoptionStatus == "ADOPTION_PENDING") && (
                <Link
                  to={`/page/application/${data.rescue.rescueMeta.rescueId}/${data.id}`}
                  className={classes.button}
                >
                  Adopt Me Today
                </Link>
              )}
            {user && user.role != "USER" && (
              <span>Adoption is not available for Rescue account</span>
            )}
            {!user && data.adoptionStatus == "LOOKING_FOR_A_HOME" && (
              <Link to="/auth/login" className={classes.button}>
                Sign in to adopt me today
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className={classes.fullPageWrapperGrey}>
        <div className={classes.container}>
          <div className={classes.centerContainer}>
            <div className={classes.descriptionContainer}>
              <h3>My Story</h3>
              <p>{data.animalMeta.description}</p>
              <h3>Adoption Process</h3>
              <ol>
                <li>Submit Application</li>
                {data.rescue.rescueMeta.interviewReq ? (
                  <li>Adopter Interview</li>
                ) : null}
                {data.rescue.rescueMeta.homeCheckReq ? (
                  <li>Home Check</li>
                ) : null}
                <li>Meet up</li>
                <li>Approval & Adoption Fee (if applicable)</li>
                <li>Bring your new pet home</li>
              </ol>
            </div>
            <div className={classes.rescueDataContainer}>
              <h5>I'm cared for by</h5>
              <Link to={`/page/rescues/${data.rescue.rescueMeta.rescueId}`}>
                {data.rescue.rescueLogo && (
                  <img
                    src={`${process.env.REACT_APP_API_BASE_ROOT}${data.rescue.rescueLogo.img_url}`}
                  />
                )}
                {!data.rescue.rescueLogo && <img src={LogoPlacement} />}
              </Link>
              <h6>
                <Link to={`/page/rescues/${data.rescue.rescueMeta.rescueId}`}>
                  {data.rescue.legalRescueName}
                </Link>
              </h6>
              <div className={classes.space10} />
              <span>{data.rescue.rescueMeta.streetAddress1}</span>
              <span>
                {data.rescue.rescueMeta.city}, {data.rescue.rescueMeta.state}{" "}
                {data.rescue.rescueMeta.zipCode}
              </span>
              <div className={classes.space10} />
              <span>{data.rescue.rescueMeta.phone}</span>
              <span>{data.rescue.user.email}</span>

              <h6>Adoption Fee</h6>
              <span>{`$${data.animalMeta.adoptionFee}`}</span>
              <h6>Home Check</h6>
              <span>
                {data.rescue.rescueMeta.homeCheckReq
                  ? "Required"
                  : "Not Required"}
              </span>
              <h6>Min. Age Requirement</h6>
              <span>{`${data.rescue.rescueMeta.adoptionAgeReq} years old`}</span>
              <h6>Interview</h6>
              <span>
                {data.rescue.rescueMeta.interviewReq
                  ? "Required"
                  : "Not Required"}
              </span>
            </div>
          </div>
          <div className={classes.bottomContainer}>
            <div>
              <h3>Questions & Answers</h3>
              {user != null && user.role == "USER" && (
                <Link to="#" className={classes.button}>
                  Ask a question
                </Link>
              )}
              {user != null && user.role != "USER" && (
                <span>
                  "Ask a question" is not available for Rescue account
                </span>
              )}
              {user == null && (
                <Link to="/auth/login" className={classes.button}>
                  Sign in to ask a question
                </Link>
              )}
            </div>

            {data.questions.length == 0 &&
              `Be the first one to ask question about ${data.name}.`}
            {data.questions.length > 0 &&
              data.questions.map((item, idx) => (
                <div
                  key={`question_${idx}`}
                  className={classes.questionWrapper}
                >
                  <h5>{item.question}</h5>
                  <span>{item.answer}</span>
                </div>
              ))}
          </div>
          <div className={classes.space100} />
        </div>
      </div>
      {/* <div className={classes.container}>
        <Footer />
      </div> */}
    </>
  );
};

export default AnimalsPage;


export const loader = async ({ params }) => {
  let status = "";
  const url = `${process.env.REACT_APP_API_ROOT}/animals/${params.animalId}`;
  console.log("animal page - before fetching endpoint");

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    status = response.status;
    console.log("animal page - Status: " + status);

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
