import {
  Form,
  Link,
  useActionData,
  useNavigation,
} from "react-router-dom";

const UserSignupForm = () => {

    // const data = useActionData();
    // const navigation = useNavigation();
    // const isSubmitting = navigation.state === 'submitting'; 


    // username, password, email, ein, contactName, phone 
  return (
    <Form method="post">
      <div>
        <label>Username</label>
        <div>
          <input type="text" id="username" name="username" required/>
        </div>
      </div>
      <div>
        <label>Email Address</label>
        <div>
          <input type="email" id="email" name="email" required/>
        </div>
      </div>
      <div>
        <label>Password</label>
        <div>
          <input type="password" id="password" name="password" required/>
        </div>
      </div>
      <button>Sign up</button>
      {/* <button disabled={isSubmitting}>
        {isSubmitting ? "Authenticating..." : "Sign in"}
        </button> */}
        Already has an account? <Link to="/auth/login">Login</Link>
    </Form>
  );
};

export default UserSignupForm;