/*
  auth component for main, page and auth page navigation
*/

import { Link, NavLink, Form } from "react-router-dom";
import classes from "./Main.module.css";

const NavigationAuth = ({ user, auth }) => {

  const getRoute = (role) => {
    switch (role) {
      case "ADMIN":
        return "/admin";
      case "RESCUE":
        return "/rescue";
      case "USER":
        return "/user";
      default:
        return "/auth/login";
    }
  };

  return (
    <div className={classes["header-auth-container"]}>
      {!user && (
        <ul>
          <li>
            <NavLink
              to="/auth/login"
              className={({ isActive }) =>
                isActive ? classes.active : auth === "home" ? classes.homeAuth : classes.pageAuth
              }
            >
              Login
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/auth/signup"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              <button>Sign up</button>
            </NavLink>
          </li>
        </ul>
      )}
      {user && (
        <div >
          <Link to={getRoute(user.role)} className={auth === "home" ? classes.homeAuth : classes.pageAuth}>{user.username}</Link>
        </div>
      )}
      {user && (
        <ul>
          <li>
            <Form method="post" action="/auth/logout">
              <button>Logout</button>
            </Form>
          </li>
        </ul>
      )}
    </div>
  );
};

export default NavigationAuth;
