import {decode, redirect} from 'string-encode-decode';


export const getSession = () => {
    const session = localStorage.getItem('session')

    if(!session) return null;

    return session;
}

export const getAuthToken = () => {
    const token = localStorage.getItem('token');
    
    if(!token) {
        return null;
    }

    return token;
}

export const tokenLoader = () => {
    const token = getAuthToken();
    if(!token) return null;

    const decodeToken = JSON.parse(decode(token));
    console.log(decodeToken);
    return (decodeToken);
}

export const checkAuthLoader = () => {
    const token = getAuthToken();
    if(!token) {
        return redirect('/login');
    }
    return null;
}

export const checkRoleLoader = () => {
    const token = getAuthToken();
    if(!token) return null;

    const decodeToken = JSON.parse(decode(token));
    console.log(decodeToken);
    switch (decodeToken.role) {
        case "ADMIN" :
            return "ADMIN";
        case "RESCUE" :
            return "RESCUE";
        case "USER" :
            return "USER";
        default:
            return null;
    }
}

export const checkUserLoader = () => {
    const token = getAuthToken();
    if(!token) return null;

    const decodeToken = JSON.parse(decode(token));
    const user = 
        {
            id: decodeToken.id,
            role: decodeToken.role,
            username: decodeToken.username
        }
    return (user);

    // switch (decodeToken.role) {
    //     case "ADMIN" :
    //         return "ADMIN";
    //     case "RESCUE" :
    //         return "RESCUE";
    //     case "USER" :
    //         return "USER";
    //     default:
    //         return null;
    // }
}