import { redirect, useLoaderData } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import CustomForm from "../../../components/dashboard/CustomForm";
import classes from "./EditPage.module.css";

const RescueDashboardCustomPage = () => {
  const data = useLoaderData();
  console.log(data);
  return (
    <div className="content">
      <div className={classes.badge}>
        {data.isEnabled ? "Active" : "Inactive"}
      </div>
      <h3 className={classes.question}>{data.question}</h3>
      <br />
      <br />
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle>Edit current question</CardTitle>
            </CardHeader>
            <CardBody>
              <CustomForm method="PUT" customQuestion={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RescueDashboardCustomPage;

export const loader = async ({ params }) => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/customQuestions/${params.customId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
