/* States */
export const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
export const stateOptions = [
  { value: "", label: "Select State", isDisabled: true },
].concat(states.map((state) => ({ value: state, label: state })));


/* Applicant Tags */
export const applicantTag = [
  "NEW_APPLICANT",
  "REPEAT_ADOPTER",
  "REPEAT_APPLICANT",
  "FLAGGED_USER",
];
export const applicantTagOptions = [
    { value: "", label: "Select Tag", isDisabled: true },
].concat(applicantTag.map((tag) => ({ value: tag, label: tag.replaceAll("_", " ") })));


/* Applicantion Status */
export const applicationStatus = [
  "APPLICATION_SUBMITTED",
  "APPLICATION_IN_REVIEW",
  "APPLICATION_APPROVED",
  "PET_FOUND_ANOTHER_HOME",
];
export const applicationStatusOptions = [
    { value: "", label: "Select Application Status", isDisabled: true },
].concat(applicationStatus.map((status) => ({ value: status, label: status.replaceAll("_", " ") })));


/* Field Types */
export const fieldType = ["TEXT", "SELECT"];
export const fieldTypeOptions = [
    { value: "", label: "Select Field Type", isDisabled: true },
].concat(fieldType.map((type) => ({ value: type, label: type.replaceAll("_", " ") })));


/* Social Platform */
export const SocialPlatform = ["FACEBOOK", "TWITTER", "INSTAGRAM", "TIKTOK"];
export const socialPlatformOptions = [
    { value: "", label: "Select Social Platform", isDisabled: true },
].concat(SocialPlatform.map((social) => ({ value: social, label: social.replaceAll("_", " ") })));


/* Sizes */
export const size = ["TOY", "SMALL", "MEDIUM", "LARGE", "GIANT"];
export const sizeOptions = [
    { value: "", label: "Select Animal Size", isDisabled: true },
].concat(size.map((item) => ({ value: item, label: item.replaceAll("_", " ") })));


/* Length */
export const length = ["SHORT", "MEDIUM", "LONG"];
export const lengthOptions = [
    { value: "", label: "Select Coat Length", isDisabled: true },
].concat(length.map((item) => ({ value: item, label: item.replaceAll("_", " ") })));


/* Event Time */
export const eventTime = [
  "AM_12_00",
  "AM_12_30",
  "AM_01_00",
  "AM_01_30",
  "AM_02_00",
  "AM_02_30",
  "AM_03_00",
  "AM_03_30",
  "AM_04_00",
  "AM_04_30",
  "AM_05_00",
  "AM_05_30",
  "AM_06_00",
  "AM_06_30",
  "AM_07_00",
  "AM_07_30",
  "AM_08_00",
  "AM_08_30",
  "AM_09_00",
  "AM_09_30",
  "AM_10_00",
  "AM_10_30",
  "AM_11_00",
  "AM_11_30",
  "PM_12_00",
  "PM_12_30",
  "PM_01_00",
  "PM_01_30",
  "PM_02_00",
  "PM_02_30",
  "PM_03_00",
  "PM_03_30",
  "PM_04_00",
  "PM_04_30",
  "PM_05_00",
  "PM_05_30",
  "PM_06_00",
  "PM_06_30",
  "PM_07_00",
  "PM_07_30",
  "PM_08_00",
  "PM_08_30",
  "PM_09_00",
  "PM_09_30",
  "PM_10_00",
  "PM_10_30",
  "PM_11_00",
  "PM_11_30",
];
export const eventTimeOptions = [
    { value: "", label: "Select Coat Length", isDisabled: true },
].concat(eventTime.map((time) => ({ value: time, label: time.replace("_", " ").replace("_", ":")})));


/* Weight */
export const weight = [
  "UNDER_15_LBS",
  "BETWEEN_15_AND_30_LBS",
  "BETWEEN_30_AND_60_LBS",
  "BETWEEN_60_AND_90_LBS",
  "ABOVE_90_LBS",
];
export const weightOptions = [
    { value: "", label: "Select Animal Weight", isDisabled: true },
].concat(weight.map((item) => ({ value: item, label: item.replaceAll("_", " ") })));


/* Age */
export const age = ["YOUNG", "YOUNG_ADULT", "ADULT", "SENIOR"];
export const ageOptions = [
    { value: "", label: "Select Animal Age", isDisabled: true },
].concat(age.map((item) => ({ value: item, label: item.replaceAll("_", " ") })));


/* Species */
export const species = ["DOG", "CAT"];
export const speciesOptions = [
    { value: "", label: "Select Species", isDisabled: true },
].concat(species.map((item) => ({ value: item, label: item.replaceAll("_", " ") })));


/* Gender */
export const gender = ["MALE", "FEMALE"];
export const genderOptions = [
    { value: "", label: "Select Gender", isDisabled: true },
].concat(gender.map((item) => ({ value: item, label: item.replaceAll("_", " ") })));


/* Adoption Status */
export const adoptionStatus = [
  "LOOKING_FOR_A_HOME",
  "ADOPTION_PENDING",
  "ADOPTED"
];
export const adoptionStatusOptions = [
    { value: "", label: "Select Adoption Status", isDisabled: true },
].concat(adoptionStatus.map((status) => ({ value: status, label: status.replace("_", " ") })));


/* User Role */
export const role = ["USER", "RESCUE", "ADMIN"];
export const userRoleOptions = [
    { value: "", label: "Select User Role", isDisabled: true },
].concat(role.map((userRole) => ({ value: userRole, label: userRole.replace("_", " ") })));


/* universal TRUE FALSE options */
export const booleanOptions = [
    { value: "", label: "Select", isDisabled: true}, {value: true, label: "Yes"}, {value: false, label: "No"}
]