/*
  navigation for rescue dashboard
*/


import { NavLink, Link, Form } from "react-router-dom";
import classes from "./Dashboard.module.css";
import logo from "../../assets/images/logo.png";
import {
  dog,
  house,
  application,
  event,
  profile,
  user,
  comment,
  task,
  close,
  archive,
} from "../../assets/svg/svgs";

const RescueNavigation = ({ name, menuToggle, onMenuClick }) => {
  const handleMenu = () => {
    onMenuClick(false);
  };

  return (
    <div
      className={
        menuToggle
          ? classes["dashboard-nav-container-active"]
          : classes["dashboard-nav-container"]
      }
    >
      <div className={classes["dashboard-menu-button-wrapper"]}>
        <button
          onClick={handleMenu}
          className={classes["dashboard-menu-button"]}
        >
          {close}
        </button>
      </div>

      <div className={classes["dashboard-nav"]}>
        <div className={classes["dashboard-logo-wrapper"]}>
          <Link to="/">
            <img src={logo} />
          </Link>
        </div>
        <ul>
          <li>
            <NavLink
              to="/rescue"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              end
            >
              {house}
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rescue/profile"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {profile}
              Rescue Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rescue/animals"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {dog}
              Animals
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rescue/applications"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {application}
              Applications
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rescue/events"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {event}
              Events
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rescue/applicants"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {user}
              Applicants
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rescue/customs"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {task}
              Custom Questions
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rescue/questions"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {comment}Q & A / Comments
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/rescue/archivedAnimals"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {archive}Archived Animals
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={classes["dashboard-avatar-container"]}>
        <div className={classes["dashboard-avatar"]}>
          {name.charAt(0).toUpperCase()}
        </div>
        <div className={classes["dashboard-avatar-text-wrapper"]}>
          <h3>{name}</h3>
          <Form method="post" action="/rescue/logout">
            <button>Logout</button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default RescueNavigation;
