import classes from './HeroBg.module.css';
import HeroDog from '../assets/images/heroDog.png'
import { fe_spotlightBg, fe_heroPaw, fe_bowl, fe_ball, fe_woofHello, fe_bone } from '../assets/svg/svgs';
const HeroBg = () => {
    return (
            <div className={classes.heroBg}>
                <div className={classes.heroSpotlight}>
                    {fe_spotlightBg}
                </div>
                <div className={classes.heroPaw}>
                    <div className={classes.bowl}>{fe_bowl}</div>
                    <div className={classes.ball}>{fe_ball}</div>
                    <div className={classes.woofHello}>{fe_woofHello}</div>
                    <div className={classes.bone}>{fe_bone}</div>
                    <img src={HeroDog} alt="Dog for adoption, brown dog, adult dog"/>
                    {fe_heroPaw}
                </div>
            </div>

    )
}

export default HeroBg;
 