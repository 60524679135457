import classes from "./Tag.module.css";

const Tag = ({ title, handleClick, type, size }) => {
  const color = {
    "New Applicant": "#6bd098",
    "Repeat Adopter": "#BB6BD9",
    "Repeat Applicant": "#51bcda",
    "Flagged": "#ef8157",
   
  };

  const handlingButtonClick = (e) => {
    e.preventDefault();
    handleClick(type);
  };

  const styles = {
    backgroundColor: color[title],
  }

  if (size === "small") {
    return (
      <div className={classes.tagSmall} style={{ backgroundColor: color[title] }}>
        {type === null && <span style={{cursor: "auto"}}>{title}</span>}
        {type !== null && title !== "+" && (
          <>
            <span onClick={handlingButtonClick}>{title}</span>
            <button onClick={handlingButtonClick}>x</button>
          </>
        )}
      </div>
    );
  }

  return (
    <div className={classes.tag} style={{ backgroundColor: color[title] }}>
      {type === null && <span>{title}</span>}
      {type !== null && title !== "+" && (
        <>
          <span onClick={handlingButtonClick}>{title}</span>
          <button onClick={handlingButtonClick}>x</button>
        </>
      )}
    </div>
  );
};

export default Tag;
