import { useState } from "react";
import { useLoaderData, redirect } from "react-router-dom";
import DashboardStatsPanel from "../../../components/dashboard/StatsPanel";
import { dog, application} from "../../../assets/svg/svgs";
import ApplicationTable from "../../../components/dashboard/ApplicationTable";

const RescueDashboardApplicationsPage = () => {
  const data = useLoaderData();
  const stats = getStats(data);
  const [applications, setApplications] = useState(data);
  const defaultPageSize = 10;


  console.log(data);

  const handlingClick = (option) => {
    if (option === "ALL") setApplications(data);
    else {
      const filteredApplications = data.filter((application) => {
        return application.status === option;
      });
      setApplications(filteredApplications);
    }
  };

  const statCards = [
    {
      body: true,
      title: "Total Applications",
      icon: dog,
      stat: data.length,
      footer: true,
      actionButton: true,
      footerMsg: "View all applications",
      onClick: () => handlingClick("ALL"),
    },
    {
      body: true,
      title: "New Applications",
      icon: application,
      stat: stats.new,
      footer: true,
      actionButton: true,
      footerMsg: "View new applications",
      onClick: () => handlingClick("APPLICATION_SUBMITTED"),
    },
    {
      body: true,
      title: "In Review",
      icon: dog,
      stat: stats.review,
      footer: true,
      actionButton: true,
      footerMsg: "View pending applications",
      onClick: () => handlingClick("APPLICATION_IN_REVIEW"),
    },
    {
      body: true,
      title: "Approved",
      icon: application,
      stat: stats.approved,
      footer: true,
      actionButton: true,
      footerMsg: "View approved applications",
      onClick: () => handlingClick("APPLICATION_APPROVED"),
    },
  ];

  return (
    <div>
      <DashboardStatsPanel statCards={statCards} />

      <ApplicationTable applications={applications} title="Applications" pageSize={defaultPageSize}/>
    </div>
  );
};

export default RescueDashboardApplicationsPage;

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/rescues/rescue/applications`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      status = response.status;
      const data = await response.json();
      return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};

const getStats = (applications) => {
  console.log(applications);
  const stats = { new: 0, review: 0, approved: 0 };
  applications.forEach((app) => {
    switch (app.status) {
      case "APPLICATION_SUBMITTED":
        stats.new++;
        return;
      case "APPLICATION_IN_REVIEW":
        stats.review++;
        return;
      case "APPLICATION_APPROVED":
        stats.approved++;
        return;
      default:
        return;
    }
  });
  return stats;
};
