/*
  navigation for admin dashboard
*/

import { NavLink, Link, Form } from "react-router-dom";
import classes from "./Dashboard.module.css";
import logo from "../../assets/images/logo.png";
import { pets, house, close } from "../../assets/svg/svgs";

const AdminNavigation = ({ name, menuToggle, onMenuClick }) => {
  const handleMenu = () => {
    onMenuClick(false);
  };

  return (
    <div
      className={
        menuToggle
          ? classes["dashboard-nav-container-active"]
          : classes["dashboard-nav-container"]
      }
    >
      <div className={classes["dashboard-menu-button-wrapper"]}>
        <button
          onClick={handleMenu}
          className={classes["dashboard-menu-button"]}
        >
          {close}
        </button>
      </div>

      <div className={classes["dashboard-nav"]}>
        <div className={classes["dashboard-logo-wrapper"]}>
          <Link to="/">
            <img src={logo} />
          </Link>
        </div>
        <ul>
          <li>
            <NavLink
              to="/admin"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              end
            >
              {house}
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/rescues"
              onClick={handleMenu}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              {pets}
              Rescues
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={classes["dashboard-avatar-container"]}>
        <div className={classes["dashboard-avatar"]}>
          {name.charAt(0).toUpperCase()}
        </div>
        <div className={classes["dashboard-avatar-text-wrapper"]}>
          <h3>{name}</h3>
          <Form method="post" action="/rescue/logout">
            <button>Logout</button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminNavigation;
