import classes from './Copyright.module.css';

const Copyright = () => {

    const today = new Date();
    const year = today.getFullYear();
    return (
        <footer className={classes.dashboardFooter}>© {year} Fetched, Inc. All rights reserved. </footer>
    )
}

export default Copyright;