export const eventDefault = {
  city: "",
  description: "",
  endDate: "",
  endTime: "",
  isPhysical: null,
  location: "",
  startDate: "",
  startTime: "",
  state: "",
  streetAddress1: "",
  // streetAddress2: "",
  title: "",
  url: "",
  zipcode: "",
};

export const documentDefault = {
  file : null,
  title : "",
  note : ""
}

export const customQuestionDefault = {
  question: "",
};

export const animalDefault = {
  name: "",
  adoptionStatus: "",
  animalMeta: {
    species: "",
    gender: "",
    age: "",
    birthDate: "",
    isMixed: "",
    primaryBreedId: "",
    secondaryBreedId: "",
    coatLength: "",
    size: "",
    weight: "",
    housetrained: false,
    health: "",
    isGoodWithDogs: false,
    isGoodWithCats: false,
    isGoodWithKids: false,
    temperament: "",
    description: "",
    adoptionFee: "",
    intakeDate: "",
    intakeSource: "",
    intakeDetail: "",
  },
};

export const userMetaDefault = {
  firstName : "",         
  lastName : "",         
  pronouns : "",          
  streetAddress1 : "",    
  // streetAddress2 : "",   
  city : "",
  state : "",             
  zipCode : "",            
  phone : ""           
}
