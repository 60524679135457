import { useState, useEffect } from "react";
import Select from "react-select";
import { stateOptions } from "../../../utils/enums";

import {
  Form,
  useLoaderData,
  redirect,
  useSubmit,
  useActionData,
  Link,
} from "react-router-dom";
import classes from "./RescueProfile.module.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  // Form,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import RescueLogoUpload from "../../../components/fileUpload/RescueLogoUpload";
import bg3 from "../../../assets/images/pexels-2112010.jpg";
import LogoPlacement from "../../../assets/images/logo_placement_bw.png";

const RescueDashboardProfilePage = () => {
  const data = useLoaderData();
  const submit = useSubmit();
  const actionData = useActionData();
  const [usState, setUsState] = useState("");

  console.log(data);

  const logoImg = data.rescue.rescueLogo
    ? `${process.env.REACT_APP_API_BASE_ROOT}${data.rescue.rescueLogo.img_url}`
    : LogoPlacement;

  const [isInterviewReq, setIsInterviewReq] = useState(
    data.rescue.rescueMeta.interviewReq
  );
  const [isHomeCheckReq, setIsHomeCheckReq] = useState(
    data.rescue.rescueMeta.homeCheckReq
  );
  const [phoneNumber, setPhoneNumber] = useState(data.rescue.rescueMeta.phone);

  const handlePhoneNumber = (e) => {
    let num = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let updateNum = !num[2]
      ? num[1]
      : +num[1] + "-" + num[2] + (num[3] ? "-" + num[3] : "");
    setPhoneNumber(updateNum);
  };

  const handleFormClick = (e, method, formId) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("form-id", formId);
    const actionPath = `/rescue/profile`;
    submit(formData, { method: method, action: actionPath });
  };

  useEffect(() => {
    setUsState({
      value: data.rescue.rescueMeta.state,
      label: data.rescue.rescueMeta.state,
    });
  }, []);

  return (
    <div className="content">
      {data.rescue.isEnabled && (
        <Button>
          <Link to={`/page/rescues/${data.rescue.id}`}>
            View Public Rescue Profile Page
          </Link>
        </Button>
      )}
      {!data.rescue.isEnabled && (
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                Your account is currently under verification process. <br />
                Once your account is verified and approved, you can start
                seeinng your rescue profile and animals on the public website.
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row className={classes["profile-cards"]}>
        <Col md="8">
          <Card>
            <CardHeader style={{ borderBottom: "1px solid #ddd" }}>
              <h5 className="title">Edit Rescue Profile</h5>
            </CardHeader>
            <CardBody>
              <Form method="PUT">
                <input hidden name="form-id" defaultValue="updateProfile" />
                <Row>
                  <Col className="pr-1" md="2">
                    <div className="form-group">
                      <label>Rescue ID</label>
                      <Input
                        defaultValue={data.rescue.id}
                        disabled
                        placeholder="rescueId"
                        type="text"
                        name="id"
                      />
                    </div>
                  </Col>
                  <Col className="px-1" md="6">
                    <div className="form-group">
                      <label>Rescue Legal Name</label>
                      <Input
                        defaultValue={data.rescue.legalRescueName}
                        disabled
                        placeholder="rescue"
                        type="text"
                        name="leagleRescueName"
                      />
                    </div>
                  </Col>
                  <Col className="pl-1" md="4">
                    <div className="form-group">
                      <label>Username</label>
                      <Input
                        defaultValue={data.username}
                        disabled
                        placeholder="Username"
                        type="text"
                        name="username"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <div className="form-group">
                      <label>Contact Name</label>
                      <Input
                        defaultValue={data.rescue.rescueMeta.contactName}
                        placeholder="Contact Name"
                        type="text"
                        name="contactName"
                      />
                    </div>
                  </Col>
                  <Col className="px-1" md="4">
                    <div className="form-group">
                      <label htmlFor="email">Email address</label>
                      <Input
                        id="email"
                        disabled
                        placeholder="Email"
                        type="email"
                        defaultValue={data.email}
                        name="email"
                      />
                    </div>
                  </Col>
                  <Col className="pl-1" md="4">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <Input
                        onChange={handlePhoneNumber}
                        value={phoneNumber}
                        placeholder="555-555-5555"
                        type="text"
                        name="phone"
                        maxLength="12"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label>Street Address</label>
                      <Input
                        defaultValue={data.rescue.rescueMeta.streetAddress1}
                        placeholder="Legal Address 1"
                        type="text"
                        name="streetAddress1"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <div className="form-group">
                      <label>City</label>
                      <Input
                        defaultValue={data.rescue.rescueMeta.city}
                        placeholder="City"
                        type="text"
                        name="city"
                      />
                    </div>
                  </Col>
                  <Col className="px-1" md="2">
                    <div className="form-group">
                      <label>State</label>
                      <Select
                        id="state"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        required
                        name="state"
                        value={usState}
                        onChange={(value) => {
                          setUsState(value);
                        }}
                        options={stateOptions}
                        placeholder="State"
                      />
                    </div>
                  </Col>
                  <Col className="px-1" md="2">
                    <div className="form-group">
                      <label>Zip Code</label>
                      <Input
                        placeholder="ZIP Code"
                        type="text"
                        defaultValue={data.rescue.rescueMeta.zipCode}
                        name="zipCode"
                        maxLength="5"
                      />
                    </div>
                  </Col>
                  <Col className="pl-1" md="4">
                    <div className="form-group">
                      <label>Adoption Age Requirement</label>
                      <Input
                        placeholder="Minimum Age Requirement"
                        type="number"
                        defaultValue={data.rescue.rescueMeta.adoptionAgeReq}
                        name="adoptionAgeReq"
                        min="18"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <div className="form-group">
                      <label>Website Address</label>
                      <Input
                        defaultValue={data.rescue.rescueMeta.website}
                        placeholder="Website Address"
                        type="text"
                        name="website"
                      />
                    </div>
                  </Col>
                  <Col className="px-1" md="4">
                    <div className="form-group">
                      <label>Merch Store Address</label>
                      <Input
                        defaultValue={data.rescue.rescueMeta.merchStore}
                        placeholder="Merch Store Web Address"
                        type="text"
                        name="merchStore"
                      />
                    </div>
                  </Col>
                  <Col className="pl-1" md="4">
                    <div className="form-group">
                      <label>Donation Address</label>
                      <Input
                        defaultValue={data.rescue.rescueMeta.donation}
                        placeholder="Donation Page Web Address"
                        type="text"
                        name="donation"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="6">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="interviewReq"
                          checked={isInterviewReq}
                          onChange={() => {
                            setIsInterviewReq((prevState) => !prevState);
                          }}
                          value={isInterviewReq}
                        />
                        <span className="form-check-sign" />
                        Adopter Interview Required
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="6">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="homeCheckReq"
                          checked={isHomeCheckReq}
                          onChange={() => {
                            setIsHomeCheckReq((prevState) => !prevState);
                          }}
                          value={isHomeCheckReq}
                        />
                        <span className="form-check-sign" />
                        Adopter Home Check Required
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label>About {data.rescue.legalRescueName} </label>
                      <Input
                        className="textarea"
                        type="textarea"
                        cols="80"
                        rows="4"
                        placeholder="We are a 501(c)(3) nonprofit organization..."
                        defaultValue={data.rescue.rescueMeta.description}
                        name="description"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label>Our Story</label>
                      <Input
                        className="textarea"
                        type="textarea"
                        cols="80"
                        rows="4"
                        placeholder={`${data.rescue.legalRescueName} focus on ... `}
                        defaultValue={data.rescue.rescueMeta.story}
                        name="story"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12 flex justify-content flex-end">
                    <Button>Update Profile</Button>
                    {actionData?.profileUpdateSuccess && (
                      <span>{actionData.profileUpdateSuccess}</span>
                    )}
                    {actionData?.profileUpdateError && (
                      <span>{actionData.profileUpdateError}</span>
                    )}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardHeader style={{ borderBottom: "1px solid #ddd" }}>
              <h5 className="title">Rescue Social Profile</h5>
            </CardHeader>
            <CardBody>
              <Form method="PUT">
                <input hidden name="form-id" defaultValue="updateSocial" />
                <Row>
                  <Col className="pr-1" md="6">
                    <div className="form-group">
                      <label>facebook.com/</label>
                      <Input
                        defaultValue={data.rescue.socialProfile.facebook}
                        placeholder="facebook account"
                        type="text"
                        name="facebook"
                      />
                    </div>
                  </Col>
                  <Col className="pl-1" md="6">
                    <div className="form-group">
                      <label>twitter.com/</label>
                      <Input
                        defaultValue={data.rescue.socialProfile.x}
                        placeholder="twitter account"
                        type="text"
                        name="x"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="6">
                    <div className="form-group">
                      <label>instagram.com/</label>
                      <Input
                        defaultValue={data.rescue.socialProfile.instagram}
                        placeholder="instagram account"
                        type="text"
                        name="instagram"
                      />
                    </div>
                  </Col>
                  <Col className="pl-1" md="6">
                    <div className="form-group">
                      <label>tiktok.com/@</label>
                      <Input
                        defaultValue={data.rescue.socialProfile.tiktok}
                        placeholder="tiktok account"
                        type="text"
                        name="tiktok"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="6">
                    <div className="form-group">
                      <label>youtube.com/@</label>
                      <Input
                        defaultValue={data.rescue.socialProfile.youtube}
                        placeholder="youtube channel"
                        type="text"
                        name="youtube"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col md="12 flex justify-content flex-end">
                    <Button>Update Social Profile</Button>
                    {actionData?.socialUpdateSuccess && (
                      <span>{actionData.socialUpdateSuccess}</span>
                    )}
                    {actionData?.socialUpdateError && (
                      <span>{actionData.socialUpdateError}</span>
                    )}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="card-user">
            <div className="image">
              <img alt="..." src={bg3} />
            </div>
            <CardBody>
              <div className="rescue">
                <div className={classes.logoWrapper}>
                  {data.rescue.rescueLogo && (
                    <Form>
                      <div className={classes.logoButtonWrapper}>
                        <Button
                          color="info"
                          className="btn-round multiBtn"
                          // onClick={handleDeleteLogo}
                          onClick={(e) => {
                            handleFormClick(e, "DELETE", "deleteLogo");
                          }}
                        >
                          <i className="fa fa-minus" />
                        </Button>
                      </div>
                    </Form>
                  )}
                  {!data.rescue.rescueLogo && (
                    <RescueLogoUpload
                      logo={data.rescue.rescueLogo}
                      rescueId={data.rescue.id}
                    />
                  )}
                  <img
                    alt={`${data.rescue.legalRescueName} logo`}
                    className="avatar border-black bg-white "
                    src={logoImg}
                  />
                </div>
                <h5 className="title">{data.rescue.legalRescueName}</h5>

                <p className="description">@{data.username}</p>
              </div>
              <p className="description text-center">
                {data.rescue.ein}
                <br />
                {data.rescue.rescueMeta.streetAddress1}
                <br />
                {data.rescue.rescueMeta.city}, {data.rescue.rescueMeta.state}{" "}
                {data.rescue.rescueMeta.zipCode}
                <br />
              </p>
            </CardBody>
            <CardFooter />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RescueDashboardProfilePage;

// update rescue profile put route at line 275

export const multiActions = async ({ request, params }) => {
  const formData = await request.formData();
  const userData = Object.fromEntries(formData);

  console.log(formData);
  const formId = formData.get("form-id");
  console.log(formId);
  let logoUrlBase = `${process.env.REACT_APP_API_ROOT}/files/rescue/logo`;
  let status = "";
  const actionData = {};

  switch (formId) {
    case "addLogo":
      const logoResponse = await fetch(logoUrlBase, {
        method: "POST",
        credentials: "include",
        // do not use header and stringify for multipart/formdata encription type, already included in formData
        body: formData,
      });
      status = logoResponse.status;

      if (!logoResponse.ok) {
        console.log("status: " + status);
        actionData.logoError = "Can't upload logo.  Try again later.";
        return actionData;
      }
      const logoData = await logoResponse.json();
      return logoData;

    case "deleteLogo":
      const deleteLogoResponse = await fetch(logoUrlBase, {
        method: "DELETE",
        credentials: "include",
      });
      status = deleteLogoResponse.status;
      if (!deleteLogoResponse.ok) {
        actionData.logoError = "Can't delete logo.  Try again later.";
        return actionData;
      }
      const deleteLogoData = await deleteLogoResponse.json();
      return deleteLogoData;

    case "updateSocial":
      const updateSocialResponse = await fetch(
        `${process.env.REACT_APP_API_ROOT}/rescues/rescue/social`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );
      status = updateSocialResponse.status;
      if (!updateSocialResponse.ok) {
        actionData.socialUpdateError =
          "Can't update social accounts.  Try again later.";
        return actionData;
      }
      const updateSocialData = await updateSocialResponse.json();
      actionData.socialUpdateSuccess = "Success!";
      return actionData;

    case "updateProfile":
      
      const updateProfileResponse = await fetch(
        `${process.env.REACT_APP_API_ROOT}/rescues/rescue/meta`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );
      status = updateProfileResponse.status;
      if (!updateProfileResponse.ok) {
        actionData.profileUpdateError =
          "Can't update profile at this time.  Try again later.";
        return actionData;
      }

      actionData.profileUpdateSuccess = "Success!";
      return actionData;

    case "updateUsername":

      return;

    case "udateEmail":
      return;

    default:
      console.log("form submitted without a valid form id");
  }
};

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
