import { useState } from "react";
import { useLoaderData, redirect, Form } from "react-router-dom";
import Select from "react-select";

import classes from "./MainPage.module.css";
import NoteTimeline from "../../../components/dashboard/NoteTimeLine";
import ApplicationAnimalProfile from "../../../components/dashboard/ApplicationAnimalProfile";
import ApplicationMeta from "../../../components/dashboard/ApplicationMeta";
import ApplicationQuestions from "../../../components/dashboard/ApplicationQuestions";
import {applicationStatusOptions} from '../../../utils/enums';
import {
  Row,
  Col,
  Button,
} from "reactstrap";

const applicationStatus = {
  APPLICATION_SUBMITTED: "Application Submitted",
  APPLICATION_IN_REVIEW: "Application in Review",
  APPLICATION_APPROVED: "Application Approved",
  PET_FOUND_ANOTHER_HOME: "Pet Found Another Home",
};

const RescueDashboardApplicationPage = () => {
  const data = useLoaderData();
  const [status, setStatus] = useState({
    value: data.status,
    label: applicationStatus[data.status],
  });
  console.log(data);

  return (
    <div className={classes.container}>
      <div className="col-sm-12 col-md-8">
        <Row
          style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px" }}
        >
          <div className="col-lg-12 col-xl-6">
            <p>Application received on {data.createdAt.substring(0, 10)}</p>
          </div>
          <div className="col-lg-12 col-xl-6">
            <Form method="PUT">
              <input hidden name="form-id" defaultValue="status" />
              <Row>
                <Col md="8" className="pr-1">
                  <div className="form-group">
                    <Select
                      id="status"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      required
                      name="status"
                      value={status}
                      onChange={(value) => {
                        setStatus(value);
                      }}
                      options={applicationStatusOptions}
                      placeholder="Select"
                    />
                  </div>
                </Col>
                <Col md="4" className="pl-1">
                  <Button className={classes.singleUpdateButton}>Update</Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Row>
        <Row style={{ marginTop: "20px" }}>
         
          <div className="col-lg-12 col-xl-6">
            <ApplicationMeta
              user={data.user}
              applicationMeta={data.applicationMeta}
              rescueMeta={data.rescue.rescueMeta}
            />
          </div>
           <div className="col-lg-12 col-xl-6">
            <ApplicationAnimalProfile animal={data.animal}/>
          </div>
        </Row>
        <h5>Personal Questions</h5>
        <ApplicationQuestions questions={data.applicationQuestions} category="PERSONAL"/>
        <h5>General Questions</h5>
        <ApplicationQuestions questions={data.applicationQuestions} category="GENERAL"/>
        <h5>Living Environment Questions</h5>
        <ApplicationQuestions questions={data.applicationQuestions} category="LIVING_ENVIORNMENT"/>
        <h5>Current & Previous Animals Questions</h5>
        <ApplicationQuestions questions={data.applicationQuestions} category="PREVIOUS_CURRENT_ANIMAL"/>
      </div>
      <div className="col-sm-12 col-md-4">
        <NoteTimeline notes={data.notes} title="" />
      </div>
    </div>
  );
};

export default RescueDashboardApplicationPage;

export const multiActions = async ({ request, params }) => {
  const formData = await request.formData();
  const formId = formData.get("form-id");
  let status = "";
  let url = "";

  switch (formId) {
    case "note":
      const note = { note: formData.get("note") };
      url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/applications/${params.applicationId}/note`;
      const noteResponse = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(note),
      });
      status = noteResponse.status;
      if (!noteResponse.ok) {
        console.log("status: " + status);
        return noteResponse;
      }
      const noteData = await noteResponse.json();
      console.log("data: " + noteData);
      return noteData;

    case "status":
      const applicationStatus = { status: formData.get("status") };
      url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/applications/${params.applicationId}`;
      const statusResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(applicationStatus),
      });
      status = statusResponse.status;
      if (!statusResponse.ok) {
        console.log("status: " + status);
        return statusResponse;
      }
      const statusData = await statusResponse.json();
      return statusData;

    default:
      console.log("formData submitted without a valid id");
  }
};

export const loader = async ({ params }) => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/applications/${params.applicationId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    if (data.message && data.message === "Unauthorized") {
      return redirect("/rescue/applications");
    } else {
      return data;
    }
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
