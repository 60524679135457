import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import DashboardTable from "../table/DashboardTable";
import {
  dog,
  application as applicationSvg,
  user,
} from "../../assets/svg/svgs";

const ApplicationTable = ({ applications, title, pageSize }) => {
  const processor = (applications) => {
    const applicationsState = applications.map((application, key) => {
      return {
        id: `application_${application.id}`,
        application_id: application.id,
        animal_name: application.animal.name,
        status: application.status.replaceAll("_", " "),
        applicant: `${application.applicationMeta.firstName} ${application.applicationMeta.lastName}`,
        username: application.user.username,
        actions: (
          <div className="actions-right">
            <Link to={`/rescue/applications/${application.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Application"
              >
                {applicationSvg}
              </button>
            </Link>
            <Link to={`/rescue/animals/${application.animal.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Animal"
              >
                {dog}
              </button>
            </Link>
            <Link to={`/rescue/applicants/${application.user.id}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Applicant"
              >
                {user}
              </button>
            </Link>
          </div>
        ),
      };
    });
    return applicationsState;
  };

  const [dataState, setDataState] = useState(processor(applications));

  const flexGrow = ["max-80", "", "grow-2", "grow-2", "", "grow-0-auto"];

  useEffect(() => {
    setDataState(processor(applications));
  }, [applications]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <h5>{title}</h5>
          <Card className="dashboardTable">
            <CardBody>
              <DashboardTable
                data={dataState}
                columns={[
                  {
                    Header: "ID",
                    accessor: "application_id",
                  },
                  {
                    Header: "Animal",
                    accessor: "animal_name",
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                  },
                  {
                    Header: "Applicant",
                    accessor: "applicant",
                  },
                  {
                    Header: "Username",
                    accessor: "username",
                  },
                  {
                    Header: (
                      <div>
                        <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                          {applicationSvg}
                        </button>
                        <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                          {dog}
                        </button>
                        <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                          {user}
                        </button>
                      </div>
                    ),
                    accessor: "actions",
                  },
                ]}
                className="-striped -highlight primary-pagination"
                flexGrow={flexGrow}
                pageRowCount={pageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationTable;
