import { useLoaderData, redirect } from "react-router-dom";
import ApplicantTable from "../../../components/dashboard/ApplicantTable";

const RescueDashboardApplicantsPage = () => {
    const data = useLoaderData();
    const defaultPageSize = 10;

    /* 
      [userId, {username, name, count, tags}]
    */
    console.log(data);
    
    return (
        <div>
            <ApplicantTable applicants={data} title="Applicants" pageSize={defaultPageSize}/>
        </div>
    )
}

export default RescueDashboardApplicantsPage;

export const loader = async() => {
  let status = "";
  try {
    const response = await fetch(`${process.env.REACT_APP_API_ROOT}/rescues/rescue/applicants`, 
        {
            method: "GET",
            credentials: "include",
            headers: {
                'Content-Type':'application/json',
            }
        });
        status = response.status;
        const data = await response.json();
        return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
  
}