import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, Row } from "reactstrap";

import DocumentForm from "./DocumentForm";
import MultipleDocumentItem from "./MultipleDocumentItem";
import { documentDefault } from "../../utils/initialState";

// const urlRoot = "http://localhost:8080";
const urlRoot = `${process.env.REACT_APP_API_BASE_ROOT}`;

const MultipleDocumentUpload = ({ documents }) => {
  // documents {id, title, note, url}


  const [modal, setModal] = useState(false);
  const [method, setMethod] = useState("POST");
  const [activeDocument, setActiveDocument] = useState(documentDefault);
  const [modalPreview, setModalPreview] = useState("");

  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  const { animalId } = useParams();



  return (
    <div className="flex-column" style={{width: "100%"}}>
      <Row style={{ marginLeft: 0, marginRight: 0 }}>
        <Button
          color="fetched-solid"
          onClick={() => {
            setMethod("POST");
            setActiveDocument(documentDefault);
            toggle();
          }}
        >
          + Add New Document
        </Button>
      </Row>
      <Row>
        <div className="flex-wrap" style={{width: "100%"}}>
          {documents.map((document, i) => (
            <MultipleDocumentItem
              key={`document_${animalId}${i + 1}`}
              document={document}
              setMethod={setMethod}
              setActiveDocument={setActiveDocument}
              toggle={toggle}
            />
          ))}
        </div>
      </Row>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="dashboardFormModal modal-dialog-centered"
      >
        <ModalHeader toggle={toggle}>
          {method === "PUT" ? "Edit Document" : "Add New Document"}
        </ModalHeader>
        <ModalBody>
          <DocumentForm
            document={activeDocument}
            method={method}
            toggle={toggle}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MultipleDocumentUpload;
