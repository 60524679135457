import { json, redirect } from 'react-router-dom';
import classes from './Login.module.css';
import LoginForm from '../components/form/LoginForm';
import {encode, decode} from 'string-encode-decode';

const LoginPage = () => {
    return (
        <>
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={classes["prompt-wrapper"]}>
                    <h2>Sign in to your Fetched account</h2>
                </div>
                <div className={classes["form-wrapper"]}>  
                    <LoginForm /> 
                </div>
            </div>
        </div>
        </>
    )
}

export default LoginPage;

export const action = async ({request}) => {
    // const data = Object.fromEntries(await request.formData());
    // console.log(data);

    const data = await request.formData();
    const auth = {
        "username" : data.get("username"),
        "password" : data.get("password")
    }

    
    const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/auth/login`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            'Content-Type':'application/json',
            // "Access-Control-Allow-Origin": "http://localhost:3000",
          },
          body: JSON.stringify(auth)
        }
      );

      if(!response.ok) {
        console.log("Response not ok");
        throw json({message: "Couldn't authenticate user"}, {status: 500});
      } 
        const resData = await response.json();
        // if user is not deleted nor inactive
        if(resData.user.isEnabled && !resData.user.isDeleted) {

            const token = encode(JSON.stringify(resData.user));
            localStorage.setItem('token', token);
            const decodeToken = decode(token);
            console.log(JSON.parse(decodeToken));

            switch(resData.user.role) {
                case "RESCUE" :
                    return redirect("/rescue");
                case "ADMIN" :
                    return redirect("/admin");
                default :
                    return redirect("/user");
            }
        }
        return redirect('/');
}
