import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import DashboardTable from "../table/DashboardTable";
import { user } from "../../assets/svg/svgs";
import Tag from "../../ui/Tag";

const ApplicantTable = ({ applicants, title, pageSize }) => {
  const processor = (applicants) => {
    const applicantsState = applicants.map((applicant, key) => {
      return {
        id: `applicant_${applicant[0]}`,
        user_id: applicant[0],
        username: applicant[1].username,
        full_name: applicant[1].name,
        tags: (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {getTags(applicant[1].tags).map((tagTitle, i) => (
              <Tag
                title={tagTitle}
                key={`tag_${applicant[0]}_${i + 1}`}
                type={null}
                size="small"
              />
            ))}
          </div>
        ),
        application_counts: applicant[1].count,
        actions: (
          <div className="actions-right">
            <Link to={`/rescue/applicants/${applicant[0]}`}>
              <button
                className="btn-icon btn-link like btn btn-fetched btn-sm"
                type="button"
                title="View Applicant"
              >
                {user}
              </button>
            </Link>
          </div>
        ),
      };
    });
    return applicantsState;
  };

  const [dataState, setDataState] = useState(processor(applicants));

  const flexGrow = ["max-80", "", "grow-2", "grow-2", "", "grow-0-auto"];

  useEffect(() => {
    setDataState(processor(applicants));
  }, [applicants]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <h5>{title}</h5>
          <Card className="dashboardTable">
            <CardBody>
              <DashboardTable
                data={dataState}
                columns={[
                  {
                    Header: "ID",
                    accessor: "user_id",
                  },
                  {
                    Header: "Username",
                    accessor: "username",
                  },
                  {
                    Header: "Full Name",
                    accessor: "full_name",
                  },

                  {
                    Header: "Tags",
                    accessor: "tags",
                  },
                  {
                    Header: "Counts",
                    accessor: "application_counts",
                  },
                  {
                    Header: (
                      <button className="btn-icon btn-link like btn btn-fetched btn-sm">
                        {user}
                      </button>
                    ),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                className="-striped -highlight primary-pagination"
                flexGrow={flexGrow}
                pageRowCount={pageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ApplicantTable;

const getTags = (tags) => {
  let tagsArr = [];
  if (tags.isNewApplicant) {
    tagsArr.push("New Applicant");
  }
  if (tags.isRepeatAdopter) {
    tagsArr.push("Repeat Adopter");
  }
  if (tags.isRepeatApplicant) {
    tagsArr.push("Repeat Applicant");
  }
  if (tags.isFlagged) {
    tagsArr.push("Flagged");
  }
  return tagsArr;
};
