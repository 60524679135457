import { useLoaderData, redirect } from "react-router-dom";
import { heart, application, comment } from "../../../assets/svg/svgs";
import DashboardStatsPanel from "../../../components/dashboard/StatsPanel";
import UserApplicationTable from "../../../components/dashboard/UserApplicationTable";
import UserQuestionTable from "../../../components/dashboard/UserQuestionTable";

const UserDashboardPage = () => {
  const data = useLoaderData();
  const {animalQuestions, animalsLikes, applications} = data;
  const defaultPageSize = 5;
  console.log(data);

  const statCards = [
    {
      body: true,
      title: "Total Saved Animals",
      icon: heart,
      stat: animalsLikes.length,
      footer: true,
      actionButton: false,
      footerMsg: "View Saved Animals",
      route: "/user/favorites",
    },
    {
      body: true,
      title: "Total Applications",
      icon: application,
      stat: applications.length,
      footer: true,
      actionButton: false,
      footerMsg: "View applications",
      route: "/user/applications",
    },
    {
      body: true,
      title: "Total Questions",
      icon: comment,
      stat: animalQuestions.length,
      footer: true,
      actionButton: false,
      footerMsg: "View questions",
      route: "/user/questions",
    }
  ];

  return (
    <div>
      <DashboardStatsPanel statCards={statCards}/>
      <h1>Dashboard</h1>
      <UserApplicationTable applications={applications.slice(0,5)} title="Recent Applications" pageSize={defaultPageSize}/>
      <UserQuestionTable questions={animalQuestions.slice(0,5)} title="Recent Questions" pageSize={defaultPageSize}/>

    </div>
  );
};

export default UserDashboardPage;

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/users/userDashboard`, 
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
