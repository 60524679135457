import { redirect } from 'react-router-dom';

export const action = async () => {

    try {
        await fetch(`${process.env.REACT_APP_API_ROOT}/auth/logout`);
        localStorage.removeItem('token');
    } catch(error) {
        console.log(error.message);
    }
    
    return redirect("/auth/login");
}