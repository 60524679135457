import { useState, useRef, useEffect } from "react";
import { useSubmit, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import classes from "../../pages/dashboard/rescue/RescueProfile.module.css";

const RescueLogoUpload = ({ logo, rescueId }) => {

  const [logoId, setLogoId] = useState();
  const [previewUrl, setPreviewUrl] = useState();

  const submit = useSubmit(); 

  const addImageRef = useRef();

  const handleSubmit = (e, formData) => {
    e.preventDefault();
    const actionPath = `/rescue/profile`;
    submit(formData, { method: "POST", encType: "multipart/form-data", action: actionPath });
  };

  const handleImageChange = (e, formId) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("form-id", "addLogo");
    formData.append("fileType", "photo");
    formData.append("rescueId", rescueId);
    formData.append("file", file);
    reader.onloadend = () => {
      setLogoId(logoId);
      setPreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    handleSubmit(e, formData);
  };


  const handleClick = () => {
    addImageRef.current.click();
  };

  const resetStates = (logo) => {
    setLogoId(logo.id);
    setPreviewUrl(logo.img_url);
  }

  useEffect(() => {
    if (logo) {
      resetStates(logo);
    }
  }, [logoId, previewUrl]);


  return (
          <div className={classes.logoButtonWrapper}>
            <input
              type="file"
              onChange={(e) => {
                handleImageChange(e, logo ? "updateLogo" : "addLogo");
              }}
              ref={addImageRef}
              accept="image/png, image/jpeg, image/webp"
              style={{ display: "none" }}
            />
            <Button
              color="info"
              className="btn-round multiBtn"
              onClick={handleClick}
            >
              <i className="fa fa-plus" />
            </Button>
          </div>
  );
};

export default RescueLogoUpload;
