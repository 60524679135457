import { Outlet } from "react-router-dom";
import MainNavigation from "../components/navigation/Main";
import HeroBg from "../components/HeroBg";
import classes from "./Root.module.css";
import NoiseBG from "../assets/images/NoiseTexture.png";
import ScrollToTop from "../components/ScrollToTop";

const RootLayout = () => {
  return (
    <>
      <div
        className={classes.noiseOverlay}
        style={{ backgroundImage: `url(${NoiseBG})` }}
      />
      <MainNavigation />
      <HeroBg />
      
      <main className={classes.main}>
        <Outlet />
        <ScrollToTop />
      </main>
    </>
  );
};

export default RootLayout;
