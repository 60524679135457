import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    username : "",
    session : "",
    userId: "",
    userRole: "",
    rescueId: "",
    rescueName: "",
    expiration: "",
}

const rootSlice = createSlice({
    name : "root",
    initialState,
    reducers : {
        loggedin : (state, action) => {
            const {username, session, userId, userRole, rescueId, rescueName, expiration} = action.payload;
            state.username = username;
            state.session = session;
            state.userId = userId;
            state.userRole = userRole;
            state.rescueId = rescueId;
            state.rescueName = rescueName;
            state.expiration = expiration;
        },
        loggedout : (state) => {
            state.username = "";
            state.session = "";
            state.userId = "";
            state.userRole = "";
            state.rescueId = "";
            state.rescueName = "";
            state.expiration = "";
        }
    }
});

export const rootSliceActions = rootSlice.actions;

export default rootSlice.reducer;