import { useState, useEffect } from "react";
import { Form } from "react-router-dom";
import Datetime from "react-datetime";
import Select from "react-select";
import moment from "moment";
import { Button, Input, Row, Col, Label, FormGroup } from "reactstrap";

import {
  sizeOptions,
  lengthOptions,
  weightOptions,
  ageOptions,
  speciesOptions,
  genderOptions,
  adoptionStatusOptions,
  booleanOptions,
} from "../../utils/enums";

const AnimalForm = ({ method, dogBreeds, catBreeds, animal }) => {

  const rawBirthDate = animal.animalMeta.birthDate
    ? new Date(animal.animalMeta.birthDate)
    : null;
  const rawIntakeDate = animal.animalMeta.intakeDate
    ? new Date(animal.animalMeta.intakeDate)
    : null;
    
    // accounting for timezone difference
    const now = new Date();
    const initialBirthDate = rawBirthDate ? new Date(rawBirthDate.getTime() + now.getTimezoneOffset() * 60 * 1000) : null;
    const initialIntakeDate = rawIntakeDate ? new Date( rawIntakeDate.getTime() + now.getTimezoneOffset() * 60 * 1000) : null;

  const [intakeDate, setIntakeDate] = useState(initialIntakeDate);
  const [birthDate, setBirthDate] = useState(initialBirthDate);

  const [activeBreedOptions, setActiveBreedOptions] = useState(
    animal.animalMeta.species === "DOG"
      ? dogBreeds
      : animal.animalMeta.species === "CAT"
      ? catBreeds
      : []
  );

  const [species, setSpecies] = useState(
    method === "PUT"
      ? {
          value: animal.animalMeta.species,
          label: animal.animalMeta.species.replaceAll("_", " "),
        }
      : speciesOptions[0]
  );

  const [adoptionStatus, setAdoptionStatus] = useState(
    method === "PUT"
      ? {
          value: animal.adoptionStatus,
          label: animal.adoptionStatus.replaceAll("_", " "),
        }
      : adoptionStatusOptions[0]
  );

  const [weight, setWeight] = useState(
    method === "PUT"
      ? {
          value: animal.animalMeta.weight,
          label: animal.animalMeta.weight.replaceAll("_", " "),
        }
      : weightOptions[0]
  );

  const [size, setSize] = useState(
    method === "PUT"
      ? {
          value: animal.animalMeta.size,
          label: animal.animalMeta.size.replaceAll("_", " "),
        }
      : sizeOptions[0]
  );

  const [coatLength, setCoatLength] = useState(
    method === "PUT"
      ? {
          value: animal.animalMeta.coatLength,
          label: animal.animalMeta.coatLength.replaceAll("_", " "),
        }
      : lengthOptions[0]
  );

  const [gender, setGender] = useState(
    method === "PUT"
      ? {
          value: animal.animalMeta.gender,
          label: animal.animalMeta.gender.replaceAll("_", " "),
        }
      : genderOptions[0]
  );

  

  const [age, setAge] = useState(
    method === "PUT"
      ? {
          value: animal.animalMeta.age,
          label: animal.animalMeta.age.replaceAll("_", " "),
        }
      : ageOptions[0]
  );

  const [isMixed, setIsMixed] = useState(
    booleanOptions.filter(
      (option) => option.value === animal.animalMeta.isMixed
    )
  );

  const [primaryBreed, setPrimaryBreed] = useState(
    activeBreedOptions.filter(
      (option) => option.value == animal.animalMeta.primaryBreedId
    )
  );

  const [secondaryBreed, setSecondaryBreed] = useState(
    activeBreedOptions.filter(
      (option) => option.value == animal.animalMeta.secondaryBreedId
    )
  );

  const [isHouseTrained, setIsHouseTrained] = useState(
    animal.animalMeta.housetrained
  );
  const [isGoodWithDogs, setIsGoodWithDogs] = useState(
    animal.animalMeta.isGoodWithDogs
  );
  const [isGoodWithCats, setIsGoodWithCats] = useState(
    animal.animalMeta.isGoodWithCats
  );
  const [isGoodWithKids, setIsGoodWithKids] = useState(
    animal.animalMeta.isGoodWithKids
  );

  const handleSelectorChanges = (type, value) => {
    switch (type) {
      case "species":
        setSpecies(value);
        setPrimaryBreed("");
        setSecondaryBreed("");
        break;
      case "isMixed":
        setIsMixed(value);
        if (value.value === false) {
        setPrimaryBreed("");
        setSecondaryBreed("");
        }
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (species) {
      setActiveBreedOptions(
        species.value === "DOG"
          ? dogBreeds
          : species.value === "CAT"
          ? catBreeds
          : []
      );
    }
  }, [species]);

  return (
    <Form method={method} style={{width: "100%"}}>
      {method === "PUT" && (
        <input hidden name="form-id" defaultValue="animalForm" readOnly />
      )}

      {/*   name, species, gender, adoption status */}
      <Row>
        <Col className="pr-1" md={method === "POST" ? 4 : 5}>
          <div className="form-group">
            <label>Name*</label>
            <Input
              defaultValue={animal.name}
              placeholder="Animal name"
              type="text"
              name="name"
              required
            />
          </div>
        </Col>
        <Col className="px-1" md={method === "POST" ? 2 : 3}>
          <div className="form-group">
            <label>Species*</label>
            <Select
              id="species"
              className="react-select primary"
              classNamePrefix="react-select"
              required
              name="species"
              value={species}
              onChange={(value) => {
                handleSelectorChanges("species", value);
              }}
              options={speciesOptions}
              placeholder="Select"
            />
          </div>
        </Col>
        <Col
          className={method === "POST" ? "px-1" : "pl-1"}
          md={method === "POST" ? 3 : 4}
        >
          <div className="form-group">
            <label>Gender*</label>
            <Select
              id="gender"
              className="react-select primary"
              classNamePrefix="react-select"
              required
              name="gender"
              value={gender}
              onChange={(value) => {
                setGender(value);
              }}
              options={genderOptions}
              placeholder="Select"
            />
          </div>
        </Col>
        {method === "POST" && (
          <Col className="pl-1" md="3">
            <div className="form-group">
              <label>Adoption Status*</label>
              <Select
                id="adoptionStatus"
                className="react-select primary"
                classNamePrefix="react-select"
                required
                name="adoptionStatus"
                value={adoptionStatus}
                onChange={(value) => {
                  setAdoptionStatus(value);
                }}
                options={adoptionStatusOptions}
                placeholder="Select"
              />
            </div>
          </Col>
        )}
      </Row>

      {/* primaryBreed, isMixed, secondaryBreed */}
      <Row>
      <Col className="pr-1" md="3">
          <div className="form-group">
            <label>Age*</label>
            <Select
              id="age"
              className="react-select primary"
              classNamePrefix="react-select"
              required
              name="age"
              value={age}
              onChange={(value) => {
                setAge(value);
              }}
              options={ageOptions}
              placeholder="Select"
            />
          </div>
        </Col>
        <Col className="px-1" md="4">
          <div className="form-group">
            <label>Primary Breed*</label>
            <Select
              id="primaryBreedId"
              className="react-select primary"
              classNamePrefix="react-select"
              name="primaryBreedId"
              required
              value={primaryBreed}
              onChange={(value) => {
                setPrimaryBreed(value);
              }}
              options={activeBreedOptions}
              placeholder="Select"
            />
          </div>
        </Col>
        <Col className="px-1" md="2">
          <div className="form-group">
            <label>Mixed*</label>
            <Select
              id="isMixed"
              className="react-select primary"
              classNamePrefix="react-select"
              required
              name="isMixed"
              value={isMixed}
              onChange={(value) => {
                handleSelectorChanges("isMixed", value);
              }}
              options={booleanOptions}
              placeholder="Select"
            />
          </div>
        </Col>
        <Col className="pl-1" md="3">
          <div className="form-group">
            <label>Secondary Breed (optional)</label>
            <Select
              isDisabled={
                isMixed.value !== undefined
                  ? !isMixed.value
                  : !animal.animalMeta.isMixed
              }
              id="secondaryBreedId"
              className="react-select primary"
              classNamePrefix="react-select"
              name="secondaryBreedId"
              value={secondaryBreed}
              onChange={(value) => {
                setSecondaryBreed(value);
              }}
              options={activeBreedOptions}
              placeholder="Select"
            />
          </div>
        </Col>
      </Row>

      {/* age, birthday, coatLength, size, weight */}
      <Row>
      
        
        <Col className="pr-1" md="2">
          <div className="form-group">
            <label>Coat Length*</label>
            <Select
              id="coatLength"
              className="react-select primary"
              classNamePrefix="react-select"
              required
              name="coatLength"
              value={coatLength}
              onChange={(value) => {
                setCoatLength(value);
              }}
              options={lengthOptions}
              placeholder="Select"
            />
          </div>
        </Col>
        <Col className="px-1" md="3">
          <div className="form-group">
            <label>Color*</label>
            <Input
              defaultValue={animal.animalMeta.color}
              placeholder="Color"
              type="text"
              name="color"
              required
            />
          </div>
        </Col>
        <Col className="px-1" md="2">
          <div className="form-group">
            <label>Size*</label>
            <Select
              id="size"
              className="react-select primary"
              classNamePrefix="react-select"
              required
              name="size"
              value={size}
              onChange={(value) => {
                setSize(value);
              }}
              options={sizeOptions}
              placeholder="Select"
            />
          </div>
        </Col>
        <Col className="px-1" md="3">
          <div className="form-group">
            <label>Weight*</label>
            <Select
              id="weight"
              className="react-select primary"
              classNamePrefix="react-select"
              required
              name="weight"
              value={weight}
              onChange={(value) => {
                setWeight(value);
              }}
              options={weightOptions}
              placeholder="Select"
            />
          </div>
        </Col>
        <Col className="pl-1" md="2">
          <div className="form-group">
            <label>Birthday (optional)</label>
            <Datetime
              value={birthDate ? birthDate : ""}
              onChange={(e) => {
                setBirthDate(e._d);
              }}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              inputProps={{
                name: "birthDate",
              }}
              renderInput={(props) => {
                return (
                  <input {...props} value={birthDate ? props.value : ""} />
                );
              }}
            />
          </div>
        </Col>
      </Row>

      {/* houseTrained, goodWithCat, goodWithDogs, goodWithKids */}
      <Row style={{ marginTop: "10px" }}>
        <Col className="pr-1" md="3">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="housetrained"
                checked={isHouseTrained}
                onChange={() => {
                  setIsHouseTrained((prevState) => !prevState);
                }}
                value={isHouseTrained}
              />
              <span className="form-check-sign" />
              Housetrained
            </Label>
          </FormGroup>
        </Col>
        <Col className="pr-1" md="3">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="isGoodWithDogs"
                checked={isGoodWithDogs}
                onChange={() => {
                  setIsGoodWithDogs((prevState) => !prevState);
                }}
                value={isGoodWithDogs}
              />
              <span className="form-check-sign" />
              Good With Dogs
            </Label>
          </FormGroup>
        </Col>
        <Col className="pr-1" md="3">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="isGoodWithCats"
                checked={isGoodWithCats}
                onChange={() => {
                  setIsGoodWithCats((prevState) => !prevState);
                }}
                value={isGoodWithCats}
              />
              <span className="form-check-sign" />
              Good With Cats
            </Label>
          </FormGroup>
        </Col>
        <Col className="pr-1" md="3">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="isGoodWithKids"
                checked={isGoodWithKids}
                onChange={() => {
                  setIsGoodWithKids((prevState) => !prevState);
                }}
                value={isGoodWithKids}
              />
              <span className="form-check-sign" />
              Good With Kids
            </Label>
          </FormGroup>
        </Col>
      </Row>

      {/* health  */}
      <Row>
        <Col md="12">
          <div className="form-group">
            <label>Health*</label>
            <Input
              defaultValue={animal.animalMeta.health}
              placeholder="Health"
              type="text"
              name="health"
              required
            />
          </div>
        </Col>
      </Row>

      {/* temperament  */}
      <Row>
        <Col md="12">
          <div className="form-group">
            <label>Temperament*</label>
            <Input
              defaultValue={animal.animalMeta.temperament}
              placeholder="Temperament"
              type="text"
              name="temperament"
              required
            />
          </div>
        </Col>
      </Row>

      {/* description */}
      <Row>
        <Col md="12">
          <div className="form-group">
            <label>Animal description*</label>
            <Input
              defaultValue={animal.animalMeta.description}
              className="textarea"
              type="textarea"
              cols="80"
              rows="4"
              placeholder="Animal Description"
              name="description"
              required
            />
          </div>
        </Col>
      </Row>

      {/*adoptionFee, intakeDate, intakeSource  */}
      <Row>
        <Col md="4">
          <div className="form-group">
            <label>Adoption Fee*</label>
            <Input
              defaultValue={animal.animalMeta.adoptionFee}
              placeholder="adoptionFee"
              type="text"
              name="adoptionFee"
              required
            />
          </div>
        </Col>
        <Col className="px-1" md="4">
          <div className="form-group">
            <label>Intake Date*</label>
            <Datetime
              value={intakeDate ? intakeDate : ""}
              onChange={(e) => {
                setIntakeDate(e._d);
                console.log(intakeDate);
              }}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              inputProps={{
                name: "intakeDate",
              }}
              renderInput={(props) => {
                return (
                  <input {...props} value={intakeDate ? props.value : ""} />
                );
              }}
            />
          </div>
        </Col>

        <Col className="pl-1" md="4">
          <div className="form-group">
            <label>Intake Source*</label>
            <Input
              defaultValue={animal.animalMeta.intakeSource}
              placeholder="intakeSource"
              type="text"
              name="intakeSource"
              required
            />
          </div>
        </Col>
      </Row>
      
      {/* intakeDetail */}
      <Row>
        <Col md="12">
          <div className="form-group">
            <label>Intake Detail*</label>
            <Input
              defaultValue={animal.animalMeta.intakeDetail}
              className="textarea"
              type="textarea"
              cols="80"
              rows="4"
              placeholder="Animal Intake Detail"
              name="intakeDetail"
              required
            />
          </div>
        </Col>
      </Row>

      <Button color="fetched-solid" style={{ width: "100%" }}>
        {method==="PUT" ? "Update" : "Submit"}
      </Button>
    </Form>
  );
};

export default AnimalForm;
