import  FetchedDog  from '../assets/images/FetchedDog.png';
import classes from './ContactBox.module.css';
import {Link} from 'react-router-dom';

const ContactBox = () => {
    return (
        <div className={classes.container} id="contactBox">
            <div className={classes.imgWrapper}>    
                <img src={FetchedDog} />
            </div>
            <div className={classes.contactWrapper}>
                <h2>Want to chat?</h2>
                <Link to={'/page/contact'}>Contact us</Link>
            </div>
        </div>
    )
}

export default ContactBox;