import { useState } from "react";
import {
  useLoaderData,
  redirect,
  Form,
  useParams,
  useSubmit,
} from "react-router-dom";
import { Card, CardBody, Row, Col, Input, Button } from "reactstrap";
import { user, house, phone, mail, profile, globe } from "../../../assets/svg/svgs";
import classes from "./AdminRescue.module.css";
import ReactBSAlert from "react-bootstrap-sweetalert";

const AdminDashboardRescuePage = () => {
  const [alert, setAlert] = useState(null);
  const { rescueId } = useParams();
  const submit = useSubmit();
  const data = useLoaderData();
  console.log(data);

  const deleteRescueWarning = (e) => {
    e.preventDefault();
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => handleClick("delete")}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        This action is non-reversible!
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const handleClick = (type) => {
    let formData = new FormData();
    let actionPath = `/admin/rescues/${rescueId}`;
    switch (type) {
      case "delete":
        formData.append("form-id", "delete");
        formData.append("isDeleted", true);
        break;
      case "enableStatus":
        formData.append("form-id", "updateEnabled");
        formData.append("isEnabled", !data.isEnabled);
        break;
      default:
        break;
    }
    submit(formData, { method: "PUT", action: actionPath });
  };

  return (
    <div>
      {alert}
      <Row>
        <Col md="6">
          <Card>
            <CardBody>
              <div className={classes["rescueData-container"]}>
                <h4 className="title">{data.legalRescueName}</h4>
                <p>
                  {user}
                  <span>{data.rescueMeta.contactName}</span>
                </p>
                <p>
                  {profile}
                  <span>
                    Member since {data.rescueMeta.createdAt.substring(0, 10)}
                  </span>
                </p>
                <p>
                  {house}
                  <span>
                    {data.rescueMeta.streetAddress1}, {data.rescueMeta.city},{" "}
                    {data.rescueMeta.state} {data.rescueMeta.zipCode}
                  </span>
                </p>
                <p>
                  {phone}
                  <span>{data.rescueMeta.phone}</span>
                </p>
                <p>
                  {mail}
                  <span>{data.user.email}</span>
                </p>
                <p>
                    {globe}
                    <span>{data.rescueMeta.website}</span>
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          {/* <h5>Update Rescue Data</h5> */}
          <Card>
            <CardBody>
              <Form method="PUT">
                <input hidden name="form-id" defaultValue="updateData" />
                <Row style={{ alignItems: "flex-end" }}>
                  <Col md="2" className="pr-1">
                    <div className="form-group">
                      <label>Rescue ID</label>
                      <Input
                        type="text"
                        placeholder="Rescue ID"
                        name="id"
                        defaultValue={data.id}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md="7" className="px-1">
                    <div className="form-group">
                      <label>Legal Rescue Name</label>
                      <Input
                        type="text"
                        placeholder="Legal Rescue Name"
                        name="legalRescueName"
                        defaultValue={data.legalRescueName}
                        required
                        disabled={data.isDeleted ? true : false}
                      />
                    </div>
                  </Col>
                  <Col md="3" className="pl-1">
                    <div className="form-group">
                      <label>EIN</label>
                      <Input
                        type="text"
                        placeholder="EIN"
                        name="ein"
                        defaultValue={data.ein}
                        required
                        disabled={data.isDeleted ? true : false}
                      />
                    </div>
                  </Col>
                </Row>
                {!data.isDeleted && (
                  <Button color="fetched-solid">Update Rescue</Button>
                )}
              </Form>
            </CardBody>
          </Card>
          {!data.isDeleted && (
            <div className="flex justify-content flex-end">
              
              <Button
                color={data.isEnabled ? "fetched-solid" : "fetched-outline"}
                onClick={() => handleClick("enableStatus")}
              >
                {data.isEnabled ? "Approved" : "Not Approved"}
              </Button>
              <Button color="danger" onClick={deleteRescueWarning}>
                Delete Rescue
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboardRescuePage;

export const multiAction = async ({ request, params }) => {
  const formData = await request.formData();
  const rescueData = Object.fromEntries(formData);
  const formId = formData.get("form-id");
  let url = `${process.env.REACT_APP_API_ROOT}/admin/rescues/${params.rescueId}`;
  let status = "";

  switch (formId) {
    case "updateData":
      const updateDataResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rescueData),
      });
      status = updateDataResponse.status;
      if (!updateDataResponse.ok) {
        console.log("status: " + status);
        return updateDataResponse;
      }
      const updatedData = await updateDataResponse.json();
      return updatedData;

    case "delete":
      url = `${url}/delete`;
      const deleteDataResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rescueData),
      });
      status = deleteDataResponse.status;
      if (!deleteDataResponse.ok) {
        console.log("status: " + status);
        return deleteDataResponse;
      }
      const deletedData = await deleteDataResponse.json();
      return deletedData;

    case "updateEnabled":
      url = `${url}/enableStatus`;
      const updateStatusResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rescueData),
      });
      status = updateStatusResponse.status;
      if (!updateStatusResponse.ok) {
        console.log("status: " + status);
        return updateStatusResponse;
      }
      const statusData = await updateStatusResponse.json();
      return statusData;
    default:
      console.log("Not a valid form id");
  }
};

export const loader = async ({ request, params }) => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/admin/rescues/${params.rescueId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/admin");
  }
};
