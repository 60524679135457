import { useEffect, useState, useRef } from "react";
import { Form, useNavigation } from "react-router-dom";
import { Button, Input, Row, Col, FormGroup, Label } from "reactstrap";

const QuestionForm = ({ question }) => {
  const [isPublic, setIsPublic] = useState(false);
  const formRef = useRef();
  
  const handlingToggle = (e) => {
    setIsPublic((prevState) => !prevState);
  };
  const navigation = useNavigation();
  let submissionState = "";

  switch (navigation.state) {
    case "submitting":
      submissionState = "Saving...";
      break;
    default:
      submissionState = "Save";
  }
  useEffect(() => {
    setIsPublic(question.isPublic);
  }, []);

  return (
    <Form method="put" ref={formRef}>
      <Row>
        <Col md="12">
          <div className="form-group">
            <Input
              defaultValue={question.answer == null ? "" : question.answer}
              className="textarea"
              type="textarea"
              cols="80"
              rows="4"
              placeholder="Answer"
              name="answer"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {!isPublic && <input hidden name="isPublic" value={false} readOnly />}
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="isPublic"
                checked={isPublic}
                onChange={handlingToggle}
                value={isPublic}
              />
              <span className="form-check-sign" />
              Publish this question
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <div className="flex justify-content flex-end">
        <Button color="fetched-solid">{submissionState}</Button>
      </div>
    </Form>
  );
};

export default QuestionForm;

export const action = async({request, params}) => {
    const formData = await request.formData();
    const questionData = Object.fromEntries(formData);
    let url = `${process.env.REACT_APP_API_ROOT}/rescues/rescue/questions/${params.questionId}`;
    let status = "";

    const response = await fetch(url,
        {
            method: request.method,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(questionData),
        }
    );

    status = response.status;
    if (!response.ok) {
      return response;
    }
    const data = await response.json();
    return data;

}
