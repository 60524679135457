import HomePage from "./pages/Home";
import RootLayout from "./layouts/Root";
import PageRootLayout from "./layouts/PageRoot";
import AuthRootLayout from "./layouts/AuthRoot";
import UserDashboardRootLayout from "./layouts/UserDashboardRoot";
import RescueDashboardRootLayout from "./layouts/RescueDashboardRoot";
import AdminDashboardRootLayout from "./layouts/AdminDashboardRoot";
import RescuesPage, { loader as rescuePageLoader, action as rescueAnimalLikeAction } from "./pages/Rescues";
import AnimalsPage, { loader as animalPageLoader } from "./pages/Animals";
import SearchResultPage, { loader as searchPageLoader, multiActions as searchPageActions } from "./pages/SearchResult";
import ContactPage from "./pages/Contact";
import LoginPage, { action as loginAction } from "./pages/Login";
import SignupPage, {action as signupAction } from "./pages/Signup";
import RegisterPage, { action as registerAction } from "./pages/Register";
import ApplicationPage, {action as applicationAction, loader as applicationPageLoader} from "./pages/Application";
import ErrorPage from "./pages/Error";
import AboutPage from "./pages/About";
import TermsPage from "./pages/Terms";
import CookiesPage from "./pages/Cookies";
import {
  RescueDashboardPage,
  RescueDashboardAnimalsPage,
  RescueDashboardProfilePage,
  RescueDashboardApplicationsPage,
  RescueDashboardEventsPage,
  RescueDashboardAnimalPage,
  RescueDashboardApplicationPage,
  RescueDashboardApplicantsPage,
  RescueDashboardCustomsPage,
  RescueDashboardQuestionsPage,
  RescueDashboardApplicantPage,
  RescueDashboardEventPage,
  RescueDashboardCustomPage,
  RescueDashboardQuestionPage,
  RescueDashboardArchivedDashboardPage,
  rescueDashboardLoader,
  rescueProfileLoader,
  rescueAnimalLoader,
  rescueAnimalsLoader,
  rescueApplicationsLoader,
  rescueApplicationLoader,
  rescueEventsLoader,
  rescueApplicantsLoader,
  rescueCustomQuestionsLoader,
  rescueQuestionsLoader,
  rescueApplicantLoader,
  rescueEventLoader,
  rescueCustomQuestionLoader,
  rescueQuestionLoader,
  rescueNewAnimalLoader,
  rescueAnimalActions,
  RescueDashboardNewAnimalPage,
  rescueApplicantActions,
  rescueApplicationActions,
  rescueNewAnimalAction,
  rescueArchivedAnimalsLoader,
  rescueProfileActions
} from "./pages/dashboard/rescue";
import {
  UserDashboardPage,
  UserDashboardProfilePage,
  UserDashboardApplicationsPage,
  UserDashboardFavoritesPage,
  UserDashboardQuestionsPage,
  userDashboardLoader,
  userApplicationsLoader,
  userQuestionsLoader,
  userFavoritesLoader,
  userProfileLoader,
  userFavoritesAction,
  userProfileActions
} from "./pages/dashboard/user";
import {
  AdminDashboardPage,
  AdminDashboardRescuesPage,
  AdminDashboardRescuePage,
  AdminDashboardNewRescuePage,
  adminDashboardLoader,
  adminRescuesLoader,
  adminRescueLoader,
  adminNewRescueAction,
  adminRescueActions
} from "./pages/dashboard/admin";
import { checkRoleLoader, tokenLoader , checkUserLoader} from "./utils/auth";
import { action as logoutAction } from "./pages/Logout";
import { action as rescueEventAction } from "./components/dashboard/EventForm";
import { action as rescueCustomQuestionAction } from "./components/dashboard/CustomForm";
import { action as rescueAnimalQuestionAction } from "./components/dashboard/QuestionForm";

const routes = [
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: "root",
    loader: checkUserLoader,
    children: [
      { 
        index: true, 
        element: <HomePage /> 
      },
    ],
  },
  {
    path: "/page",
    element: <PageRootLayout />,
    errorElement: <ErrorPage />,
    id:"pageRoot",
    loader: checkUserLoader,
    children: [
      {
        path: "/page/rescues/:rescueId",
        loader: rescuePageLoader,
        action: rescueAnimalLikeAction,
        element: <RescuesPage /> 
      },
      {
        path: "/page/animals/:animalId",
        loader: animalPageLoader,
        element: <AnimalsPage /> 
      },
      {
        path: "/page/results/:species/:zipcode",
        loader: searchPageLoader,
        action: searchPageActions,
        element: <SearchResultPage />
      },
      {
        path: "/page/application/:rescueId/:animalId",
        loader: applicationPageLoader,
        action: applicationAction,
        element: <ApplicationPage />,
      },
      {
        path:'/page/contact',
        element: <ContactPage />
      },
      {
        path:'/page/about',
        element: <AboutPage />
      },
      {
        path:'/page/termsAndCondition',
        element: <TermsPage />
      },
      {
        path:'/page/cookiesPolicy',
        element: <CookiesPage />
      }
    ],
  },
  {
    path: "/auth",
    element: <AuthRootLayout />,
    errorElement: <ErrorPage />,
    id: "authRoot",
    loader: checkUserLoader,
    children: [
      {
        path: "/auth/login",
        element: <LoginPage />,
        action: loginAction,
      },
      {
        path: "/auth/signup",
        element: <SignupPage />,
        action: signupAction,
      },
      {
        path: "/auth/register",
        element: <RegisterPage />,
        action: registerAction,
      },
      {
        path: "/auth/logout",
        action: logoutAction,
      },
    ],
  },
  {
    path: "/user",
    element: <UserDashboardRootLayout />,
    id: "userRoot",
    loader: tokenLoader,
    children: [
      {
        index: true,
        element: <UserDashboardPage />,
        loader: userDashboardLoader,
      },
      {
        path: "/user/profile",
        element: <UserDashboardProfilePage />,
        loader: userProfileLoader,
        action: userProfileActions
      },
      {
        path: "/user/applications",
        element: <UserDashboardApplicationsPage />,
        loader: userApplicationsLoader,
      },
      {
        path: "/user/favorites",
        element: <UserDashboardFavoritesPage />,
        loader: userFavoritesLoader,
        action: userFavoritesAction,
      },
      {
        path: "/user/questions",
        element: <UserDashboardQuestionsPage />,
        loader: userQuestionsLoader,
      },
    ],
  },
  {
    path: "/rescue",
    element: <RescueDashboardRootLayout />,
    id: "rescueRoot",
    loader: tokenLoader,
    children: [
      {
        index: true,
        element: <RescueDashboardPage />,
        loader: rescueDashboardLoader,
      },
      {
        path: "/rescue/profile",
        element: <RescueDashboardProfilePage />,
        loader: rescueProfileLoader,
        action: rescueProfileActions
      },
      {
        path: "/rescue/animals",
        action: rescueAnimalActions,
        children: [
          {
            index: true,
            element: <RescueDashboardAnimalsPage />,
            loader: rescueAnimalsLoader,
          },
          {
            path: "/rescue/animals/:animalId",
            element: <RescueDashboardAnimalPage />,
            loader: rescueAnimalLoader,
            action: rescueAnimalActions,
          },
          {
            path: "/rescue/animals/new",
            element: <RescueDashboardNewAnimalPage />,
            loader: rescueNewAnimalLoader,
            action: rescueNewAnimalAction,
          },
        ],
      },
      {
        path: "/rescue/applications",
        children: [
          {
            index: true,
            element: <RescueDashboardApplicationsPage />,
            loader: rescueApplicationsLoader,
          },
          {
            path: "/rescue/applications/:applicationId",
            element: <RescueDashboardApplicationPage />,
            loader: rescueApplicationLoader,
            action: rescueApplicationActions,
          },
        ],
      },
      {
        path: "/rescue/events",
        children: [
          {
            index: true,
            element: <RescueDashboardEventsPage />,
            loader: rescueEventsLoader,
            action: rescueEventAction,
          },
          {
            path: "/rescue/events/:eventId",
            element: <RescueDashboardEventPage />,
            loader: rescueEventLoader,
            action: rescueEventAction,
          },
        ],
      },
      {
        path: "/rescue/customs",
        children: [
          {
            index: true,
            element: <RescueDashboardCustomsPage />,
            loader: rescueCustomQuestionsLoader,
            action: rescueCustomQuestionAction,
          },
          {
            path: "/rescue/customs/:customId",
            element: <RescueDashboardCustomPage />,
            loader: rescueCustomQuestionLoader,
            action: rescueCustomQuestionAction,
          },
        ],
      },
      {
        path: "/rescue/questions",
        children: [
          {
            index: true,
            element: <RescueDashboardQuestionsPage />,
            loader: rescueQuestionsLoader,
          },
          {
            path: "/rescue/questions/:questionId",
            element: <RescueDashboardQuestionPage />,
            loader: rescueQuestionLoader,
            action: rescueAnimalQuestionAction,
          },
        ],
      },
      {
        path: "/rescue/applicants",
        action: rescueApplicantActions,
        children: [
          {
            index: true,
            element: <RescueDashboardApplicantsPage />,
            loader: rescueApplicantsLoader,
          },
          {
            path: "/rescue/applicants/:applicantId",
            element: <RescueDashboardApplicantPage />,
            loader: rescueApplicantLoader,
            action: rescueApplicantActions,
          },
        ],
      },
      {
        path: "/rescue/archivedAnimals",
        element: <RescueDashboardArchivedDashboardPage />,
        loader: rescueArchivedAnimalsLoader,
      },
      {
        path: "/rescue/logout",
        action: logoutAction,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminDashboardRootLayout />,
    id: "adminRoot",
    loader: tokenLoader,
    children: [
      {
        index: true,
        element: <AdminDashboardPage />,
        loader: adminDashboardLoader,
      },
      {
        path: "/admin/rescues",
        children: [
          {
            index: true,
            element: <AdminDashboardRescuesPage />,
            loader: adminRescuesLoader,
          },
          {
            path: "/admin/rescues/:rescueId",
            element: <AdminDashboardRescuePage />,
            loader: adminRescueLoader,
            action: adminRescueActions
          },
          {
            path: "/admin/rescues/new",
            element: <AdminDashboardNewRescuePage />,
            action: adminNewRescueAction
          },
        ],
      },
    ],
  },
];

export default routes;
