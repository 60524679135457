import { useSubmit, Link } from "react-router-dom";
import classes from "./AnimalCard.module.css";
import dog from "../../assets/images/dog.png";
import cat from "../../assets/images/cat.png";
import baby from "../../assets/images/baby.png";
import { redHeartFilled, redHeartOutlined, dot } from "../../assets/svg/svgs";

const AnimalCardItemAlt = ({ animal, userId, likes, action, updateAction, searchPage }) => {
  const animalData = {
    photo:
      searchPage && animal.img_url
        ? animal.img_url
        : !searchPage && animal.photos.length > 0
        ? animal.photos[0].img_url
        : null,
    gender: searchPage ? animal.gender : animal.animalMeta.gender,
    size: searchPage ? animal.size : animal.animalMeta.size,
    age: searchPage ? animal.age : animal.animalMeta.age,
    breed: searchPage ? animal.breed : animal.animalMeta.primaryBreed.name,
    isMixed: searchPage ? animal.isMixed : animal.animalMeta.isMixed,
    isGoodWithDogs: searchPage
      ? animal.isGoodWithDogs
      : animal.animalMeta.isGoodWithDogs,
    isGoodWithCats: searchPage
      ? animal.isGoodWithCats
      : animal.animalMeta.isGoodWithCats,
    isGoodWithKids: searchPage
      ? animal.isGoodWithKids
      : animal.animalMeta.isGoodWithKids,
  };

  const submit = useSubmit();
  let like = false;

  if (!searchPage && userId) {
    for (let i = 0; i < animal.likes.length; i++) {
      if (animal.likes[i].userId === userId) {
        like = true;
        break;
      }
    }
  }

  if(searchPage && userId) {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].animalId === animal.id) {
        like = true;
        break;
      }
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("animalId", animal.id);
    if(searchPage) formData.append("formId", "like");
    if (like) {
      submit(formData, { method: "DELETE", action: action });
      updateAction(animal.id, "unlike"); 
    } else {
      submit(formData, { method: "POST", action: action });
      updateAction(animal.id, "like");
    }
  };

  return (
    <Link to={`/page/animals/${animal.id}`}>
      <div className={`${classes.cardAlt} ${classes.md}`}>
        <div className={classes.iconsWrapperAlt}>
          {animalData.isGoodWithDogs && <img src={dog} />}
          {animalData.isGoodWithCats && <img src={cat} />}
          {animalData.isGoodWithKids && <img src={baby} />}
        </div>

        <div
          className={classes.cardImageWrapper}
          style={
            animalData.photo
              ? {
                  backgroundImage: `url(${process.env.REACT_APP_API_BASE_ROOT}/${animalData.photo})`,
                }
              : null
          }
        ></div>
        <div className={classes.cardDescriptionWrapper}>
          {userId && (
            <div className={classes.heartWrapper} onClick={handleClick}>
              {like && redHeartFilled}
              {!like && redHeartOutlined}
            </div>
          )}
          <div className={`${classes.titleAlt} ${classes.mdTitleAlt}`}>
            {animal.name}
          </div>
          <div className={`${classes.subTitleAlt} ${classes.mdSubTitle}`}>
            {animalData.breed} {animalData.isMixed ? "Mix" : ""}
          </div>
          <div className={classes.cardAltBottomWrapper}>
            <div className={`${classes.textAlt} ${classes.mdText}`}>
              {animalData.size.toLowerCase()}{" "}
              {animalData.age.toLowerCase().replaceAll("_", " ")}
            </div>
            <div
              className={
                animalData.gender === "FEMALE"
                  ? `${classes.genderF} ${classes.mdText}`
                  : `${classes.genderM} ${classes.mdText}`
              }
            >
              {animalData.gender.toLowerCase()}
            </div>
          </div>

          {/* <div
          className={classes.circle}
          style={{
            backgroundColor: gender === "F" ? "rgb(251, 124, 124)" : "#56CCF2",
          }}
        >
          {gender}
        </div> */}
        </div>
      </div>
    </Link>
  );
};

export default AnimalCardItemAlt;
