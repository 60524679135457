import {useState} from "react";
import { Link, redirect } from "react-router-dom";
import classes from "./Home.module.css";
import {
  fe_collar,
  fe_spotlightPaw,
  fe_seniorSpotlight,
  fe_ballRed,
  fe_bowl,
  fe_bone,
  fe_cat,
  fe_dog
} from "../assets/svg/svgs";
import illustration from "../assets/images/fetchedIllustration1.png";
import dog1 from "../assets/images/beautiful-pet-portrait-dog.png";
import adopt1 from "../assets/images/adopt1.png";
import adopt2 from "../assets/images/adopt2.png";
import adopt3 from "../assets/images/adopt3.png";
import adopt4 from "../assets/images/adopt4.png";
import Footer from '../components/Footer';

const HomePage = () => {
  const [species, setSpecies] = useState("DOG");
  const [zipCode, setZipcode] = useState("");

  const data = {
    name: "Sweetie",
    img_url: "uploads/images/1/1691743857832_4AC27830-3400-4AD2-8991-9D7E3841D793.jpg",
    size: "MEDIUM",
    gender: "FEMALE",
    breed: "Golden Retriever",
    city: "VORHEE",
    state: "NJ",
    species: "DOG",
    isGoodWithKids: true,
    isGoodWithCats: true,
    isGoodWithDogs: true,
    url: "/page/animals/3"
  }

  const getTemperament = (kids, cats, dogs) => {
    if(!kids && !cats && !dogs) return "";
    if(kids && cats && dogs) {
      if(data.species === "DOG") return "I'm good with kids, cats and other dogs";
      if(data.species === "CAT") return "I'm good with kids, dogs and other cats";
      else return "";
    }
    let str = "I am good with";
    let count = 0
    if(kids) {
      str += " kids";
      count++;
    }
    if(data.species === "DOG") {
      if(cats) {
        str = count === 0 ? str + " cats" : " and cats";
        count++;
      }
      if(dogs) {
        str = count === 0 ? str + " other dogs" : " and other dogs";
        count++;
      }
    } 
    if(data.species === "CAT") {
      if(dogs) {
        str = count === 0 ? str + " dogs" : " and dogs";
        count++
      }
      if(cats) {
        str = count === 0 ? str + " other cats" : " and other cats";
      }
    }
    return str;
  }

  const handleChange = e => {
    if(!isNaN(e.target.value)) {
      setZipcode(e.target.value);
    }
    
  }

  const handleEnterSubmit = e => {
    if(e.keyCode === 13) {
      e.preventDefault();
      redirect(`/page/results/${species}/${zipCode}`);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.heroContainer}>
          <div className={classes.heroCTABox}>
            <h1>Ready to find your new best friend?</h1>
            <h3>
              Looking to promote your rescue pets on Fetched?
              <br />
              <Link to="/auth/register">Sign up</Link> for a free rescue account
              today.
            </h3>
           
              {/* <input type="text" placeholder="Dog / Cat" /> */}
              <div className={classes.switchButtonWrapper}>
              <button 
                className={species === "DOG" ? classes.switchActive : null}
                onClick={() => setSpecies("DOG")}
              >
                  {fe_dog}
                </button>
                <button 
                  className={species === "CAT" ? classes.switchActive : null}
                  onClick={() => setSpecies("CAT")}
                >
                  {fe_cat}
                </button>
              </div> 
              <form>
              <input 
                type="text" 
                placeholder="Enter Zip Code" 
                onChange={handleChange}
                value={zipCode}
                maxLength="5"
                onKeyDown={handleEnterSubmit}
              />
              <Link to={`/page/results/${species}/${zipCode}`} className={classes.button}>Find my best friend!</Link>
              
            </form>
            <div className={classes.collar}>{fe_collar}</div>
          </div>
        </div>
      </div>
      <section id="mission" className={classes["section-mission"]}>
        <img src={illustration} />
        <div>
          <div className={classes["section-container"]}>
            <h2>Our Mission</h2>
            <h1>
              All animals deserve a second chance
              <br />
              (and third! and fourth!<span> &#10084; </span>) at finding a
              forever home.
            </h1>
            <p>
              We are Fetched, the best kind of matchmaking service. Whether it’s
              you that could use a companion, or your pet at home, we can help
              you find the perfect match.
            </p>

            <p>
              So this is your sign to make that first move, to throw that ball,
              or fling that frisbee, and maybe just maybe it will get….fetched.
            </p>
          </div>
        </div>
      </section>
      <section id="spotlight" className={classes["section-spotlight"]}>
        <div className={classes.redBall}>{fe_ballRed}</div>
        <div className={classes.pawBg}>{fe_spotlightPaw}</div>
        <div className={classes["section-container"]}>
          <div className={classes["spotlight-wrapper"]}>
            <div className={classes.spotlightLeft}>
              <div>
                {fe_seniorSpotlight}
                <div>
                  {/* <img src="http://localhost:8080/uploads/images/1/1691747418315_pexels-ilargian-faus-1629781.jpg" /> */}
                  <img src={`${process.env.REACT_APP_API_BASE_ROOT}/${data.img_url}`} />
                  <div className={classes.seniorSpotlight}></div>
                </div>
              </div>
            </div>
            <div className={classes.spotlightRight}>
              <h1>
                Hi there,
                <br />
                I'm {data.name}
              </h1>
              <p>
                I’m a {data.size.toLowerCase()} {data.breed} {data.gender.toLowerCase()} senior currently living in {data.city}, {data.state}. {getTemperament(data.isGoodWithKids, data.isGoodWithCats, data.isGoodWithDogs)}.
              </p>
              <span>
                Pets featured in our Senior Spotlight are entirely random and
                change daily. Senior status varies by age and breed; however for
                the purpose of our Senior Spotlight, pets 8+ years are featured.
              </span>

              <Link to={data.url} className={classes.button}>Learn more</Link>
            </div>
          </div>
        </div>
      </section>
      <section id="forAdopters" className={classes["section-forAdopters"]}>
        <div className={classes["section-container"]}>
          <div className={classes.adoptersTop}>
            <div>
              <h2>Fetched for Adopters</h2>
              <div>
                <h1>
                  Looking <br />
                  to adopt?
                </h1>
                <p>
                  Every animal on our website is available for adoption from a
                  verified 501(c)(3) animal nonprofit. We work closely with our
                  rescues to ensure you can find your perfect match with
                  confidence and ease 
                </p>
              </div>
            </div>
            <div>
              <img src={dog1} />
            </div>
          </div>
          <div className={classes.adoptersBottom}>
            <div>
              <div>
                <img src={adopt1} />
              </div>
              <div>
                <h2>Search</h2>
                <p>Search through the rescue pets available in your area by location or breed and you’re already on your way to finding your next best friend!</p>
              </div>
            </div>
            <div>
              <div>
                <img src={adopt2} />
              </div>
              <div>
                <h2>Apply online</h2>
                <p>Fill out an online application with one of our featured rescues and get automatic updates on your application.</p>
              </div>
            </div>
            <div>
              <div>
                <img src={adopt3} />
              </div>
              <div>
                <h2>Meet</h2>
                <p>If your application is a good match you’ll hear directly from the rescue to set up a meet and greet.</p>
              </div>
            </div>
            <div>
              <div>
                <img src={adopt4} />
              </div>
              <div>
                <h2>Adopt</h2>
                <p>Work with the rescue to bring your new best friend home and start a happy new life together.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="forRescues" className={classes["section-forRescues"]}>
        <div className={classes["section-container"]}>

          <div className={classes.rescuesTop}>
              
              <h2>Fetched for Rescues</h2>
              <h1>Promoting Your Rescue and <br />
              Managing Your Rescue Animals All in One Place</h1>
              <p>
              Animal rescue is a full time job, that’s why we offer FREE easy-to-use software for verified animal welfare nonprofits to help manage every aspect of the day-to-day from application tracking to pet management
              </p>
              <Link to="/auth/register">Sign up for a free rescue account today</Link>
              <div className={classes.bowl}>{fe_bowl}</div>
              <div className={classes.bone}>{fe_bone}</div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default HomePage;
