export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// convert from YYYY-MM-DD to Month Day, Year
export const dateStrConversion = (str) => {
  // 0 - year, 1 - month, 2 day
  const strArr = str.split("-");
  return `${months[strArr[1] - 1]} ${strArr[2]}, ${strArr[0]}`;
};

// converting from 1:00 AM to AM_01_00
export const convertingTimeStringForPost = (str) => {
  const strArr = str.split(" ");
  const timeArr = [strArr[1], ...strArr[0].split(":")];
  return `${timeArr[0]}_${
    timeArr[1].length < 2 ? "0" + timeArr[1] : timeArr[1]
  }_${timeArr[2]}`;
};

// converting from AM_01_00 to 1:00 AM
export const reverseConvertingTimeStringToDateObject = (str) => {
  const strArr = str.split("_");
  const amPm = strArr[0];
  let hour = parseInt(strArr[1]);
  const minute = parseInt(strArr[2]);

  if (amPm === "AM" && hour == 12) {
    hour = 0;
  }
  if (amPm === "PM" && hour != 12) {
    hour += 12;
  }
  const newDate = new Date();
  newDate.setHours(hour);
  newDate.setMinutes(minute);
  return newDate;
};

// modifying eventdata before posting / updating
export const modifyingEventData = (eventData) => {
  eventData.isPhysical = eventData.isPhysical === "physical";
  eventData.startTime = convertingTimeStringForPost(eventData.startTime);
  eventData.endTime =
    eventData.endTime !== ""
      ? convertingTimeStringForPost(eventData.endTime)
      : "";
  return eventData;
};

// date obj to string
export const handleTimeZoneOffset = (dateObj) => {
  // get offset from local timezone to utc
  const offset = dateObj.getTimezoneOffset();
  // set offset to mod date object for utc string conversion
  const modDate = new Date(dateObj.getTime() - offset * 60 * 1000);
  // convert mod date object (with timezone differences already implemented)
  // to string and extract date string only
  let dateStr = modDate.toISOString().split("T")[0];
  return dateStrConversion(dateStr);
};

// date obj to string
export const handleCurrentTimeZoneOffset = (dateObj) => {
  const now = new Date();
  const offset = now.getTimezoneOffset();
  let utcDay = dateObj.getUTCDate();
  let utcMonth = months[dateObj.getUTCMonth()];
  let utcYear = dateObj.getUTCFullYear();
  return `${utcMonth} ${utcDay}, ${utcYear}`;
};

