import { useLoaderData, redirect } from "react-router-dom";
import UserApplicationTable from "../../../components/dashboard/UserApplicationTable";

const UserDashboardApplicationsPage = () => {
    const data = useLoaderData();
    console.log(data);
    const defaultPageSize = 10;

    return(
        <div>
            <UserApplicationTable applications={data.applications} title="My Applications" pageSize={defaultPageSize}/>
        </div>
    )
}

export default UserDashboardApplicationsPage;

export const loader = async () => {
    let status = "";
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/users/user/applications`, 
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });
      status = response.status;
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(`Message : ${error.message}, Status : ${status}`);
      return redirect("/");
    }
  };
  