import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Tag from "../../ui/Tag";
import classes from "./ApplicationMeta.module.css";
import {
  mail,
  phone,
  user as userSVG,
  house,
  checkedCircle,
  crossedCircle,
} from "../../assets/svg/svgs";

const ApplicationMeta = ({ user, applicationMeta, rescueMeta }) => {
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader className={classes.cardHeader}>
              <h5 className="title">
                <Link to={`/rescue/applicants/${user.id}`}>
                  {userSVG}
                  {user.username}
                </Link>{" "}
                {/* {applicationMeta.age >= rescueMeta.adoptionAgeReq && (
                  <span style={{ color: "rgb(107, 208, 152)" }}>
                    {" "}
                    {checkedCircle} Over {rescueMeta.adoptionAgeReq}
                  </span>
                )}
                {applicationMeta.age < rescueMeta.adoptionAgeReq && (
                  <span style={{ color: "rgb(239, 129, 87)" }}>
                    {" "}
                    {crossedCircle} Not over {rescueMeta.adoptionAgeReq}
                  </span>
                )} */}
                {applicationMeta.age && (
                  <span style={{ color: "rgb(107, 208, 152)" }}>
                    {" "}
                    {checkedCircle} Over {rescueMeta.adoptionAgeReq}
                  </span>
                )}
                {!applicationMeta.age && (
                  <span style={{ color: "rgb(239, 129, 87)" }}>
                    {" "}
                    {crossedCircle} Not over {rescueMeta.adoptionAgeReq}
                  </span>
                )}
              </h5>
            </CardHeader>
            <CardBody>
              <div className={classes.tagWrapper}>
                {user.applicantTags[0].isNewApplicant && (
                  <Tag title="New Applicant" type={null} size="small" />
                )}
                {user.applicantTags[0].isRepeatAdopter && (
                  <Tag title="Repeat Adopter" type={null} size="small" />
                )}
                {user.applicantTags[0].isRepeatApplicant && (
                  <Tag title="Repeat Applicant" type={null} size="small" />
                )}
                {user.applicantTags[0].isFlagged && (
                  <Tag title="Flagged" type={null} size="small" />
                )}
              </div>
              <p className={classes.info1}>
                {userSVG} {applicationMeta.firstName} {applicationMeta.lastName}{" "}
                {user.userMeta.pronouns
                  ? "( " + user.userMeta.pronouns + " )"
                  : ""}
              </p>
              <p className={classes.info2}>
                {phone} {applicationMeta.phone}
              </p>
              <p className={classes.info2}>
                {mail} {applicationMeta.email}
              </p>
              <p className={classes.info2}>
                {house} {applicationMeta.streetAddress1}
              </p>
              {/* <p className={classes.info3}>{applicationMeta.streetAddress2}</p> */}
              <p className={classes.info3}>
                {applicationMeta.city}, {applicationMeta.state}{" "}
                {applicationMeta.zipCode}
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationMeta;
