import { useState, useRef, useEffect } from "react";
import { useSubmit, useParams, Form, Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import classes from "./AnimalProfile.module.css";
import {
  dog,
  cat,
  document as documentSVG,
  camera,
  trash,
} from "../../assets/svg/svgs";
import AnimalForm from "../../components/dashboard/AnimalForm";
import MultipleImageUpload from "../fileUpload/MultipleImageUpload";
import MultipleDocumentUpload from "../fileUpload/MultipleDocumentUpload";

import { adoptionStatusOptions } from "../../utils/enums";

const adoptionStatusDisplay = {
  ADOPTED: "Adopted",
  LOOKING_FOR_A_HOME: "Looking for a Home",
  ADOPTION_PENDING: "Adoption Pending",
};

const AnimalProfile = ({ animal, dogBreeds, catBreeds }) => {
  console.log("animal: " + animal);
  const dropdownRef = useRef();
  // profile, photo, document
  const [activeTab, setActiveTab] = useState("profile");
  const [display, setDisplay] = useState(animal.isDisplayed);
  const [archive, setArchive] = useState(animal.isArchived);
  const [adoptionStatus, setAdoptionStatus] = useState({
    value: animal.adoptionStatus,
    label: adoptionStatusDisplay[animal.adoptionStatus],
  });
  const [toggle, setToggle] = useState(false);
  const [alert, setAlert] = useState(null);

  const styles = !toggle ? { display: "none" } : { display: "block" };

  const submit = useSubmit();
  const { animalId } = useParams();

  const toggleHideDropdown = (e) => {
    if (
      dropdownRef.current &&
      toggle &&
      !dropdownRef.current.contains(e.target)
    ) {
      setToggle(false);
    }
  };

  const deleteAnimalWarning = () => {
    console.log("I clicked animal warning");
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => handleStatusUpdate("delete")}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this animal file!
      </ReactBSAlert>
    );
  };

  const handleStatusUpdate = (statusType) => {
    let formData = new FormData();
    let actionPath = "/rescue/animals";
    formData.append("animalId", animalId);
    switch (statusType) {
      case "display":
        formData.append("form-id", "display");
        formData.append("isDisplayed", display ? false : true);
        setDisplay((prevState) => !prevState);
        actionPath = `${actionPath}/${animalId}`;
        break;

      case "archive":
        formData.append("form-id", "archive");
        formData.append("isArchived", archive ? false : true);
        setArchive((prevState) => !prevState);
        break;

      case "delete":
        formData.append("form-id", "delete");
        formData.append("isDeleted", true);
        break;
        
      default:
        break;
    }
    submit(formData, { method: "PUT", action: actionPath });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  document.addEventListener("mousedown", toggleHideDropdown);

  return (
    <>
      {alert}
      <Row style={{marginBottom: "15px"}}>
        <div className="col-lg-12 col-xl-7 pr-1">
          <div
            className={display ? classes.badge : classes.inactiveBadge}
            onClick={() => handleStatusUpdate("display")}
          >
            {display ? "Displayed" : "Not Displayed"}
          </div>
          <div
            className={archive ? classes.badge : classes.inactiveBadge}
            onClick={() => handleStatusUpdate("archive")}
          >
            {archive ? "Archived" : "Not Archived"}
          </div>
          <div className={classes.container}>
            <div
              className={classes.badge}
              style={{ marginRight: 0 }}
              onClick={() => setToggle(!toggle)}
            >
              {trash}
            </div>
            <ul style={styles} ref={dropdownRef}>
              <li onClick={deleteAnimalWarning}>Delete Animal</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-12 col-xl-5 pl-1">
          <Form method="PUT">
            <input hidden name="form-id" defaultValue="adoptionStatus" />
            <input hidden name="animalId" defaultValue={animalId} />
            <Row>
              <Col md="8" className="pr-1">
                <div className="form-group">
                  <Select
                    id="adoptionStatus"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    required
                    name="adoptionStatus"
                    value={adoptionStatus}
                    onChange={(value) => {
                      setAdoptionStatus(value);
                    }}
                    options={adoptionStatusOptions}
                    placeholder="Select"
                  />
                </div>
              </Col>
              <Col md="4" className="pl-1">
                <Button className={classes.singleUpdateButton}>Update</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Row>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader className={classes["cardHeader_title"]}>
                <div
                  className={classes["cardHeader_svgWrapper"]}
                  onClick={() => setActiveTab("profile")}
                  style={
                    activeTab === "profile"
                      ? { backgroundColor: "#bb6bd930" }
                      : null
                  }
                >
                  {animal.animalMeta.species === "DOG" ? dog : cat}{" "}
                </div>
                <h3><Link to={`/page/animals/${animal.id}`} target="_blank" rel="noopener noreferrer">{animal.name}</Link></h3>
                <div className={classes["cardHeader_rightContainer"]}>
                  <div
                    className={classes["cardHeader_rightSvgWrapper"]}
                    onClick={() => setActiveTab("photo")}
                    style={
                      activeTab === "photo"
                        ? { backgroundColor: "#bb6bd930" }
                        : null
                    }
                  >
                    {camera}
                  </div>
                  <div
                    className={classes["cardHeader_rightSvgWrapper"]}
                    onClick={() => setActiveTab("document")}
                    style={
                      activeTab === "document"
                        ? {
                            backgroundColor: "#bb6bd930",
                            borderTopRightRadius: "12px",
                          }
                        : null
                    }
                  >
                    {documentSVG}
                  </div>
                </div>
              </CardHeader>
              <CardBody style={{display:"flex", flexWrap: "wrap"}}>
                {activeTab === "profile" && (
                  <AnimalForm
                    method="PUT"
                    animal={animal}
                    dogBreeds={dogBreeds}
                    catBreeds={catBreeds}
                  />
                )}
                {activeTab === "photo" && <MultipleImageUpload photos={animal.photos} />}
                {activeTab === "document" && <MultipleDocumentUpload documents={animal.documents}/>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AnimalProfile;
