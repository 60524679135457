import { useState, useEffect } from "react";
import { Form, useLoaderData, redirect, useActionData } from "react-router-dom";
import { encode, decode } from "string-encode-decode";

import classes from "./UserProfile.module.css";
import { userMetaDefault } from "../../../utils/initialState";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";

const UserDashboardProfilePage = () => {
  const [userMeta, setUserMeta] = useState(null);
  const [method, setMethod] = useState(null);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const data = useLoaderData();

  const { id, email, username } = data;
  console.log(data);

  const actionData = useActionData();

  const handlePhoneNumber = (e) => {
    let num = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let updateNum = !num[2]
      ? num[1]
      : +num[1] + "-" + num[2] + (num[3] ? "-" + num[3] : "");
    setPhoneNumber(updateNum);
  };

  useEffect(() => {
    if (!userMeta) {
      if (data.userMeta) {
        setUserMeta(data.userMeta);
        setPhoneNumber(data.userMeta.phone);
        setMethod("PUT");
      } else {
        setUserMeta(userMetaDefault);
        setPhoneNumber("");
        setMethod("POST");
      }
    }
  }, [actionData]);

  return (
    <div className="content">
      <Row className={classes["profile-cards"]}>
        {userMeta && (
          <Col md="8">
            <Card>
              <CardHeader style={{ borderBottom: "1px solid #ddd" }}>
                <h5 className="title">Update User Profile</h5>
              </CardHeader>
              <CardBody>
                <Form method={method}>
                  <input hidden name="form-id" defaultValue="updateProfile" />
                  <Row>
                    <Col className="pr-1" md="3">
                      <div className="form-group">
                        <label>User ID</label>
                        <Input
                          defaultValue={id}
                          disabled
                          placeholder="User ID"
                          type="text"
                          name="id"
                          style={{ fontWeight: "900" }}
                        />
                      </div>
                    </Col>
                    <Col className="pl-1" md="9">
                      <div className="form-group">
                        <label>Username</label>
                        <Input
                          value={username}
                          disabled
                          placeholder="Username"
                          type="text"
                          name="usernameDisplay"
                          style={{ fontWeight: "900" }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <div className="form-group">
                        <label>First Name</label>
                        <Input
                          defaultValue={userMeta.firstName}
                          placeholder="First Name"
                          type="text"
                          name="firstName"
                        />
                      </div>
                    </Col>
                    <Col className="px-1" md="4">
                      <div className="form-group">
                        <label>Last Name</label>
                        <Input
                          defaultValue={userMeta.lastName}
                          placeholder="Last Name"
                          type="text"
                          name="lastName"
                        />
                      </div>
                    </Col>
                    <Col className="pl-1" md="4">
                      <div className="form-group">
                        <label>Pronouns</label>
                        <Input
                          defaultValue={userMeta.pronouns}
                          placeholder="Pronouns"
                          type="text"
                          name="pronouns"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="form-group">
                        <label>Street Address</label>
                        <Input
                          defaultValue={userMeta.streetAddress1}
                          placeholder="Street Address"
                          type="text"
                          name="streetAddress1"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <div className="form-group">
                        <label>City</label>
                        <Input
                          defaultValue={userMeta.city}
                          placeholder="City"
                          type="text"
                          name="city"
                        />
                      </div>
                    </Col>
                    <Col className="px-1" md="4">
                      <div className="form-group">
                        <label>State</label>
                        <Input
                          defaultValue={userMeta.state}
                          placeholder="State"
                          type="text"
                          name="state"
                        />
                      </div>
                    </Col>
                    <Col className="pl-1" md="4">
                      <div className="form-group">
                        <label>Zip Code</label>
                        <Input
                          placeholder="ZIP Code"
                          type="text"
                          defaultValue={userMeta.zipCode}
                          name="zipCode"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <Input
                          // defaultValue={userMeta.phone}
                          value={phoneNumber}
                          placeholder="555-555-5555"
                          type="text"
                          name="phone"
                          onChange={handlePhoneNumber}
                          maxLength="12"
                        />
                      </div>
                    </Col>
                    <Col className="pl-1" md="6">
                      <div className="form-group">
                        <label htmlFor="emailDisplay">
                          Email address on File
                        </label>
                        <Input
                          placeholder="Email"
                          type="email"
                          value={email}
                          name="emailDisplay"
                          disabled
                          style={{ fontWeight: "900" }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 flex justify-content flex-end">
                      <Button
                        color="fetched-solid "
                        style={{ marginBottom: 0 }}
                      >
                        Update Profile
                      </Button>
                    </Col>
                  </Row>

                  {actionData?.updateProfileSuccess && (
                    <span>{actionData.updateProfileSuccess}</span>
                  )}
                  {actionData?.updateProfileError && (
                    <span>{actionData.updateProfileError}</span>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        )}

        <Col md="4">
          <Card>
            <CardHeader style={{ borderBottom: "1px solid #ddd" }}>
              <h6 className="title">Update Email</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <Form method="PUT">
                    <input hidden name="form-id" defaultValue="updateEmail" />
                    <div className="form-group">
                      <label>Email address</label>
                      <Input placeholder="Email" type="email" name="email" />
                      {actionData?.emailError && (
                        <span>{actionData.emailError}</span>
                      )}
                    </div>
                    <div className="flex justify-content flex-end">
                      <Button color="fetched-solid" style={{ marginBottom: 0 }}>
                        Update
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader style={{ borderBottom: "1px solid #ddd" }}>
              <h6 className="title">Update Username</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <Form method="PUT">
                    <input
                      hidden
                      name="form-id"
                      defaultValue="updateUsername"
                    />

                    <div className="form-group">
                      <label>Username</label>
                      <Input
                        placeholder="Username"
                        type="text"
                        name="username"
                      />
                      {actionData?.usernameError && (
                        <span>{actionData.usernameError}</span>
                      )}
                    </div>

                    <div className="flex justify-content flex-end">
                      <Button color="fetched-solid" style={{ marginBottom: 0 }}>
                        Update
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader style={{ borderBottom: "1px solid #ddd" }}>
              <h6 className="title">Update Password</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <Form method="PUT">
                    <input
                      hidden
                      name="form-id"
                      defaultValue="updatePassword"
                    />
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <Input
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        type="password"
                        name="password"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="passwordRetype">Retype Password</label>
                      <Input
                        id="passwordRetype"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        placeholder="Retype Password"
                        type="password"
                      />
                    </div>
                    <div className="flex justify-content flex-end">
                      {password !== "" &&
                        password2 !== "" &&
                        password === password2 &&
                        password.length > 7 && (
                          <Button
                            color="fetched-solid"
                            style={{ marginBottom: 0 }}
                            //   disabled={
                            //     password == "" ||
                            //     password2 == "" ||
                            //     password !== password2 ||
                            //     password.length < 8
                            //   }
                          >
                            Update
                          </Button>
                        )}
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserDashboardProfilePage;

let url = `${process.env.REACT_APP_API_ROOT}/users/user`;

export const multiActions = async ({ request, params }) => {
  const formData = await request.formData();
  const userData = Object.fromEntries(formData);
  console.log(JSON.stringify(userData));
  const formId = formData.get("form-id");
  let url = `${process.env.REACT_APP_API_ROOT}/users/user`;
  let status = "";
  const actionData = {};
  let token;

  switch (formId) {
    case "updateProfile":
      url = `${url}/meta`;
      const updateProfileResponse = await fetch(url, {
        method: request.method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      status = updateProfileResponse.status;
      if (!updateProfileResponse.ok) {
        console.log("status: " + status);
        actionData.updateProfileError =
          "Cannot update profile at this time.  Please try later.";
        return actionData;
      }
      actionData.updateProfileSuccess = "Success!";
      return actionData;

    // const profileData = await updateProfileResponse.json();
    // return profileData;

    case "updateEmail":
      url = `${url}/email`;
      const updateEmailResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      status = updateEmailResponse.status;

      if (!updateEmailResponse.ok) {
        console.log("status: " + status);
        if (status == 409) {
          actionData.emailError = "Email already exist";
        } else {
          actionData.emailError = "Not a valid email";
        }
        return actionData;
      }

      console.log(updateEmailResponse.ok);

      const emailData = await updateEmailResponse.json();
      console.log(JSON.stringify(emailData));

      // update token in local storage
      token = JSON.parse(decode(localStorage.getItem("token")));
      token.email = emailData.email;
      localStorage.setItem("token", encode(JSON.stringify(token)));
      token = null;
      return emailData;

    case "updateUsername":
      url = `${url}/username`;
      const updateUsernameResponse = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      status = updateUsernameResponse.status;

      if (!updateUsernameResponse.ok) {
        console.log("status: " + status);
        if (status == 409) {
          actionData.usernameError = "Username already exist";
        } else {
          actionData.usernameError = "Not a valid username";
        }
        return actionData;
      }

      console.log(updateUsernameResponse.ok);

      const usernameData = await updateUsernameResponse.json();
      console.log(JSON.stringify(usernameData));

      // update token in local storage
      token = JSON.parse(decode(localStorage.getItem("token")));
      token.username = usernameData.username;
      localStorage.setItem("token", encode(JSON.stringify(token)));
      token = null;

      //   console.log(decode(localStorage.getItem("token")));
      //   const token = encode(JSON.stringify(resData.user));
      //   localStorage.setItem('token', token);
      //   const decodeToken = decode(token);
      //   console.log(JSON.parse(decodeToken));

      return usernameData;

    case "updatePassword":
      return null;

    default:
      console.log("not a valid form-id");
      return null;
  }
};

export const loader = async () => {
  let status = "";
  let url = `${process.env.REACT_APP_API_ROOT}/users/user`;
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
