import StatCard from "../../ui/StatCard";

const DashboardStatsPanel = ({statCards}) => {

  const classes = statCards.length === 3 ? "col-lg-4 col-sm-6" : statCards.length === 2 ? "col-sm-6" : "col-lg-3 col-sm-6"

  return (
    <div className="row">
        {
            statCards.map((card, i) => (
                <div className={classes} key={`statCard_${i+1}`}>
                    <StatCard
                        body={card.body}
                        title={card.title}
                        icon={card.icon}
                        stat={card.stat}
                        footer={card.footer}
                        actionButton={card.actionButton}
                        footerMsg={card.footerMsg}
                        route={card.route}
                        onClick={card.onClick}
                    />
                </div>
            ))
        }
    </div>
  );
};

export default DashboardStatsPanel;
