import { Link } from "react-router-dom";
import classes from "./StatCard.module.css";

const StatCard = (props) => {
  return (
    <div className={classes.card}>
      {props.body && (
        <div className={classes["card-body"]}>
          <div className={`row ${classes["card-desc-text"]}`}>
            {props.icon}
            <div className={classes.text}>
              <p>{props.title}</p>
              <h2>{props.stat}</h2>
            </div>
          </div>
        </div>
      )}


      
      { props.footer && props.actionButton && (
         <div className={classes["card-footer"]}>
          <button onClick={props.onClick}>{props.footerMsg}</button>
          </div>
      )}  
      
      { props.footer && !props.actionButton && (
          <div className={classes["card-footer"]}>
            <Link to={props.route}>{props.footerMsg}</Link>
          </div>
      )}
    </div>
  );
};

export default StatCard;
