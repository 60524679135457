import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "./routes";
import ScrollToTop from "./components/ScrollToTop";


function App() {
  const router = createBrowserRouter(routes);

  return (
    <RouterProvider router={router} />

  );
}

export default App;
