import { useLoaderData, redirect, Link } from "react-router-dom";
import AdminRescuesTable from "../../../components/dashboard/AdminRescuesTable";
import { Button, Row, Col } from "reactstrap";


const AdminDashboardRescuesPage = () => {
 
  const data = useLoaderData();
  const defaultPageSize = 20;
  console.log(data);


  return (
    <div>
      
      <Row>
        <Col md={{size: 3, offfset: 9}} >
          <Link to="/admin/rescues/new">
            <Button color="fetched-solid">+ Add New Rescue</Button>
          </Link>
        </Col>
      </Row>
      <AdminRescuesTable
        rescues={data}
        title="Rescues"
        pageSize={defaultPageSize}
      />
    </div>
  );
};

export default AdminDashboardRescuesPage;

export const loader = async () => {
  let status = "";
  try {
    const response = await fetch(`${process.env.REACT_APP_API_ROOT}/admin/allRescues`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
