import { redirect, useLoaderData, Link } from "react-router-dom";
import classes from "./EditPage.module.css";
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";

import QuestionForm from "../../../components/dashboard/QuestionForm";
const RescueDashboardQuestionPage = () => {
  const data = useLoaderData();
  console.log(data);
  return (
    <div>
      <div className={classes.badge}>
        <Link to={`/rescue/animals/${data.animal.id}`}>{data.animal.name}</Link>
      </div>
      <div className={classes.badge}>
        {data.isPublic ? "Published" : "Not Published"}
      </div>
      <h2 className={classes.question}>{data.question}</h2>
      <p>
        asked by {data.user.username} on {data.createdAt.substring(0, 10)}
      </p>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle>Answer current question</CardTitle>
            </CardHeader>
            <CardBody>
              <QuestionForm question={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RescueDashboardQuestionPage;

export const loader = async ({ params }) => {
  let status = "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/rescues/rescue/questions/${params.questionId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    status = response.status;
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(`Message : ${error.message}, Status : ${status}`);
    return redirect("/");
  }
};
