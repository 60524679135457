import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";

const CustomText = ({question, idx}) => {
    
  return (
    <Row>
      <Col md="12">
        <div className="form-group">
          <label for={`fetchedAppQuestion_${question.category}_${idx}`}>{question.question}{question.isRequired ? "*" : ""}</label>
          <Input
           id={`fetchedAppQuestion_${question.category}_${idx}`}
            type="text"
            name={`fetchedAppQuestion_${question.category}_${idx}`}
            className="mt-1"
            required={question.isRequired}
            data-question-type={question.type}
            data-question-category={question.category}
            data-question-idx={idx}

          />
        </div>
      </Col>
    </Row>
  );
};

export default CustomText;
