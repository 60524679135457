import { useState, useEffect } from "react";
import { Link, useLoaderData, useOutletContext } from "react-router-dom";
import Select from "react-select";
import classes from "./SearchResult.module.css";
import { Label, Input, Button } from "reactstrap";
import AnimalCards from "../components/card/AnimalCards";

const distanceOptions = [
  { label: "10 miles", value: 10 },
  { label: "25 miles", value: 25 },
  { label: "50 miles", value: 50 },
  { label: "100 miles", value: 100 },
];

const speciesOptions = [
  { label: "Dog", value: "DOG" },
  { label: "Cat", value: "CAT" },
];

const sizeOptions = [
  { label: "Toy", value: "TOY" },
  { label: "Small", value: "SMALL" },
  { label: "Medium", value: "MEDIUM" },
  { label: "Large", value: "LARGE" },
  { label: "Giant", value: "Giant" },
];

const ageOptions = [
  { label: "Young", value: "YOUNG" },
  { label: "Young Adult", value: "YOUNG_ADULT" },
  { label: "Adult", value: "ADULT" },
  { label: "Senior", value: "SENIOR" },
];

const genderOptions = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
];

const SearchResultPage = () => {
  const [species, setSpecies] = useState("");
  const [breeds, setBreeds] = useState([]);
  const [distance, setDistance] = useState("");
  const [size, setSize] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState(useLoaderData());

  const [user] = useOutletContext();
  const dogBreeds = parseBreedData(data.filters.dogBreeds);
  const catBreeds = parseBreedData(data.filters.catBreeds);
  console.log(data);

  const handleChange = (type, option) => {
    switch (type) {
      case "distance":
        setDistance(option);
        console.log(distance);
        break;
      case "breed":
        setBreeds(option);
        console.log(breeds);
        break;
      case "species":
        setSpecies(option);
        setBreeds([]);
        console.log(species);
        break;
      case "size":
        setSize(option);
        console.log(size);
        break;
      case "age":
        setAge(option);
        console.log(age);
        break;
      case "gender":
        setGender(option);
        console.log(gender);
        break;

      default:
        break;
    }
  };

  const updateLikes = (id, operation) => {
    console.log("updateLikes");
    const likes = data.filters.user.animalsLikes;
    if(operation === "like") likes.push({"animalId": id});
    else if(operation === "unlike") {
        for(let i = 0; i < likes.length; i++) {
            if(likes[i].animalId == id) likes.splice(i, 1);
        }
    }
    const filters = data.filters;
    filters.user.animalLikes = likes;
    console.log(filters.user.animalLikes);
    setData({
        animals: data.animals,
        filters: filters,
        zipCode: zipCode,
        distance: distance,
        species: species,
      });
  }

  const handleZipcodeChange = (e) => {
    setZipcode(e.target.value);
    // console.log(zipCode);
  };

  const handleSearch = async (page) => {
    let status = "";
    let animalData = null;
    const animalsUrl = `${process.env.REACT_APP_API_ROOT}/animals`;
    const breedsArr = breeds.map((_breed) => _breed.value);
    console.log(breedsArr);
    const body = {
      species:
        species === "" || species === undefined
          ? null
          : species.value.toUpperCase(),
      breed:
        (Array.isArray(breeds) && breeds.length == 0) || breeds === undefined
          ? null
          : breedsArr,
      age: age === "" || age === undefined ? null : age.value.toUpperCase(),
      gender:
        gender === "" || gender === undefined
          ? null
          : gender.value.toUpperCase(),
      size: size === "" || size === undefined ? null : size.value.toUpperCase(),
      distance: distance == "" || distance == undefined ? null : distance.value,
      zipcode: zipCode,
      pageSize: pageSize,
      offset: (pageNumber - 1) * pageSize,
    };

    try {
      const response = await fetch(animalsUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      status = response.status;
      animalData = await response.json();

      setData({
        animals: animalData,
        filters: data.filters,
        zipCode: zipCode,
        distance: distance,
        species: species,
        
      });

      setPageNumber(page);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClear = (type) => {
    switch (type) {
      case "age":
        setAge("");
        break;
      case "size":
        setSize("");
        break;
      case "gender":
        setGender("");
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (data.species === "DOG") {
      setSpecies({ label: "Dog", value: "DOG" });
    } else {
      setSpecies({ label: "Cat", value: "CAT" });
    }
    setDistance({ label: "25 miles", value: 25 });
    setZipcode(data.zipcode);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.searchPanelContainer}>
        <div className={classes.searchItemWrapper}>
          <Label className="pl-1">Species</Label>
          <Select
            onChange={(option) => handleChange("species", option)}
            options={speciesOptions}
            value={species}
            name="species"
            className="mb-3 mt-1"
          />
        </div>
        <div className={classes.searchItemWrapper}>
          <Label className="pl-1">Breed(s)</Label>
          <Select
            isMulti
            onChange={(option) => handleChange("breed", option)}
            options={species.value === "DOG" ? dogBreeds : catBreeds}
            value={breeds}
            placeholder="All Breeds"
            name="breed"
            maxMenuHeight={500}
            className="mb-3 mt-1"
          />
        </div>
        <div className={classes.searchItemWrapper}>
          <Label className="pl-1">Distance</Label>
          <Select
            onChange={(option) => handleChange("distance", option)}
            options={distanceOptions}
            value={distance}
            name="distance"
            className="mb-3 mt-1"
          />
        </div>
        <div className={classes.searchItemWrapper}>
          <Label className="pl-1">Zip Code</Label>
          <Input
            type="text"
            name="zipCode"
            className={`${classes.input} mb-3 mt-1`}
            // defaultValue={data.zipcode}
            value={zipCode}
            onChange={handleZipcodeChange}
            maxLength="5"
            pattern="[0-9]{5}"
          />
        </div>
        <div className={classes.searchItemWrapper}>
          <span
            onClick={() => {
              handleClear("size");
            }}
          >
            clear
          </span>
          <Label className="pl-1">Size</Label>
          <Select
            onChange={(option) => handleChange("size", option)}
            options={sizeOptions}
            value={size}
            name="size"
            placeholder="All Sizes"
            className="mb-3 mt-1"
          />
        </div>
        <div className={classes.searchItemWrapper}>
          <span
            onClick={() => {
              handleClear("age");
            }}
          >
            clear
          </span>
          <Label className="pl-1">Age</Label>
          <Select
            onChange={(option) => handleChange("age", option)}
            options={ageOptions}
            value={age}
            name="age"
            placeholder="All Ages"
            className="mb-3 mt-1"
          />
        </div>
        <div className={classes.searchItemWrapper}>
          <span
            onClick={() => {
              handleClear("gender");
            }}
          >
            clear
          </span>
          <Label className="pl-1">Gender</Label>
          <Select
            onChange={(option) => handleChange("gender", option)}
            options={genderOptions}
            value={gender}
            name="gender"
            placeholder="All Genders"
            className="mb-3 mt-1"
          />
        </div>
        <Button onClick={handleSearch}>Search</Button>
      </div>
      <div className={classes.resultPanelContainer}>
        {data.animals === "No LongLat found" && (
          <h2>
            You've entered an invalid zip code. <br />
            Please try again
          </h2>
        )}
        {data.animals.length == 0 && (
          <h2>
            Your search result with no animals. <br /> Please expand your search
          </h2>
        )}
        {data.animals !== "No LongLat found" && (
          <AnimalCards
            animals={data.animals}
            userId={user?.id}
            likes={data.filters.user.animalsLikes}
            action={`/page/results/${species.value}/${zipCode}`}
            updateAction={updateLikes}
            searchPage={true}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResultPage;

export const multiActions = async ({ request }) => {
  const formData = await request.formData();
  const formId = formData.get("formId");
  
  switch (formId) {
    case "like":
      let url = `${
        process.env.REACT_APP_API_ROOT
      }/users/user/animal/${formData.get("animalId")}`;
      let status = "";

      const response = await fetch(url, {
        method: request.method,
        credentials: "include",
      });
      status = response.status;
      if (!response.ok) {
        console.log("status: " + status);
        return response;
      }
      const responseData = await response.json();
      return responseData;
    case "filterSearch":
        return;
    default:
        return;
  }
};

export const loader = async ({ params }) => {
  let status = "";

  let animalData = null;
  let filterData = null;

  const animalsUrl = `${process.env.REACT_APP_API_ROOT}/animals/`;

  const body = {
    species: params?.species.toUpperCase(),
    breed: null,
    age: null,
    gender: null,
    size: null,
    zipcode: params.zipcode,
    distance: params.distance != null ? params.distance : 25,
    pageSize: 20,
    offset: 0,
  };

  try {
    const response = await fetch(animalsUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    status = response.status;
    animalData = await response.json();
    console.log(animalData);
  } catch (error) {
    console.log(error);
  }

  const filterUrl = `${process.env.REACT_APP_API_ROOT}/animals/filters`;

  try {
    const response = await fetch(filterUrl, {
      method: "GET",
      credentials: "include",
    });
    status = response.status;
    filterData = await response.json();
  } catch (error) {
    console.log(error);
  }

  return {
    animals: animalData,
    filters: filterData,
    zipcode: body.zipcode,
    distance: body.distance,
    species: params?.species.toUpperCase(),
  };
};

const parseBreedData = (breedsData) => {
  const breeds = [];
  breedsData.forEach((breed) => {
    breeds.push({ label: breed.name, value: breed.id });
  });
  return breeds;
};
